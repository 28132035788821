export default {
  account: 'Account',
  activity: 'Activity',
  add: 'Add',
  addFeedback: 'Add Feedback',
  addMessage: 'Add Message',
  agoDayShort: '{0}d',
  agoHourShort: '{0}h',
  agoMinuteShort: '{0}m',
  agoMonthShort: '{0}M',
  agoSecondShort: '{0}s',
  agoYearShort: '{0}y',
  application: 'Application',
  approve: 'Approve',
  audioFromMe: 'Audio by Me',
  audioFromSystem: 'Audio by Rab7',
  audioFromUserName: 'Audio by {0}',
  audioHelp: 'Help Audio',
  back: 'Back',
  birthday: 'Birthday',
  birthdaySubtitle: 'Please choose your birthday',
  birthdayWithColor: 'Birthday:',
  camera: 'Camera',
  cancel: 'Cancel',
  cashier: 'Cashier',
  changePassword: 'Change Password',
  chatWithUs: 'Chat With Us',
  checkRequest: 'Check Request',
  city: 'City',
  cityHint: 'Type some characters of city name then select from the list, if you didn\'t find your city, choose the nearest one',
  cityNDistrict: 'Country, City & District',
  cityNDistrictSubtitle: 'Type your country, city & district and choose from the list that will appear to you, \nif you didn\'t find, choose the nearest one',
  cityWithColon: 'City:',
  confirmation: 'Confirmation',
  confirmPassword: 'Confirm Password',
  congratulations: 'Congratulations',
  dateWithDate: 'Date: {0}',
  daysToGo: '{days} days to go',
  delete: 'Delete',
  discard: 'Discard',
  discardPromptMessage: 'Are you sure you want to discard the changes?',
  discountWithColon: 'Discount: ',
  district: 'District',
  districtHint: 'Type some characters of district name then select from the list, if you didn\'t find your district, choose the nearest one',
  districtWithColon: 'District:',
  earn: 'Earn',
  earnedWithColon: 'Earned: ',
  eBachelor: 'Bachelor',
  eDiploma: 'Diploma',
  eDoctorate: 'Doctorate',
  educationLevel: 'Education Level',
  educationLevelHint: 'Type or select education level from the list',
  educationLevelSubTitle: 'Please choose your education level',
  educationLevelWithColon: 'Education Level: ',
  eHighSchool: 'High School',
  eligibility: 'Eligibility',
  eligible: 'Eligible',
  eligibleForPrize: 'Eligible for the prize',
  email: 'Email',
  emailSubtitle: 'Please write your email',
  emailWithColon: 'Email:',
  eMaster: 'Master',
  english: 'English',
  eNotInAcademicSector: 'Not in Academic Sector',
  enterFeedback: 'Enter Feedback',
  enterVerificationCodeMessage: 'Enter verification code generated at customer\'s end',
  ePrimarySchool: 'Primary School',
  errorApiGeneric: 'Unable to connect to server, please try later',
  errorAudioPlayGeneric: 'Unable to play audio, some error occurred.',
  errorAudioPlayNoNetwork: 'Unable to play audio, please check your internet connection and try again.',
  errorAudioRecordGeneric: 'Unable to record audio, some error occurred.',
  errorNoNetwork: 'Please check your internet connection and try again',
  externalLinkFailure: 'Unable to open this link',
  female: 'Female',
  forgotPassword: 'Forgot Password?',
  gallery: 'Gallery',
  genderWithColor: 'Gender: ',
  gift: 'Gift',
  giftRedeemedSuccess: 'Gift Redeemed Successfully',
  help: 'Help',
  invoiceAmount: 'Invoice Amount',
  invoiceAmountWithColon: 'Invoice amount: ',
  itemCountSelected: '{count} Selected',
  languageArabic: 'Arabic',
  languageEnglish: 'English',
  login: 'Login',
  logout: 'Logout',
  logoutPromptMessage: 'Are you sure you want to logout?',
  male: 'Male',
  maleOrFemale: 'Male or Female',
  manager: 'Manager',
  maritalStatus: 'Marital Status',
  maritalStatusSubtitle: 'Please choose your marital status',
  maritalStatusWithColor: 'Marital Status:',
  married: 'Married',
  media: 'Media',
  messagedYou: 'messaged you',
  messageFeedbackAdded: 'Feedback Added',
  messages: 'Messages',
  mobileNumber: 'Mobile Number',
  mobileNumberSubtitle: 'Please write mobile number',
  mobileNumberVerification: 'Mobile Number Verification',
  mobileNumberVerificationSubtitle: 'Please enter verification code sent to mobile number',
  mobileNumberWithColon: 'Mobile Number: ',
  money: 'Money',
  moneyWithColon: 'Money: ',
  monthPoints: 'Points ({0})',
  name: 'Name',
  nameSubtitle: 'Please write your name',
  nameWithColor: 'Name:',
  nationality: 'Nationality',
  nationalityHint: 'Type or select your nationality from the list',
  nationalitySubtitle: 'Please choose your nationality',
  nationalityWithColon: 'Nationality:',
  never: 'Never',
  newPassword: 'New Password',
  newPasswordSubtitle: 'Please write new password',
  next: 'Next',
  noAccountYet: 'Don\'t have an account?',
  noDistrictsFound: 'No Districts Found',
  noGiftsAvailable: 'No Gifts Available',
  noMessages: 'No Messages',
  noNotifications: 'No Notifications',
  notAssociatedToBranch: 'You are not associated to any branch',
  notEligible: 'Not Eligible',
  notEligibleForThePrize: 'Not Eligible for the prize',
  notification: 'Notification',
  notifications: 'Notifications',
  notifyMessages: 'Notify Messages',
  notMarried: 'Not Married',
  noWinners: 'No Winners',
  offer: 'Offer',
  offerConsumedSuccess: 'Offer Consumed Successfully',
  ok: 'OK',
  oldPassword: 'Old Password',
  password: 'Password',
  passwordChangedSuccessfully: 'Password Changed Successfully',
  passwordMismatch: 'Password Mismatch',
  passwordResetSuccessfully: 'Password Reset Successfully',
  pastMonthsPoints: 'Past Months Points',
  pBusiness: 'Business',
  pEducation: 'Education',
  pEngineering: 'Engineering',
  pFieldOperation: 'Field Operation',
  pFinance: 'Finance',
  pHealthcare: 'Healthcare',
  pHomeMaker: 'Home Maker',
  photo: 'Photo',
  photoSubtitle: 'Click image to add or edit photo',
  pickPhoto: 'Pick Photo',
  pJobSeeker: 'Job Seeker',
  pManagement: 'Management',
  pMilitary: 'Military',
  points: 'Points',
  pointsNeeded: ' points needed',
  pointsRequiredForPrize: 'Points Required to Eligible: {0}',
  pRetired: 'Retired',
  previous: 'Previous',
  prize: 'Prize',
  prizeDetails: 'Prize Details',
  prizeWinners: 'Prize Winners',
  profession: 'Profession',
  professionSubtitle: 'Please write your profession',
  profile: 'Profile',
  profileDetails: 'Profile Details',
  promptApproveRequest: 'Are you sure you want to approve the request?',
  promptDeleteNotification: 'Are you sure you want to delete {0} notification?',
  promptDeleteNotifications: 'Are you sure you want to delete {0} notifications?',
  promptEarn: 'Are you sure you want to add?',
  promptPasswordMismatch: 'Confirm password and new password are not matching',
  promptRejectRequest: 'Are you sure you want to reject the request?',
  pTechnical: 'Technical',
  pTechnology: 'Technology',
  quantityWithCount: 'Quantity: {0}',
  rab7NumberWithColon: 'Rab7 Number: ',
  rab7OrMobileNumber: 'Rab7 or Mobile Number',
  recordedAudio: 'Recorded Audio',
  recoverPassword: 'Recover Password',
  redeemDiscount: 'Redeem Discount',
  redeemDiscountWithValue: 'Redeem Discount: {0}',
  redeemGift: 'Redeem Gift',
  redeemOffer: 'Redeem Offer',
  refund: 'Refund',
  refundedSuccess: 'Refunded Successfully',
  refundWithColon: 'Refund: ',
  register: 'Register',
  registrationSuccessful: 'Registration Successful',
  reject: 'Reject',
  relationshipParent: 'Parent',
  relationshipSibling: 'Sibling',
  relationshipSpouse: 'Spouse',
  relationshipFriend: 'Friend',
  relationshipCoworker: 'Co-worker',
  relationshipNeighbour: 'Neighbour',
  relationshipOther: 'Other',
  removePhoto: 'Remove Photo',
  requestRejectedSuccess: 'Request Rejected Successfully',
  resend: 'Resend Code',
  resendCodeInSeconds: 'Resend Code in {0} seconds',
  resetPassword: 'Reset Password',
  retry: 'Retry',
  save: 'Save',
  selectBranch: 'Select Branch',
  settings: 'Settings',
  shareYourFeelings: 'Share your feelings',
  signupHere: 'Signup here',
  somethingWentWrong: 'Something went wrong',
  startedFromDate: 'Started From: {0}',
  startingFromDate: 'Starting From: {0}',
  systemNotification: 'System Notification',
  termsConditionsNPrivacyPolicy: 'Terms of Use & Privacy Policy',
  termsNConditions: 'Terms & Conditions',
  transactionSuccessful: 'Transaction Successful',
  update: 'Update',
  updateSuccessful: 'Updated Successfully',
  username: 'Username',
  usernameNPassword: 'Username & Password',
  usernameNPasswordSubtitle: 'Please write username and password',
  verificationCode: 'Verification Code',
  verify: 'Verify',
  version: 'Version',
  winners: 'Winners',
  yourFeedback: 'Your feedback',
  addComment: 'Add Comment',
  addImage: 'Add Image',
  addMedia: 'Add Media',
  addRating: 'Add Rating',
  addReview: 'Add Review',
  agoDay: {
    'one': '{count} Day',
    'other': '{count} Days'
  },
  agoHour: {
    'one': '{count} Hour',
    'other': '{count} Hours'
  },
  agoMinute: {
    'one': '{count} Minute',
    'other': '{count} Minutes'
  },
  agoMonth: {
    'one': '{count} Month',
    'other': '{count} Months'
  },
  agoSecond: {
    'one': '{count} Second',
    'other': '{count} Seconds'
  },
  agoYear: {
    'one': '{count} Year',
    'other': '{count} Years'
  },
  all: 'All',
  amount: 'Amount',
  answer: 'Answer',
  answerNEarn: 'Answer & Earn Points',
  apply: 'Apply',
  availability: 'Availability',
  available: 'Available',
  availableBalanceInYourWallet: 'available balance in your wallet',
  balance: 'Balance',
  branches: 'Branches',
  businessBranchName: 'Business Branch Name',
  businessCategory: 'Business Category',
  businessGift: 'Business Gift',
  businessGifts: 'Business Gifts',
  businessMoney: 'Business Money',
  businessMoneyDetails: 'Business Money Details',
  businessName: 'Business Name',
  businessProfile: 'Business Profile',
  buyer: 'Buyer',
  cancelRequest: 'Cancel Request',
  chat: 'Chat',
  checkRequestStatus: 'Check Request Status',
  choose: 'Choose',
  claimEarn: 'Claim your earn',
  claimed: 'Earn Claimed',
  claimedBusinessEarn: 'You have claimed your earned points against business',
  commentAdded: 'Comment Added',
  commentDeleted: 'Comment Deleted',
  comments: 'Comments',
  commentsCount: {
    'one': '{count} comment',
    'other': '{count} comments'
  },
  consumable: 'Consumable',
  consumableBalance: 'amount available to consume as a discount',
  consume: 'Consume',
  consumed: 'Consumed',
  consumedAtAllBranches: 'This offer is consumable at all the branches of the business',
  consumedAtOnlyBranches: 'This offer is consumable at selected branches only',
  consumedGift: 'Consumed Gift',
  consumedOffer: 'Consumed Offer',
  consumedOfferLimit: 'You have consumed this offer',
  consumedOffers: 'Consumed Offers',
  consumeFormulaInformation: 'Consume Formula Information',
  consumeNEarn: 'Consume & Earn Points',
  consumingGift: 'Consuming Gift',
  consumingMoney: 'Consuming Money',
  consumingOffer: 'Consuming Offer',
  contactDetails: 'Contact Details',
  countAndPoints: '{0} Points',
  deleteAck: 'Delete Acknowledgement',
  deleteAckPromptMessage: 'Are you sure you want to delete this acknowledgement?',
  deleteActivity: 'Delete Activity',
  deleteActivityPromptMessage: 'Are you sure you want to delete this activity?',
  deleteComment: 'Delete Comment',
  deleteCommentPromptMessage: 'Are you sure you want to delete comment?',
  deletedSuccessfully: 'Deleted Successfully',
  deleteOffer: 'Delete Offer',
  deleteOfferPromptMessage: 'Are you sure you want to delete this offer?',
  deletePromptMessage: 'Are you sure you want to delete it?',
  deleteReview: 'Delete Review',
  deleteReviewPromptMessage: 'Are you sure you want to delete review?',
  details: 'Details',
  directions: 'Directions',
  discount: 'Discount',
  dislikesCount: {
    'one': '{count} dislike',
    'other': '{count} dislikes'
  },
  duration: 'Duration',
  earned: 'Earned',
  earnMore: 'Earn More',
  earnMoreToConsume: 'Earn {0} more to consume',
  earnSystemPoints: 'You earned Rab7 points',
  earnWithDiscount: 'Earn With Discount',
  editReview: 'Edit Review',
  emailNPassword: 'Login Credentials',
  emailNPasswordSubtitle: 'Please write username (alphabets, numbers and underscore), email and password',
  emailOrUsername: 'Email or Username',
  enterMoneyRange: 'enter money to consume between {0} and {1}',
  expired: 'Ended',
  favouriteOffers: 'Favourite Offers',
  feeds: 'Feeds',
  filter: 'Filter',
  fromDate: 'From Date',
  giftConsumed: 'Gift Consumed',
  giftConsumedMessage: 'You have consumed the gift',
  giftPrice: 'Gift Price',
  gifts: 'Gifts',
  giftsConsumed: 'Gifts \nConsumed',
  hintClickImageToEarn: '# click image to earn points',
  hintEnterMoney: 'Enter money to consume',
  hintEnterMoneyRange: '# enter money between {0} and {1}',
  hintEnterPointsRange: '# enter points between {0} and {1}',
  hintRab7: '# enter Rab7 number of the user',
  hintRelationship: '# type or select relation from the list',
  inCorrectedAnswerSelected: 'You have selected incorrect answer',
  less: 'Less',
  likes: 'Likes',
  likesCount: {
    'one': '{count} like',
    'other': '{count} likes'
  },
  maximumDailyConsume: 'Maximum Consume Limit Daily',
  maximumDailyConsumeHint: 'maximum amount that can be consumed in a day',
  maximumEarn: 'Maximum Earn',
  minimumAmountToConsume: 'Minimum Consume Limit',
  minimumAmountToConsumeHint: 'minimum amount that can be consumed',
  minimumBalanceNeededToConsume: 'Minimum Wallet Balance',
  minimumBalanceNeededToConsumeHint: 'minimum wallet balance needed to consume',
  minimumSpentNeededToConsume: 'Minimum Spent',
  minimumSpentNeededToConsumeHint: 'minimum invoice amount needed to consume',
  moneyConsumedMessage: 'You have consumed the money',
  moneyTransferred: 'Money Transferred',
  moneyWithValue: '{0} {1}',
  more: 'More',
  myReviews: 'My Reviews',
  needToConsume: 'Need to Consume',
  noBranches: 'No Branches',
  noBusinessGifts: 'No Business Gifts',
  noBusinessMoney: 'No Business Money',
  noComments: 'No Comments',
  noConsumedOffers: 'No Consumed Offers',
  noFavouriteOffers: 'No Favourite Offers',
  noMapApp: 'No app found to show direction',
  noMoreDetails: 'No More Details',
  noOffers: 'No Offers Found',
  noPendingEarn: 'No Pending Earn',
  noReviews: 'No Reviews Found',
  notifyComments: 'Notify Comments',
  noTnc: 'No Terms & Conditions',
  noTransfers: 'No Transfers',
  noUsers: 'No Users',
  offerConsumed: 'Offer Consumed',
  offerConsumedMessage: 'You have consumed the offer',
  offers: 'Offers',
  offersConsumed: 'Offers \nConsumed',
  offerTransferred: 'Offer Transferred',
  participate: 'Participate',
  participateMessage: 'Participate to help eligible users to compete in the prize race. Your participation will give one vote to some random eligible users.',
  participateMessageEligible: 'Participate to help yourself to compete in the prize race. Your participation will give one vote to you and some random eligible users.',
  participateNEarn: 'Participate & Earn Points',
  participationThanksMessage: 'Thanks for your participation, you have benefited below users',
  pendingEarn: 'Unclaimed Earnings',
  post: 'Post',
  pressAgainToExit: 'Press back again to exit',
  prizeRace: 'Prize Race',
  prizeWinnersAnnounced: 'Prize Winners Declared',
  professionHint: 'Type or select profession from the list',
  professionWithColon: 'Profession: ',
  profileUpdateRestriction: 'Sorry, you are not allowed to modify your details more than once in a month. However you can modify your name, photo, mobile number as much time as you want. If you want to correct your details, please contact support.',
  promptCancelRequestMessage: 'Are you sure you want to cancel the request?',
  promptConsume: 'Are you sure you want to make request to consume the offer?',
  promptConsumeDiscount: 'Are you sure you want to make request to consume money as discount?',
  promptConsumeDiscountMessage: 'It is recommended to make request to consume money within the branch, since only few minutes are provided to consume.',
  promptConsumeDiscountSpendMessage: 'You need to spend minimum {0} and invoice amount should be greater than consumed money.',
  promptConsumeGift: 'Are you sure you want to make request to consume the gift?',
  promptConsumeGiftMessage: 'It is recommended to make request to consume the gift within the branch, since only few minutes are provided to consume. \nYour wallet is deducted with gift value after successful completion.',
  promptConsumeMessage: 'It is recommended to make request to consume the offer within the branch, since only few minutes are provided to consume. \nPlease validate the branch(es) where you can consume this offer.',
  promptParticipateVote: 'Participate Now',
  promptParticipateVoteEligibleMessage: 'Your participation will help you and some random eligible users to compete in the prize race. You will come to know about the users whom gets benefit from your participation after submitting your participation.',
  promptParticipateVoteMessage: 'Your participation will help some random eligible users to compete in the prize race. You will come to know about the users whom gets benefit from your participation after submitting your participation.',
  promptRefund: 'Refund Transaction',
  promptRefundMessage: 'It is recommended to make request to refund within the same branch where transaction was made.',
  promptTransferMoney: 'Transfer Money',
  promptTransferMoneyMessage: 'Are you sure you want to transfer the money?',
  promptTransferOffer: 'Transfer Offer',
  promptTransferOfferMessage: 'You can transfer this offer to other users. When you transfer it to them, it will be available for them. It will be considered as consumed from your side. Are you sure you want to transfer this offer?',
  promptTransferPoints: 'Transfer Rab7 Loyalty Points',
  promptTransferPointsMessage: 'Are you sure you want to transfer Rab7 points?',
  provideVerificationCode: 'Please provide below verification code to the cashier when asked to complete this request',
  quantity: 'Quantity',
  rab7Number: 'Rab7 Number',
  rab7PointsTransferred: 'Rab7 Loyalty Points Transferred',
  rate: 'Rate',
  ratedSuccessfully: 'Rated Successfully',
  rating: 'Rating',
  ratingsCount: {
    'one': '{count} Rating',
    'other': '{count} Ratings'
  },
  received: 'Received',
  recordAudio: 'Record Audio',
  refunded: 'Refunded',
  refunding: 'Refunding',
  refundWalletLowInfoMessage: 'The refund amount is lesser than the invoice amount due to insufficient balance available in your wallet to reverse the earned points from old transaction.',
  registrationPointsHint: 'You will earn Rab7 points after registration',
  relation: 'Relation',
  removeAll: 'Remove All',
  replyingTo: 'Replying to',
  replyTo: 'Reply to',
  report: 'Report',
  reportedMessage: 'You have successfully reported',
  reportHint: 'Put your comments',
  requestInProgress: 'Request is in progress',
  reverseDiscount: 'Discount Reversed',
  reverseEarned: 'Earn Reversed',
  review: 'Review',
  reviewAdded: 'Review Added',
  postDeleted: 'Post Deleted',
  reviewNEarn: 'Review & Earn Points',
  reviews: 'Reviews',
  reviewUpdated: 'Review Updated',
  reviewYourVisit: 'Review Your Visit',
  selected: 'Selected',
  spent: 'Spent',
  startingFrom: 'Starting from {value}',
  submit: 'Submit',
  tnc: 'TnC',
  toDate: 'To Date',
  totalDiscount: 'Total \nDiscount',
  totalEarned: 'Total \nEarned',
  totalReceived: 'Total \nReceived',
  totalSpent: 'Total \nSpent',
  totalTransferred: 'Total \nTransferred',
  transactionConsumeGiftMade: 'You have consumed gift',
  transactionConsumeOfferMade: 'You have consumed offer',
  transactionEarnMade: 'You have made a purchase',
  transactionRefundMade: 'You have made a refund',
  transactions: 'Transactions',
  transactionTypes: 'Transaction Types',
  transfer: 'Transfer',
  transferMoney: 'Transfer Money',
  transferOffer: 'Transfer Offer',
  transferPoints: 'Transfer Rab7 Loyalty Points',
  transferred: 'Transferred',
  transfers: 'Transfers',
  twoCommaSeparatedValues: '{0}, {1}',
  user: 'User',
  usernameHint: 'Username (a-z 0-9 _)',
  userRequestCancelled: 'Request Cancelled',
  userRequestCompleted: 'Request Processed',
  userRequestCompletedMessage: 'Your request has been processed successfully',
  userRequestInProgress: 'Your request is in progress, please connect to cashier at store to handle your request. \nThis request will automatically gets cancelled in',
  userRequestNotFound: 'Request does not exist',
  userRequestNotFoundMessage: 'Request does not exist, please create another request',
  userRequestRejected: 'Request Rejected',
  userRequestRejectedMessage: 'You request has been rejected, please connect to the cashier for the reason',
  userRequestTimedOut: 'Request Timed Out',
  userRequestTimedOutMessage: 'Time to approve the request is over, please try again',
  validity: 'Validity',
  validUpto: 'Valid Upto {value}',
  visitor: 'Visitor',
  voteCurrentStatus: 'Prize Race Current Status',
  voteFinalStatus: 'Prize Race Final Status',
  voting: 'Voting',
  votingBegan: 'Prize duration is over, voting has been started, please participate',
  walletBalance: 'Wallet Balance',
  walletBalanceIs: 'You wallet balance is',
  winner: 'Winner',
  nearby: 'Nearby Stores',
  nearbyMultiLine: 'Nearby\nStores',
  noBusinessFound: 'No Business Found',
  referAndEarn: 'Invite and Earn',
  referAndEarnMessage: 'Share the Rab7 and give your friends or relatives Rab7 points. You will also earn Rab7 points once they complete registration. \n\nInvitation Code is',
  share: 'Share',
  invitationCode: 'Invitation Code',
  invitationCodeHint: '# enter invitation code if you have, leave empty otherwise',
  ratings: 'Ratings',
  smartAds: 'Smart Ads',
  noSmartAds: 'No Smart Ads',
  toStart: 'To Start',
  noActivityAfterAckMessage: 'Sorry, nothing is present now. Please check again later.',
  businessRequestSubmitted: 'Request Submitted',
  addBusinessRequest: 'New Business Request',
  locationCoordinates: 'Location Coordinates',
  done: 'Done',
  markerDragHint: '# long press marker to hold and move it',
  contactNumber: 'Contact Number',
  multipleBranchesHere: 'Multiple branches here',
  clickToView: 'Click to View',
  multipleOffersHere: 'Multiple offers here',
  votingStarted: 'Voting Started for the Prize',
  commentedOnPost: 'commented on your post',
  repliedInPost: 'replied in post',
  repliedInActivity: 'replied in activity',
  forwardedOfferToConsume: 'shared offer to consume',
  transferredBusinessPoints: 'transferred {0} business money',
  transferredRab7Points: 'transferred {0} rab7 points',
  votingCompleted: 'Prize duration is over, voting has been done',
  votingToStart: 'Prize duration is over, voting is yet to start, keep checking',
  viewVoting: 'View Voting',
  businessReviews: 'Business Reviews',
  shareYourFeedback: 'Share Your Feedback',
  referralShareMessage: 'Join on #Rab7, earn and consume offers on your daily purchases. Enter my code {rab7Number} during registration to earn points. Download {url}',
  appShareMessage: 'Join on #Rab7, Have fun and get free offers. Download {url}',
  smartAd: 'SmartAd',
  hintBusinessAd: 'Didn\'t find the business?',
  close: 'Close',
  visit: 'Visit',
  unlimited: 'Unlimited',
  referNow: 'Refer Now',
  noFeeds: 'No Feeds',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  consumeDetails: 'Consume Details',
  validityMessage: 'the offer will end after',
  validityFutureMessage: 'the offer will start in',
  totalConsumedMessage: 'count of offer have been consumed by all customers',
  myConsumed: 'Consumed by Me',
  myConsumedMessage: 'number of times I have consumed it',
  availabilityMessage: 'count of offer business provided for all customers',
  myAvailabilityMessage: 'number of times I can consume it',
  myAvailability: 'Availability for Me',
  remainingOffers: 'Remaining',
  totalConsumed: 'Consumed by All',
  availabilityAll: 'Availability for All',
  businessRequestDetails: 'Details',
  transferRab7Points: 'Transfer Rab7 Loyalty Points',
  reviewEarn: 'Review to earn more points',
  yourLocation: 'Your Location',
  search: 'Search',
  startingIn: 'Starting in',
  consumeDiscount: 'Consume',
  systemPointsClaimed: 'Reviewed & Points earned',
  prizePoints: 'Prize Points',
  youWonSmall: 'You Won the Prize',
  transferPointsSubTitle: 'Transfer some or all of your balance to your connection using their Rab7 Number',
  transferRab7PointsShort: 'Transfer Points',
  transferButtonPoints: 'Transfer',
  promptDeleteMessage: 'Deleting the chat will end the chat, and they won\'t able to communicate with you until you initiate it again. Are you sure you want to delete chat?',
  promptDeleteMessages: 'Deleting the chats will end the chat, and they won\'t able to communicate with you until you initiate it again. Are you sure you want to delete chats?',
  charity: 'Charity',
  noCharity: 'No Charity Found',
  unclaimedBalance: '{0} unclaimed',
  distanceKms: {
    'one': '{count} Km',
    'other': '{count} Kms'
  },
  chooseAnswerToQuestion: 'Select answer from the available question options',
  relationshipCharity: 'Charity',
  expenses: 'Expenses',
  categories: 'Categories',
  noExpenses: 'No Expenses',
  rab7Points: 'Rab7 Loyalty Points',
  visitsCount: {
    'one': '{count} Visit',
    'other': '{count} Visits'
  },
  votings: 'Votings',
  noVotings: 'No Votings Found',
  noTransactions: 'No Transactions',
  signupInformationRegardingMessage: 'Rab7 provide amazing offers for user who collecting Rab7 points, and since we want you to consume these, we will grant you free points with each step of registration.',
  branch: 'Branch',
  branchReviews: 'Branch Reviews',
  addReviewValidationMessage: 'Fill mandatory fields: business, branch, rating, feedback',
  addReviewAckValidationMessage: 'Fill mandatory fields: rating, feedback',
  addReviewAckWQuestionValidationMessage: 'Fill mandatory fields: rating, feedback as either text or audio, pick answer',
  experienceNearby: 'Experience Nearby',
  survey: 'Survey',
  noActivityInNearbyMessage: 'Sorry, nothing to experience nearby, please check again later.',
  distanceMeters: {
    'one': '{count} meter',
    'other': '{count} meters'
  },
  ratingDoneAlready: 'You have already added your rating, put comment only.',
  enterRating: 'Please enter rating',
  shiftingToEarnMore: 'we are shifting you to earn more',
  continueSignup: 'Continue to signup',
  contestRules: 'Contest Rules',
  earnMoreRab7PointsToConsume: 'Earn {0} Rab7 points more to consume',
  transactionAlreadyReviewed: 'You have already reviewed this visit',
  registrationHintGender: 'Choosing your gender is a way to reach your suitable offer for that before anyone, and we will provide to you XXX Points to consume them',
  registrationHintNationality: 'Your nationality the you proud of, we proud of it as well, and we will look for all exceptional offers for it, and will grant to you XXX Points to use it in that',
  registrationHintBirthday: 'Your birthday is important to make you see the desirable offer that meet your age before anyone, and with XXX Points that we will gift you, you can consume them',
  registrationHintCity: 'Knowing the nearby offers before anyone is too important, do choosing your city and district is important to make these offers available for you before anyone else, and will grant you XXX Points',
  rab7PointsEarnLimitReached: 'You have reached the daily earning limit of Points. You will no longer earn points today. Please try again tomorrow.',
  limitReached: 'Limit Reached',
  noSmartAdFoundMessage: 'Sorry, no Smart Ad found, please check again later.',
  opinion: 'Opinion',
  ad: 'Ad',
  myOpinions: 'My Opinions',
  recommendBusiness: 'Recommend Business',
  startingInTime: 'Starting in {0}',
  endingInTime: 'Ending in {0}',
  tncpp: 'Terms of Use & Privacy Policy',
  bySigningIn: 'By signing up, you agree to our',
  rab7PointsNeeded: 'Rab7 Loyalty Points Needed',
  rab7PointsNeededToConsume: 'count of Rab7 points needed to consume',
  consumableWeekDays: 'Consumable on Day',
  consumableWeekDaysMessage: 'offer can only be consumed on',
  buyerRatings: 'Buyer Ratings',
  visitorRatings: 'Visitor Ratings',
  valueInBrackets: '({0})',
  products: 'Items',
  noBusinessProducts: 'No Items Found',
  businessProducts: 'Business Items',
  productCategory: 'Item Category',
  businessProduct: 'Business Item',
  likeProduct: 'Like Item',
  initiateChat: 'Initiate Chat',
  initiateChatMessage: 'Initiate chat with the business regarding this product',
  enterMessage: 'Enter Message',
  noAvailability: 'No Availability',
  offerCannotTransferred: 'Offer is not transferable',
  shiftingToReviewVisit: 'we are shifting you to review your visit',
  reviewVisit: 'Review Visit',
  shareExperienceInOffer: 'Share your experience in consuming offer',
  shareExperienceInOffer2nd: 'with other customers so they gets benefit from that and it will be available in offer reviews',
  shareExperience: 'Share your experience at',
  shareExperience2nd: 'with other customers so they gets benefit from that and it will be available in feeds',
  hintQuestionInReview: 'Earn more and maximize your experience at',
  hintQuestionInReview2nd: 'by answering the following survey',
  shareExperienceInBusiness: 'Share your experience at business with other customers so they gets benefit from that and it will be available in feeds',
  businessAndBranchName: '{business} ({branch})',
  list: 'List',
  map: 'Map',
  byCategory: 'Category',
  byBusiness: 'Business',
  businessChats: 'Business Chats',
  chats: 'Chats',
  noBusinessChats: 'No Business Chats',
  chatsIn: 'Chats in {businessName}',
  noChats: 'No Chats',
  profilePendingMessage: 'Rab7 provides amazing offers, please complete your profile to receive recommended offers, click here to complete it',
  stepsLeft: '{0} steps left',
  stepLeft: '{0} step left',
  statistics: 'Statistics',
  at: 'at',
  total: 'Total',
  earnedPoints: 'You have earned points',
  businessPointsNeededMessage: 'business wallet balance needed to consume',
  offerFeeMessage: 'business wallet balance to be deducted when consume',
  consumableHoursMessage: 'offer can only be consumed within hours',
  consumableHours: 'Consumable Hours',
  answerSuccess: 'Thanks for answering the survey',
  opinionSuccess: 'Thanks for sharing your feedback',
  youHaveAnswered: 'You have answered this survey.',
  youHaveReviewed: 'You have shared your feedback.',
  am: 'AM',
  pm: 'PM',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  pointsAddedOnConsume: 'Points reversed on Consume',
  noneBranch: 'None Branch',
  allBranches: 'All Branches',
  from: 'from',
  to: 'to',
  offerDurationMessage: 'Offer is available',
  consumableOnBirthday: 'Consumable on your birthday',
  everyday: 'Everyday',
  openToday: 'Open Today',
  closedToday: 'Closed Today',
  openNow: 'Open Now',
  closedNow: 'Closed Now',
  allDay: 'All Day',
  orderNow: 'Order Now',
  storeTimings: 'Store Timings',
  consumeAtStore: 'Consume at Store',
  addToBasket: 'Add To Cart',
  applyToBasket: 'Apply To Cart',
  maxDiscount: 'Maximum Discount',
  minPurchaseAmount: 'Minimum Purchase Amount',
  order: 'Order',
  confirmLocation: 'Confirm Location',
  enableLocation: 'Enable Location',
  updateManually: 'Update Manually',
  locationNotUpdated: 'Location Not Updated',
  locationNotUpdatedMessage: 'Please update your current location to see the relevant stores around you',
  updateLocation: 'Update Location',
  unableToDetectCurrentLocationWithUpdate: 'Unable to detect current location, please try manual location update',
  unableToDetectCurrentLocationDeniedWithUpdate: 'Unable to detect current location since the permission is denied, please allow access to location or try manual location update',
  unableToDetectCurrentLocation: 'Unable to detect current location',
  unableToDetectCurrentLocationDenied: 'Unable to detect current location since the permission is denied, please allow access to location',
  pickFromMap: 'Pick From Map',
  useCurrentLocation: 'Use Current Location',
  savedAddressesCapitalize: 'SAVED ADDRESSES',
  searchCity: 'Search City',
  noSavedAddresses: 'No Saved Addresses',
  currentLocation: 'Current Location',
  pickedFromMap: 'Picked From Map',
  noNearbyStoresFound: 'No Nearby Stores Found',
  day: 'day',
  days: 'days',
  hours: 'hours',
  mins: 'mins',
  searchStores: 'Search store, dish',
  allNearbyStoresCapitalize: 'ALL NEARBY STORES',
  deliveryTime: 'Delivery Time',
  distance: 'Distance',
  itemsCapitalize: 'ITEMS',
  noItems: 'No Items',
  allItems: 'All Items',
  discountOff: '{0}% off',
  itemsCountWithValue: '{0} Items',
  product: 'Item',
  additionalMoneyWithValue: '+ {0} {1}',
  addToCart: 'Add to Cart',
  itemAdded: 'Item Added',
  unableToAddToCart: 'Unable to Add to Cart',
  minOptionsText: {
    'one': 'please select atleast one option',
    'other': 'please select atleast {count} options'
  },
  maxOptionsText: {
    'one': 'you can select upto one option',
    'other': 'you can select upto {count} options'
  },
  maxOptionsTextStartComma: {
    'one': ', you can select upto one option',
    'other': ', you can select upto {count} options'
  },
  cartFetchFailed: 'Unable to fetch Cart',
  zeroItems: 'No Items',
  cartIsEmpty: 'Cart is Empty',
  replaceCartItem: 'Replace Cart Items',
  replaceCartItemMessage: 'Your cart contains items from store\n"{oldBusiness}"\nDo you want to discard the existing cart items and add items from store\n"{newBusiness}"?',
  replaceCartItemWithinBusinessMessage: 'Your cart contains items from store\n"{oldBusiness} ({oldBranch})"\nDo you want to discard the existing cart items and add items from store\n"{newBusiness} ({newBranch})"?',
  yes: 'Yes',
  no: 'No',
  noItemsCart: 'Cart is Empty',
  cart: 'Shopping Cart',
  itemsWithCount: 'Items ({count})',
  deleteStoreItems: 'Delete Store Items',
  deleteStoreItemsMessage: 'Are you sure you want to delete all the items from cart?',
  deleteItem: 'Delete Item',
  deleteItemMessage: 'Are you sure you want to delete the item from cart?',
  offerApplied: 'Offer Applied',
  itemUpdated: 'Item Updated',
  updateCart: 'Update Cart',
  payNPlaceOrder: 'Pay & Place Order',
  placeOrder: 'Place Order',
  viewDetails: 'View Details',
  free: 'Free',
  deliveryCharges: 'Delivery Charges',
  deliveryFee: 'Delivery Fee',
  storeRequest: 'Any Store Request? Enter here',
  applyDiscount: 'Apply Discount',
  discountApplied: 'Discount Applied',
  edit: 'Edit',
  remove: 'Remove',
  offerAdded: 'Offer Added',
  unableToUpdateToCart: 'Unable to Update to Cart',
  youWillEarn1: 'You will earn',
  youWillEarn2: 'loyalty points',
  billDetails: 'Bill Details',
  itemTotalInclusiveTaxes: 'Items Total',
  paidUsingWallet: 'Paid Using Wallet',
  totalAmount: 'Total Amount',
  totalDiscountInCart: 'Discount',
  toPay: 'To Pay',
  earning: 'Earning',
  taxes: 'Taxes',
  savedAddresses: 'Saved Addresses',
  distanceKmsFromCurrentLocation: {
    'one': '{count} Km from current location',
    'other': '{count} Kms from current location'
  },
  distanceMetersFromCurrentLocation: {
    'one': '{count} meter from current location',
    'other': '{count} meters from current location'
  },
  selectAddress: 'Select Address',
  addNewAddress: 'Add New Address',
  deliveryAt: 'Delivery At',
  deleteAddress: 'Delete Address',
  deleteAddressMessage: 'Are you sure you want to delete the address?',
  addressDeletedSuccessfully: 'Address Deleted Successfully',
  addresses: 'Addresses',
  moveMapToAdjust: 'Move Map to adjust',
  addAddress: 'Add Address',
  editAddress: 'Edit Address',
  addressType: 'Address Type',
  placeholderAddress: 'Apartment / Building / Street',
  placeholderLandmark: 'Nearest Landmark',
  home: 'Home',
  work: 'Work',
  other: 'Other',
  chooseLocation: 'Choose Location',
  placingOrder: 'Placing Order',
  placingOrderMessage: 'Your order is in process, this may take some time, please do not press back or exit the application',
  processingPayment: 'Processing Payment',
  processingPaymentMessage: 'Your payment is in process, this may take some time, please do not press back or exit the application',
  orderPlaced: 'Order Placed',
  orderWithId: 'Order #{0}',
  orderPlacedMessage: 'Your order is currently being processed.\nYou will receive order confirmation notification shortly, when the store will confirm this order.',
  earningClaimMessage: 'You can claim your earning when the order gets delivered',
  continueShopping: 'Continue Shopping',
  trackOrder: 'View Order',
  paymentFailed: 'Payment Failed',
  paymentIsInProcessing: 'Payment is in Processing',
  paymentIsInProcessingMessage: 'Payment is in processing, you can check the payment status in orders in profile or try again to check the status',
  paymentCancelled: 'Payment Cancelled',
  paymentFailedMessageGeneric: '{0}\n\nIf the amount gets deducted, payment from card will be reverted back in 3-15 days, payment through wallet and offer consumed will be reverted back in some time. Please connect with support via chat if you need any assistance.\nTransaction Id: {1}',
  paymentCancelledMessageGeneric: 'Your payment has been cancelled.\n\nIf the amount gets deducted, payment from card will be reverted back in 3-15 days, payment through wallet and offer consumed will be reverted back in some time. Please connect with support via chat if you need any assistance.\nTransaction Id: {0}',
  deliveryWithin: 'Delivery Within',
  branchNotAvailable: 'Store not Available',
  branchNotAvailableMessage: 'Store is not available for delivery to selected location. You can check other nearby stores of same business. Location can be updated from the top.',
  branchNBusinessNotAvailableMessage: 'Store is not available for delivery to selected location. You can check other nearby stores. Location can be updated from the top.',
  selectNearbyStoreCapitalize: 'SELECT NEARBY STORE',
  addMoreItemsToCart: 'Add More Items to Cart',
  addressAdded: 'Address Added',
  addressUpdated: 'Address Updated',
  inclusiveTaxes: 'Amount Inclusive of Taxes',
  offerAvailable: 'Offer Available',
  offersAvailableMessage: 'select the offer you want to apply, or add item to cart without applying offer',
  branchesNotAvailable: 'Stores not Available',
  offerPrice: 'Offer Price',
  orders: 'Orders',
  driverCapitalize: 'DRIVER',
  myRequests: 'Job Requests',
  businessInvitations: 'Business Invitations',
  joinedBusinesses: 'Joined Businesses',
  shipments: 'Shipments',
  noPendingInvitations: 'No Pending Invitations',
  notJoinedToBusinesses: 'Not Joined to Businesses',
  rejected: 'Rejected',
  invited: 'Invited',
  inactive: 'Inactive',
  active: 'Active',
  acceptInvitation: 'Accept Invitation',
  acceptInvitationMessage: 'Are you sure you want to server the business as driver (TairShalwa)?',
  rejectInvitation: 'Reject Invitation',
  rejectInvitationMessage: 'Are you sure you are not ready to server the business as driver (TairShalwa)?',
  deactivateBusinessJoining: 'Deactivate Business Serving',
  deactivateBusinessJoiningMessage: 'Are you sure you want to deactivate yourself from serving this business?',
  activateBusinessJoining: 'Activate Business Serving',
  activateBusinessJoiningMessage: 'Are you sure you want to activate yourself in serving this business?',
  businessDriverServing: 'Business Driver (TairShalwa) Joining',
  accept: 'Accept',
  deleteDriver: 'Remove Business Serving',
  deleteDriverMessage: 'Are you sure you want to remove yourself from serving this business?',
  deliveryDistance: 'Delivery Distance',
  deactivate: 'Deactivate',
  activate: 'Activate',
  driverInvitationMessage: 'invites you to serve as driver (TairShalwa)',
  jobRequestInfo: 'Job Request Info',
  nationalIdPhoto: 'National Id Photo',
  informationSaved: 'Information Saved',
  jobRequests: 'Job Requests',
  noJobRequests: 'No Job Requests',
  jobRequestInformation: 'Job Request Information',
  noJobRequestInformationAdded: 'No job request information added, add new',
  deleteJobInformationMessage: 'Deleting job request information also delete all of your requests made for job.\nAre you sure you want to delete?',
  deleteJobInformation: 'Delete Job Request Information',
  hiringDrivers: 'Hiring Drivers (TairShalwa)',
  joinAsDriver: 'Join as Driver (TairShalwa)',
  joinAsDriverMessage: 'Send your request to the business to join as driver (TairShalwa), you will notified when business approve or reject your request',
  joinAsDriverMessageAddInfo: 'You need to update your information before sending request. Update now',
  send: 'Send',
  requestToJoinSent: 'Request Sent',
  requestToJoinSentMessage: 'Your request to join business has been submitted and is under review by business, you will be notified once it is processed. You can check all your job requests in your profile',
  requestToJoinSentAgainMessage: 'Your request to join business has been updated and is under review by business, you will be notified once it is processed. You can check all your job requests in your profile',
  jobRequest: 'Job Request',
  resendRequest: 'Resend Request',
  applied: 'Applied',
  resendDriverRequestMessage: 'Are you sure you want to apply for the job again?',
  resendOnly: 'Resend',
  deleteJobRequest: 'Remove Job Request',
  deleteJobRequestMessage: 'Are you sure you want to remove your job request?',
  addJobRequest: 'Add Job Request',
  updateJobRequest: 'Update Job Request',
  hintLeaveBranchEmptyDriverRequest: '# select branch(es) in which you want to send job request, leave empty if you want to apply in all',
  requestUpdated: 'Request Updated',
  business: 'Business',
  driverRequestUpdateSuccessMessage: 'approved your job request and added to their team',
  driverRequestUpdateRejectMessage: 'rejected your job request',
  driverOpenings: 'Driver (TairShalwa) Openings',
  assignPickupMessage: 'Are you sure you want to accept the delivery of the order?',
  cancelPickupMessage: 'Are you sure you want to cancel the delivery of the order?',
  cancelDeliveryMessage: 'Are you sure you want to cancel the delivery of the order?',
  cancelOrderMessage: 'Are you sure you want to cancel the order?',
  cancelOrderAfterDeliverMessage: 'Order is marked as delivered by driver (TairShalwa), if you have received it and want to consider, please make action as received to let the driver (TairShalwa) not charged for it. Are you sure you want to cancel the order?',
  cancelNewOrderMessage: 'We have not received payment confirmation for this order, if you have made payment for this order but quit from the payment process, please do not cancel this order.\nIf you cancel this order and we receive payment confirmation, your order will be cancelled and your payment will be refunded back with deducting transaction charges for the payment.\nAre you sure you want to cancel order?',
  deliveryAllowedMessage: 'Delivery allowed till {0}, {1}',
  assignPickupOrderWithNumber: 'Accept Order Delivery #{value}',
  cancelOrderPickupWithNumber: 'Cancel Order Delivery #{value}',
  cancelOrderWithNumber: 'Cancel Order #{value}',
  deliverOrderWithNumber: 'Deliver Order #{value}',
  deliverOrderMessage: 'Are you sure you want to complete the order?',
  withAllItems: 'with all items',
  withSelectedItems: 'with selected items',
  reasonDetails: 'Reason Details',
  confirm: 'Confirm',
  enterVerificationCodeFromCustomer: 'Enter verification code generated at customer\'s end',
  deliver: 'Deliver',
  selectCancelReason: 'Select Cancel Reason',
  orderDataNoLatest: 'Order Data Inconsistent',
  onlyAvailableWithValue: 'Only {value} Available',
  cancelledItemsWithValue: {
    'one': '{count} Item Cancelled',
    'other': '{count} Items Cancelled'
  },
  notAvailable: 'Not Available',
  orderActions: 'Order Actions',
  orderStateAll: 'All',
  orderStateReadyToPick: 'Ready',
  orderStateDriverAssigned: 'Assigned',
  orderStateDelivered: 'Delivered',
  orderStateCancelled: 'Cancelled',
  noOrdersAvailable: 'No Shipments Available',
  noDriverAssignedOrders: 'No Shipments Assigned',
  noDeliveredOrders: 'No Shipments Delivered',
  noCancelledOrders: 'No Shipments Cancelled',
  noShipments: 'No Shipments',
  noOrders: 'No Orders',
  searchDriverOrder: 'Search order no, amount, customer',
  searchOrder: 'Search order no, amount, store',
  customersLocation: 'Customer\'s Location',
  "generic.agoDayWAgo": '{count} day ago',
  "generic.agoDaysWAgo": '{count} days ago',
  "generic.agoHourWAgo": '{count} hour ago',
  "generic.agoHoursWAgo": '{count} hours ago',
  "generic.agoMinuteWAgo": '{count} minute ago',
  "generic.agoMinutesWAgo": '{count} minutes ago',
  "generic.agoMonthWAgo": '{count} month ago',
  "generic.agoMonthsWAgo": '{count} months ago',
  "generic.agoSecondWAgo": '{count} second ago',
  "generic.agoSecondsWAgo": '{count} seconds ago',
  "generic.agoYearWAgo": '{count} year ago',
  "generic.agoYearsWAgo": '{count} years ago',
  orderNoWithNumber: 'Order No #{0}',
  itemsCountWithCount: {
    'one': '{count} Item',
    'other': '{count} Items'
  },
  confirmationByMessage: 'Confirmation by {0}, {1}',
  deliveryByMessage: 'Delivery by {0}, {1}',
  pickupReadyByMessage: 'Package ready by {0}, {1}',
  approvalAllowedMessage: 'Items review approval allowed upto {0}, {1}\nDifference in payment will be refunded when the order gets delivered',
  delayed: 'Delayed',
  storeLocation: 'Store Location',
  cancelled: 'Cancelled',
  refundInWallet: 'Refund in Wallet',
  paidUsingCard: 'Paid Using Card',
  refundInCard: 'Refund in Card',
  refundWillInitiateOnOrderCompletion: 'Refund will be initiated when the order gets completed',
  approveOrderWithNumber: 'Approve Order #{value}',
  approveOrderMessage: 'Approving the order will make the order continue with available items. Difference in payment will be refunded when the order gets delivered. Are you sure you want to approve the order with the available items only?',
  deliveryAddress: 'Delivery Address',
  items: 'Items',
  otpInfoMessage: 'Verification Code for delivery is',
  driverOtpInfoMessage: 'Verification Code for delivery pickup is',
  offerFees: 'Offer Fee',
  offerFeesDeductibleFromWallet: '(deductible from wallet)',
  offerFeesDeductedFromWallet: '(deducted from wallet)',
  offerFeesRevertedToWallet: '(reverted to wallet)',
  driverLocation: 'Driver (TairShalwa) Location',
  deliveringTo: 'Delivering to',
  orderFromStore: 'Order from Store',
  retryLocation: 'Pick Location Again',
  driverLocationNotAvailable: 'Driver (TairShalwa) location not available',
  receivedOrderWithNumber: 'Received Order Delivery #{value}',
  receivedOrderMessage: 'Are you sure you have received the order?',
  addressNotAvailableForOrder: 'Sorry, this location is not serviceable currently for online orders, please choose another location',
  storePickup: 'Pickup',
  dineIn: 'Dine In',
  addCard: 'Add Card',
  cardDeleted: 'Card Deleted',
  deleteCard: 'Delete Card',
  deleteCardMessage: 'Are you sure you want to delete the card?',
  addNewCard: 'Add New Card',
  savedCardsCapitalize: 'SAVED CARDS',
  noSavedCards: 'No Saved Cards',
  savedCards: 'Saved Cards',
  selectPaymentMethod: 'Select Payment Method',
  selectPayment: 'Select Payment',
  placeOrderQ: 'Place Order?',
  continue: 'Continue',
  cameraPermissionDenied: 'Camera Permission Denied',
  permissionDeniedCamera: 'You have denied the permission to access device camera',
  permissionDeniedCameraMessage: '\nYou have denied the permission to access camera.\n\nWe need access to camera to capture images and upload in post.\n\nGrant camera permission from device settings to capture images and post them. Open device settings?',
  microphonePermissionDenied: 'Microphone Permission Denied',
  permissionDeniedMicrophoneMessage: '\nYou have denied the permission to access microphone.\n\nWe need access to microphone to record audio and upload in post.\n\nGrant microphone permission from device settings to record audio and post it. Open device settings?',
  permissionDeniedMicrophone: 'You have denied the permission to access microphone',
  photoPermissionDenied: 'Photos Permission Denied',
  permissionDeniedPhotoMessage: '\nYou have denied the permission to access photos.\n\nWe need access to photos to select images from gallery and upload in post.\n\nGrant photos permission from device settings to select images and post it. Open device settings?',
  permissionDeniedPhoto: 'You have denied the permission to access photos',
  permissionDeniedStorageMessage: '\nYou have denied the permission to access device storage.\n\nWe need access to device storage to capture images using camera and save them on device storage and upload in post.\n\nGrant storage permission from device settings to capture images and post them. Open device settings?',
  storagePermissionDenied: 'Storage Permission Denied',
  permissionDeniedStorage: 'You have denied the permission to access device storage',
  locationPermissionDenied: 'Location Permission Denied',
  permissionDeniedLocation: 'You have denied the permission to access device location',
  permissionDeniedLocationAlwaysMessage: '\nYou have denied the permission to access device location always even when app in background.\n\nWe need access to device location always in background to share your location with store and customer whose package you are delivering, so that they can track your current location.\n\nGrant location permission always even in background from device settings to share your live location. Open device settings?',
  openSettings: 'Open Device Settings',
  noOpinions: 'No Opinions',
  stopDelivering: 'Stop Delivering',
  stopDeliveringMessage: 'You wont be able to ship or view new shipments if you stop delivering. Are you sure you want to stop delivering the shipments in associated businesses?',
  startDelivering: 'Start Delivering',
  startDeliveringMessage: 'Are you sure you want to start delivering the shipments in associated businesses?',
  start: 'Start',
  stop: 'Stop',
  shipmentDelivering: 'Delivering Shipments',
  suspended: 'Suspended',
  engaged: 'Engaged',
  inAllTheBranches: 'In all the branches',
  inSelectedBranches: 'In selected branches',
  orderItemsReviewNeededWithOrderNo: 'asked to review items in order #{value}',
  orderConfirmedWithOrderNo: 'confirmed order #{value}',
  orderReadyToPickWithOrderNo: 'packed order #{value}',
  orderShippedWithOrderNo: 'shipped order #{value}',
  orderDeliveryCancelledWithOrderNo: 'delivery failed for order #{value}',
  orderDeliveredWithOrderNo: 'delivered order #{value}',
  orderCancelledWithOrderNo: 'cancelled order #{value}',
  orderRefundedWithOrderNo: 'refunded order #{value}',
  messagedInOrderWithOrderNo: 'messaged you in order #{value}',
  ratingForDriver: 'Rating for Driver (TairShalwa)',
  ratingForCashier: 'Rating for Cashier',
  ratingForBusiness: 'Rating for Business',
  ratingForOffer: 'Rating for Offer',
  reviewYourOrderWithValue: 'Review Order #{value}',
  orderWithValue: 'Order #{value}',
  enable: 'Enable',
  delivered: 'Delivered',
  refundOfferFees: 'Refund Offer Fees',
  buyAgain: 'Buy Again',
  orderAgainMessage: 'All items from this order will be added to the cart, this will replace existing cart items if they are from different store. Are you sure you want to add these items to cart?',
  pickupExtraMessage: '# share your vehicle details like number, color, etc in store request to ease the pickup',
  refundCharges: 'Refund Charges',
  refundChargesMessage: '(cancelled by customer)',
  viewOrders: 'View Orders',
  checkStatusAgain: 'Check Status Again',
  notDeliverable: 'Not Deliverable',
  deliverable: 'Deliverable',
  delivery: 'Delivery',
  noDelivery: 'No Delivery',
  rates: 'Rates',
  fixedRate: 'Fixed Rate',
  noRate: 'No Rate',
  notSet: 'Not Set',
  deliverySuccessRate: 'Delivery Success Rate',
  deliveryFailureRate: 'Delivery Failure Rate',
  driverHiringInfo: 'Driver (TairShalwa) Job Hiring Info',
  driverHiringBranches: 'Driver (TairShalwa) Hiring in Branches',
  noDriverOpenings: 'No Driver (TairShalwa) Openings',
  encash: 'Encash',
  promptEncash: 'Are you sure you want to make request to encash money?',
  promptEncashMessage: 'Ensure you are encashing at associated branch(es) only. It is recommended to make request to encash money within the branch, since only few minutes are provided to encash.',
  hintEnterMoneyToEncash: 'Enter money to encash',
  driverEarningEncash: 'Driver (TairShalwa) Earning Encash',
  byItem: 'Item',
  rateItem: 'Rate Item',
  wallet: 'Wallet',
  deliveryInsight: 'Delivery Insight',
  offerExpired: 'the offer is ended',
  orderingBans: 'Business Banned Me',
  noOrderingBans: 'No Businesses banned you',
  hintOrderingBans: 'Businesses that have banned you from ordering in their stores',
  consumeWalletMoney: 'Consume Wallet Money',
  selectWalletMoneyToConsume: 'select money to consume between {0} and {1}',
  walletBalanceIsLowThanSelected: 'Wallet Balance is lower than selected',
  reviewItem: 'Review Item',
  subTotalWithItemsCount: {
    'one': 'Subtotal ({count} Item)',
    'other': 'Subtotal ({count} Items)'
  },
  addCapitalize: 'ADD',
  customizable: 'customizable',
  tellAboutLiked: 'Tell about what you liked',
  tellAboutDisLiked: 'Tell about what you disliked',
  postAsAnonymous: 'Post as Anonymous',
  commentAsAnonymous: 'Comment as Anonymous',
  ring: 'Notify Customer',
  ringCustomerDevice: 'Notify Customer with Ringing Device',
  ringCustomerDeviceMessage: 'Notify Customer with ringing their device if you need urgent assistance from the customer regarding the order delivery. Are you sure you want to notify customer?',
  ringCustomerDeviceSent: 'Ring Notification Sent to Customer',
  rab7Support: 'Rab7 Support',
  noMessagesSupport: 'No Messages Yet\n\nIf you need any assistance, please send a message',
  happyToAssistYou: 'happy to assist you',
  endChat: 'End Chat',
  requestResolved: 'Request Resolved',
  requestResolvedMessage: 'Are you sure you want to end the chat session as the issue has been resolved?',
  connectLater: 'Connect Later',
  connectLaterTitle: 'Connect Later',
  endChatMessages: 'Are you sure you want to end the chat session?',
  chatStartMessage: 'Dear Customer, thank you for contacting Rab7 support. Your service request number is #{value}. Please mention your query in detail, we will get back to you at the earliest.',
  notificationChannelNormal: 'Normal Notifications',
  notificationChannelNormalDesc: 'Notifications for actions on your posts, for making transaction in store, application release updates and other system information',
  notificationChannelImportant: 'Important Notifications',
  notificationChannelImportantDesc: 'Notifications from support messages, updates in your orders',
  notificationChannelVeryImportant: 'Very Important Notifications',
  notificationChannelVeryImportantDesc: 'Notifications from updates in orders for drivers when delivery available',
  notificationChannelRing: 'Device Ring Notifications',
  notificationChannelRingDesc: 'Notify when delivery person needs instant assistance for your order delivery',
  noStaffForChat: 'No Staff available for chat, please connect later',
  selectStoreToConnect: 'Select Store to Connect',
  selectBranchForChat: 'Select Branch',
  connect: 'Connect',
  branchChats: 'Connect to Store Staffs',
  manageChats: 'Manage Chats',
  deleteChat: 'Delete Chat',
  deleteEmployeeChatMessage: 'Deleting the chat will delete it from chats list and prevent the staff members to connect you. However you can initiate the chat again from business or store profile.\nAre you sure you wnt to delete the chat?',
  chatDeleted: 'Chat Deleted',
  profileUpdateRestrictionMessage: 'You are allowed to modify your details only once in a month except name, photo and mobile number, which you can update as much as you want.',
  fromTo: '{from} to {to}',
  campaignReviews: 'Campaign Reviews',
  campaignReview: 'Campaign Review',
  reportCampaignReview: 'Report Campaign Review',
  commentedOnActivityReview: 'commented on your post',
  repliedOnActivityReview: 'replied in campaign review',
  shareOpinionFeedback: 'Share your opinion in campaign',
  shareCampaignFeedback: 'Share your feedback in campaign',
  shareCampaignFeedback2nd: 'with other customers so they gets benefit from that and it will be available in campaign reviews',
  reviewCampaign: 'Review Campaign',
  by: 'by',
  requestedToShareContact: 'requested to share contact number in review',
  stopContactNumberSharing: 'Stop Contact Number Sharing',
  stopContactNumberSharingMessage: 'Your contact number is shared with the business, are you sure you want to stop the sharing?',
  shareContactNumber: 'Share Contact Number',
  shareContactNumberMessage: 'Your contact number is not shared with the business, are you sure you want to share your contact number?',
  contactNumberShared: 'Contact Number Shared',
  contactNumberSharingStopped: 'Contact Number Sharing Stopped',
  businessIsRequestingContactNumber: 'Business is requesting your contact number',
  myCampaignReviews: 'My Campaign Reviews',
  activityReviews: 'Reviews',
  locationNeededInBackground: 'Location Needed Always',
  locationNeededInBackgroundMessage: 'This app collects location data to share driver current location for the assigned order with the customer even when the app is closed or not in use.',
  grant: 'Grant',
  permissionDeniedLocationDriverMessage: 'You have denied the permission to access device location.\nPlease grant location permission always even when the app is closed or not in use to provide the real time tracking of the delivery.\nLocation is only tracked during the period the delivery is assigned to you.',
  locationNotUpdatedDriverMessage: 'Please enable location always even when the app is closed or not in use to provide the real time tracking of the delivery.\nLocation is only tracked during the period the delivery is assigned to you.',
  editPhoto: 'Edit Photo',
  processingImage: 'Processing Image..',
  locationServiceDisabled: 'Location Services Disabled',
  locationServiceDisabledMessage: 'You have disabled device location services. Please enable location services to load data based on your location.\nEnable it in Device Settings -> Privacy -> Location Services',
  noLikes: 'No Likes',
  reviewLikes: 'Review Likes',
  notifyLikes: 'Notify Likes',
  earnFormulaInformation: 'Earn & Consume Formula Information',
  maximumConsumeSpecificAmountHint: 'amount that can be consumed as discount is specific from below values only',
  maximumConsume: 'Consuming Limit',
  maximumConsumeHint: 'amount that can be consumed as discount',
  deliveryTimings: 'Delivery Timings',
  unclaimedBalancePresent: 'You have unclaimed balance present',
  unclaimedBalancePresentMessage: 'click here to review and claim your earnings',
  allowed: 'Allowed',
  notAllowed: 'Not Allowed',
  consumeBalance: 'Consume Balance',
  consumeBalanceMessageHint: 'balance can be consumed as discount in online orders',
  billIssuedToEn: 'Issued To',
  billNumberWithValueEn: 'Bill No.: #{value}',
  dateWithValueEn: 'Date: {value}',
  timeWithValueEn: 'Time: {value}',
  tinWithValueEn: 'TIN: {value}',
  totalIncludingVATEn: 'Total (Including VAT)',
  totalEn: 'Total',
  vatWithValueEn: 'VAT ({value}%)',
  itemTitleInBillEn: 'Item',
  salesTitleInBillEn: 'Sales',
  amountTitleInBillEn: 'Amount',
  rab7ChargesInBillEn: 'Rab7 Charges',
  paymentGatewayChargesInBillEn: 'Payment Gateway Charges',
  quantityTitleInBillEn: 'Quantity',
  billIssuedToAr: 'أصدرت لـ',
  billNumberWithValueAr: 'رقم الفاتورة: #{value}',
  dateWithValueAr: 'التاريخ: {value}',
  timeWithValueAr: 'الوقت: {value}',
  tinWithValueAr: 'الرقم الضريبي: {value}',
  totalIncludingVATAr: 'المجموع (شامل ضريبة القيمة المضافة)',
  totalAr: 'المجموع',
  vatWithValueAr: 'ضريبة القيمة المضافة (%{value})',
  itemTitleInBillAr: 'الخدمة',
  salesTitleInBillAr: 'المبيعات',
  amountTitleInBillAr: 'الكمية',
  rab7ChargesInBillAr: 'رسوم رابح',
  paymentGatewayChargesInBillAr: 'رسوم بوابة الدفع',
  quantityTitleInBillAr: 'كمية',
  showArabic: 'View Arabic',
  showEnglish: 'View English',
  availableIn: 'Available in',
  only: 'only',
  comma: ',',
  rab7ServiceCharges: 'Rab7 Service Charges',
  generateOrderPickupTicketWithNumber: 'Generate Pickup Ticket for Order #{value}',
  generateOrderPickupTicketMessage: 'By showing order pickup ticket, you confirm you are in the shop and willing to take the order and deliver it to customer whose details will be displayed once you generate pickup ticket.\nBe aware your disengagement with the order will be allowed by store staff only.\nAre you sure you want to generate order pickup ticket?',
  generate: 'Generate',
  notNow: 'Not Now',
  orderPickupTicket: 'Order Pickup Ticket',
  customer: 'Customer',
  driver: 'Driver',
  rab7HashWithValue: 'Rab7 #{value}',
  deliveringOrderWithoutCodeWithNumber: 'Delivering Order #{value} Without Code',
  deliverOrderWithoutCodeMessage: 'Delivering the order without code increases the possibility of raising dispute of order not delivered by customer. If in any case customer claims it, order is considered as not delivered and you will be charged for the same. Always deliver the order with entering the code from the customer.\n\nAre you sure you want to deliver the order without code?',
  uptoKmsMoneyWithValue: 'Upto {value1} Kms: {value2} {value3}',
  afterKmsMoneyWithValue: 'Extra Per Km after {value1} Kms: {value2} {value3}',
  rab7ServiceFeeReceipt: 'Rab7 Service Fee Bill',
  helpNSupport: 'Help & Support',
  helpNSupportInOrderMessage: 'Select the issue regarding the order',
  selectIssue: 'Select Issue',
  raiseDispute: 'Raise Issue',
  disputeInProgressMessage: 'Your last raised issue is in progress.',
  needMoreHelpMessage: '\nNeed more help?\n\nYou can reach to store directly.',
  contactStore: 'Contact Store',
  contactUs: 'Contact Us',
  orderIssueResolvedWithOrderNo: 'resolved issue with refund in order #{value}',
  orderIssueClosedWithOrderNo: 'closed issue in order #{value}',
  forgotPasswordDetail: 'Please write your mobile number to recover your password. We will send verification code on your registered mobile number, you need to enter the code and reset the password on next screen.',
  verificationCodeDetails: 'Enter verification code sent to your mobile number',
  discountOnDelivery: 'Discount on Delivery',
  discountOnItems: 'Discount on Items',
  businessPointsNeeded: 'Wallet Balance Needed',
  offerFee: 'Wallet Balance Deduction',
  selectStore: 'Select Store',
  selectProduct: 'Select Item in Offer',
  noProductsAvailableInOffer: 'No Items Available for the Offer',
  customerDistanceFromStore: 'Customer\'s distance from store',
  pickupTicketMessage: 'Show this pickup ticket in store and collect the parcel.\nWhile delivering package to customer, ask order confirmation code from customer and enter that to complete the delivery, delivering without confirmation code increases the possibility of no delivery claim by customer and you will be accountable in that scenario, resulting in money recovered from you.\nYou can close this screen to get delivery address.',
  helpNSupportInOrderMessageAfterSelect: 'You have selected the issue regarding the order as\n\n{value}\n\nSelected issue regarding the order will be raised to the store, they will go through it and will fix it accordingly. It may take sometime in resolving the issue by the store.\nAre you sure you want to raise the issue?',
  issueResolutionByMessage: 'Issue has been raised to store and they are going through it to resolve, issue resolution by {0}, {1}',
  shipmentDeliveryDisabled: 'Shipments Delivery Disabled',
  shipmentDeliveryDisabledMessage: 'enable shipments delivery and start delivering shipments',
  customerMode: 'Consumer Mode',
  driverMode: 'Driver Mode',
  switchAppDisplayMode: 'Switch App Display Mode',
  switchAppDisplayModeMessage: 'App can be displayed either in Consumer Mode or Driver Mode.\nIn Consumer Mode app is displayed in consumer centric stuff like making order, consuming offers, reviewing stores.\nIn Driver Mode app is displayed in driver centric stuff like shipments, deliveries.',
  customerModePrompt: 'Switch App display to Consumer Mode?',
  driverModePrompt: 'Switch App display to Driver Mode?',
  appSwitchToCustomerMode: 'App Switched to Customer Mode',
  appSwitchToDriverMode: 'App Switched to Driver Mode',
  howTo: 'How to?',
  confirmDeliver: 'Confirm Deliver',
  confirmDeliverWithoutCode: 'Confirm Deliver Without Code',
  otpWithValue: 'OTP: {value}',
  noStoresFound: 'No Stores Available',
  multipleStoresHere: 'Multiple Stores Here',
  nearbyStores: 'Nearby Stores',
  deliveryChargesEn: 'Delivery Charges',
  discountEn: 'Discount',
  deliveryChargesAr: 'تكاليف التوصيل',
  discountAr: 'الخصم',
  walletPaidAr: 'الدفع بواسطة المحفظة',
  walletPaidEn: 'Wallet Paid',
  businessOrderReceipt: 'Store Bill',
  commentsOptional: 'Comments (Optional)',
  addImages: 'Add Image(s)',
  redeemOfferCode: 'Redeem Offer Code',
  redeemOfferCodeMessage: 'Enter offer code to redeem, once redeemed offer will be available to consume',
  offerCodeRedeemed: 'Offer Code Redeemed Successfully',
  enterOfferCodeToRedeem: 'Enter Offer Code',
  redeemCode: 'Redeem Code',
  bankName: 'Bank Name',
  bankBranchName: 'Bank Branch Name',
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  iban: 'IBAN',
  searchNormalStores: 'Search items',
  searchItems: 'Search items',
  quickOrderTitle: 'Hungry',
  landmark: 'Landmark',
  selectCity: 'Select City',
  selectDistrict: 'Select District',
  soldOut: 'Sold Out',
  deliveryInTime: 'Delivery in {value}',
  pickupInTime: 'Pickup in {value}',
  cod: 'Cash On Delivery',
  bankTransfer: 'Bank Transfer',
  storeBankDetails: 'Store Bank Details',
  hintBankTransfer: 'Please transfer the amount to Store\'s Bank Details displayed above.\nPlace the order now and then upload bank transfer receipt later on in orders to make it easy for the store to track your bank transfer.',
  addBankTransferDetails: 'Add Bank Transfer Details',
  transactionId: 'Transaction Id',
  addBankTransferDetailsMessage: 'Please ensure that all details are clearly visible in image. Continue?',
  trackingIdCopied: 'Tracking Id Copied',
  returnPolicy: 'Return Policy',
  placeOrderCod: 'Place Cash On Delivery Order?',
  placeOrderBankTransfer: 'Place Bank Transfer Order?',
  vatDocument: 'VAT Document',
  totalRefund: 'Total Refund',
  reviewShareMessage: 'Have a look at the review on #Rab7, "{title}" for the business "{businessName}". {url}',
  activityReviewShareMessage: 'Have a look at the review on #Rab7, "{title}" for the campaign "{campaign}" in business "{businessName}". {url}',
  offerShareMessage: 'Checkout this amazing offer "{title}" on #Rab7. {url}',
  activityShareMessage: 'Checkout this campaign "{title}" on #Rab7. {url}',
  shareBusiness: 'Checkout amazing deals and offers in business "{title}" on #Rab7. {url}',
  shareBusinessProducts: 'Experience the amazing items by business "{title}" on #Rab7. {url}',
  shareBusinessProduct: 'Checkout item "{productTitle}" by business "{title}" on #Rab7. {url}',
  localityNameWithDistance: '{0}, ~ {1}',
  mandatoryHint: '* mandatory fields',
  prev: 'Prev',
  productNotFound: 'Item Not Found',
  itemInformation: 'Item Information',
  locationInformation: 'Location Information',
  category: 'Category',
  title: 'Title',
  setPrice: 'Set Price',
  postType: 'Post Type',
  informative: 'Informative',
  enquiry: 'Enquiry',
  addPost: 'Create Post',
  appName: 'Rab7',
  posts: 'Posts',
  shareApp: 'Share Rab7 App',
  userItems: 'User Stuff',
  usedItems: 'Stuff',
  usedItem: 'Stuff',
  myUsedItems: 'My Stuff',
  myPosts: 'My Posts',
  noUsedItems: 'No Stuff Found',
  noPosts: 'No Posts Found',
  userReviews: 'User Reviews',
  sellUsedItem: 'Sell Stuff',
  postUpdated: 'Post Updated',
  postAdded: 'Post Added',
  usedItemUpdated: 'Stuff Updated',
  usedItemAdded: 'Stuff Added',
  editPost: 'Edit Post',
  deleteUsedItem: 'Delete Stuff',
  deleteUsedItemPromptMessage: 'Are you sure you want to delete stuff?',
  deletePost: 'Delete Post',
  deletePostPromptMessage: 'Are you sure you want to delete post?',
  morePeopleLikedReview: '{value} people liked your post',
  likedReview: 'liked your post',
  sharePostMessage: 'Have a look at this post on #Rab7. {title}. {url}',
  shareUsedItemMessage: 'I\'ve found this item on #Rab7, what do you think? {title}. {url}',
  similarItems: 'Similar Stuff',
  sellerItems: 'Seller Stuff',
  barter: 'Barter',
  selectItemToBarter: 'Select Item to Barter',
  noUsedItemsToBarter: 'No Stuff available to Barter',
  hintAddCommentToCreateBarterDeal: '# add comment and submit to create barter deal',
  barterDeal: 'Barter Deal',
  appNameLanding: 'Rab7 Community',
  community: 'Community',
  jobs: 'Jobs',
  noJobsFound: 'No Jobs Found',
  noTalentsFound: 'No Resumes Available',
  jobSubWork: 'Opportunity',
  jobSubTalent: 'Talent',
  myJobPosts: 'My Job Posts',
  jobPosts: 'Job Posts',
  jobPost: 'Job Post',
  myTalentPosts: 'My Resume Posts',
  talentPosts: 'Resume Posts',
  talentPost: 'Resume Post',
  updateJob: 'Update Job Post',
  addJob: 'Add Job Post',
  updateResume: 'Update Resume Post',
  addResume: 'Add Resume Post',
  jobTitle: 'Job Title',
  jobDescription: 'Job Description',
  jobType: 'Job Type',
  additionalDetails: 'Additional Details',
  contactEmail: 'Contact Email',
  jobPosted: 'Job Posted',
  jobUpdated: 'Job Updated',
  fullTime: 'Full Time',
  partTime: 'Part Time',
  oneTime: 'One Time',
  basicInformation: 'Basic Information',
  locationNContactInformation: 'Location & Contact Information',
  contactInformation: 'Contact Information',
  resumeAdded: 'Resume Added',
  resumeUpdated: 'Resume Updated',
  resumeTitle: 'Resume Title',
  skills: 'Skills',
  experience: 'Experience',
  job: 'Job',
  resume: 'Resume',
  normalOrderTitle: 'Shopping',
  deleteJobPost: 'Delete Job Post',
  deleteJobPostPromptMessage: 'Are you sure you want to delete job post?',
  deleteResumePost: 'Delete Resume Post',
  deleteResumePostPromptMessage: 'Are you sure you want to delete resume post?',
  shareJobPostMessage: 'Checkout the job opportunity posted on #Rab7, {title}. {url}',
  shareResumePostMessage: 'Checkout the talent resume available on #Rab7, {title}. {url}',
  itemDetails: 'Item Details',
  item: 'Item',
  trips: 'Trips',
  tripSubTravellers: 'Travellers',
  tripSubShipments: 'Shipments',
  noTravellersAvailable: 'No Travellers Available',
  noShipmentsAvailable: 'No Shipments Available',
  subHeaderAddQuestion: 'Ask the community members and let them reply to you with their experience',
  subHeaderAddJob: 'Put the talents, skills, and experience that you need, and the community members will engage to provide that to you and make a fast deal with them',
  subHeaderAddResume: 'Put your talents, skills, and experience so that potential buyers can get to know you better and make a deal with you',
  subHeaderAddUserItem: 'Add your stuff and wait for community members to provide a good deal or bartering with their stuffs',
  subHeaderAddUserDemand: 'Share here with community members (individual and store owners) the item or services you are looking for, so they provide to you in a good deal',
  nameColon: 'Name:',
  joinDateColon: 'Joined On:',
  rab7CommunityMember: 'Rab7 Community Member',
  noQuestions: 'No Questions',
  questions: 'Questions',
  myQuestions: 'My Questions',
  userQuestions: 'User Questions',
  question: 'Question',
  editQuestion: 'Edit Question',
  addQuestion: 'Ask Question',
  shareQuestionPostMessage: 'Checkout the question posted on #Rab7, {title}. {url}',
  deleteQuestion: 'Delete Question',
  deleteQuestionPostPromptMessage: 'Are you sure you want to delete question?',
  deleteDemand: 'Delete Requirement',
  deleteDemandPostPromptMessage: 'Are you sure you want to delete requirement?',
  shareDemandPostMessage: 'Checkout the requirement posted on #Rab7, {title}. {url}',
  addDemand: 'Looking For',
  demand: 'Requirement',
  demands: 'Requirements',
  myDemands: 'My Requirements',
  userDemands: 'User Requirements',
  noDemands: 'No Requirements Found',
  demandUpdated: 'Requirement Updated',
  demandAdded: 'Requirement Added',
  demandDetails: 'Requirement Details',
  approximatePrice: 'Approximate Price',
  selectItemToFulfillDemand: 'Select Stuff to Suggest',
  noUsedItemsToFulfillDemand: 'No Stuff available for suggestion',
  similarDemands: 'Similar Requirements',
  sellerDemands: 'User\'s More Requirements',
  suggestStuff: 'Suggest Stuff',
  hintAddCommentToSuggestStuff: '# add comment and submit to suggest stuff',
  emailNMobileNumber: 'Email & Mobile Number',
  emailNMobileNumberSubtitle: 'Please write your email and mobile number',
  subHeaderAddTravel: 'Add your trip and wait for Rab7 community members to deal with you for sending their shipment, and earn from that',
  subHeaderAddShipment: 'Add your shipment that you want to send or bring and wait for Rab7 community members to carry in their trips or bring it to you',
  addTrip: 'Add Trip',
  updateTrip: 'Update Trip',
  tripAdded: 'Trip Added',
  tripUpdated: 'Trip Updated',
  addPackage: 'Add Package',
  updatePackage: 'Update Package',
  packageAdded: 'Package Added',
  packageUpdated: 'Package Updated',
  myTrips: 'My Trips',
  myPackages: 'My Packages',
  packages: 'Packages',
  package: 'Package',
  trip: 'Trip',
  noTripsFound: 'No Trips Found',
  noPackagesFound: 'No Packages Found',
  travelFromCity: 'Travelling From City',
  travelToCity: 'Travelling To City',
  travelFromDistrict: 'Travelling From District',
  travelToDistrict: 'Travelling To District',
  shipmentFromCity: 'Want to Ship Package From City',
  shipmentToCity: 'Want to Ship Package To City',
  shipmentFromDistrict: 'Want to Ship Package From District',
  shipmentToDistrict: 'Want to Ship Package To District',
  travelDate: 'Travel Date',
  shipmentDate: 'Want to Ship Package On Date',
  fromCity: 'From City',
  toCity: 'To City',
  fromDistrict: 'From District',
  toDistrict: 'To District',
  deleteTripPost: 'Delete Trip',
  deleteTripPostPromptMessage: 'Are you sure you want to delete trip?',
  deletePackagePost: 'Delete Package Shipment',
  deletePackagePostPromptMessage: 'Are you sure you want to delete package to ship?',
  shareTripPostMessage: 'Checkout the trip from "{from}" to "{to}" available to ship your packages on #Rab7, {url}',
  sharePackagePostMessage: 'Checkout the package available to be shipped from "{from}" to "{to}" on #Rab7, {url}',
  noticeTrip: '# Rab7 App is not responsible for the correctness or safety of the information added from trips or shipments, or about their owners or their content',
  titleJobs: 'Jobs',
  titleDemands: 'Demands',
  titleReviews: 'Reviews',
  titleStuff: 'Stuff',
  subHeaderAddPost: 'Ask or Share you experience with RAB7 Community members and let them reply to you with their opinion and experience',
  shareInformation: 'Share Information',
  askQuestion: 'Ask Question',
  titlePosts: 'Ask or Tell',
  permissionDeniedLocationToast: 'Location Permission Denied. Please grant location permission from device settings to show data based on your current location.',
  permissionDeniedLocationAlwaysToast: 'App Background Location Permission Denied. Please grant location permission even when app in background from device settings to process available shipments and share live location with customer.',
  consumedOfferAllLimit: 'Offer is fully consumed',
  explore: 'Explore',
  becomeAffiliate: 'Become an Affiliate',
  getAffiliateLink: 'Get Affiliate Link',
  affiliateShareMessage: 'Join Rab7 Community, earn and consume offers on your daily purchases. Explore {url}',
  addingMoneyToWallet: 'Recharging Wallet',
  addingMoneyToWalletMessage: 'Wallet recharge is in process, this may take some time, please do not press back or exit the application',
  walletRecharged: 'Wallet Recharged',
  rechargeIdWithValue: 'Wallet Recharge Id: {value}',
  walletRechargePaymentIsInProcessingMessage: 'Payment is in processing, you can check the status again or wait for some time. Please connect with support via chat if you need any assistance.\nTransaction Id: {0}',
  cancelWalletRecharge: 'Cancel Wallet Recharge',
  cancelWalletRechargeMessage: 'We have not received payment confirmation for this recharge.\nIf the payment for the recharge is successful it wont be cancelled and the amount will be credited to your wallet, otherwise it will be cancelled.\nAre you sure you want to cancel?',
  walletRechargePaymentFailedMessageGeneric: '{0}\nIf the amount gets deducted, payment from card will be reverted back in 3-15 days. Please connect with support via chat if you need any assistance.\nTransaction Id: {1}',
  walletRechargePaymentCancelledMessageGeneric: 'Your payment has been cancelled.\nIf the amount gets deducted, payment from card will be reverted back in 3-15 days. Please connect with support via chat if you need any assistance.\nTransaction Id: {0}',
  manageAdvertisement: 'Manage Advertisement',
  adStateExpired: 'Advertisement is Stopped since campaign is ended',
  adStatePausedNoWalletBalance: 'Advertisement is Paused due to insufficient balance',
  advertisementLowWalletBalance: 'You have insufficient advertisement balance to make the advertisement ongoing, please recharge to advertise uninterruptedly.',
  advertisementBudget: 'Advertisement Budget',
  advertisementDailyBudget: 'Advertisement Daily Budget',
  hintAdvertisementDailyBudget2: '# leave empty if you don\'t want to limit daily advertisement',
  advertisementRate: 'Advertisement Rate',
  dailyBudgetLessThanBudget: 'Daily Budget should be less than advertisement Budget',
  adStatePausedNoBalance: 'Advertisement is Paused due to insufficient available budget',
  adStatePaused: 'Advertisement is Paused',
  adStateOngoing: 'Advertisement is Ongoing',
  adStateStopped: 'Advertisement is Stopped',
  adDailyStatePausedNoBalance: 'Advertisement is Paused for today due to insufficient available daily budget',
  adStateStoppedCompletely: 'Advertisement Service has been stopped by Rab7',
  paidPreviews: 'Paid Previews',
  paidViews: 'Paid Views',
  adBudget: 'Advertisement Budget',
  adAvailableBudget: 'Available Budget',
  adDailyBudget: 'Advertisement Daily Budget',
  later: 'Later',
  promotePostBalance: 'Advertisement Balance',
  recharge: 'Recharge',
  rechargeAdWallet: 'Recharge Advertisement Wallet',
  rechargeAdWalletMessage: 'enter amount to add to advertisement wallet',
  enterAmountToAddToAdWalletWithMinMax: 'Enter amount you want to add to advertisement wallet between {value1} and {value2}',
  walletRechargeConvenienceFee: 'Wallet recharge convenience fee is {value}%',
  advertisePost: 'Advertise Post',
  postAdvertisementUpdated: 'Post Advertisement Information Updated',
  hintAdvertisementBudget: '# enter your budget for advertising this post, advertisement is automatically paused once budget is consumed',
  hintAdvertisementDailyBudget: '# enter your daily budget for advertising this post, advertisement is automatically paused once daily budget is consumed',
  gNotRegistered: 'Not Registered',
  gMale: 'Male',
  gFemale: 'Female',
  regions: 'Regions',
  cities: 'Cities',
  districts: 'Districts',
  gender: 'Gender',
  ageGroups: 'Age Groups',
  companies: 'Companies',
  customers: 'Customers',
  userInterests: 'Customer Interests',
  leaveEmptyToTargetAll: '# leave empty to target all',
  targetSelectedCustomersOnly: 'Target Selected Customers Only',
  nationalities: 'Nationalities',
  userTargeting: 'User Targeting',
  adTargeting: 'Advertisement Targeting',
  postAdTargeting: 'Post Ad Targeting',
  postAdTargetingUpdated: 'Post Advertisement Targeting Updated',
  promotedPosts: 'Promoted Posts',
  noPromotedPosts: 'No Promoted Posts',
  allowComments: 'Allow Comments',
  hintAllowComments: '# allow community members to comment in this post',
  postAdSuccess: 'Post Advertisement is created and we will shift you to targeting screen where you can select the niche that you want to target community members, leave the targeting empty if you want to target all the community members.',
  userRab7Numbers: 'User Rab7 Numbers',
  codFee: 'Cash on Delivery Fee',
  hintCodFee: '# fee applicable on POD',
  codFeeApplicable: 'Fee applicable on cash on delivery',
  totalValuePayCod: 'Total Amount to be paid at time of delivery',
  totalValuePayBankTransfer: 'Total Amount to be transferred in bank account',
  continueWithQuestionMark: 'Continue?',
  minPurchaseAmountMessage: 'minimum purchase amount in cart',
  challenges: 'Challenges',
  challenge: 'Challenge',
  fun: 'Fun',
  noChallenges: 'No Posts Available',
  noChallengePosts: 'No Posts Available',
  takeOnChallenge: 'Take On Challenge & Add Your Post',
  subHeaderAddPostInChallenge: 'Take on the challenge\n{value}\nadd the message you want to share and post',
  removeOffer: 'Remove Offer',
  removeOfferPromptMessage: 'Are you sure you want to remove this offer to be displayed to you?',
  removeActivity: 'Remove Campaign',
  removeActivityPromptMessage: 'Are you sure you want to remove this campaign to be displayed to you?',
  challengeShareMessage: 'Take on this challenge "{title}" on #Rab7. {url}',
  challengePostShareMessage: 'Have a look at the post on #Rab7, "{title}" for the challenge "{campaign}". {url}',
  challengeDetails: 'Challenge Details',
  challengeDurationMessage: 'Challenge is available',
  challengeValidityMessage: 'the challenge will expire after',
  challengeExpired: 'the challenge is ended',
  challengeValidityFutureMessage: 'the challenge will start in',
  birthdayCampaignOnly: 'On Birthday Only',
  processingVideo: 'Processing Video..',
  pickVideo: 'Pick Video',
  audio: 'Audio',
  video: 'Video',
  hintVideoDuration: '# maximum video length allowed is {value} seconds',
  videoDurationLessThanAllowed: 'Video length is less than allowed, should be more than {value} seconds',
  videoDurationMoreThanAllowed: 'Video length is more than allowed, should be less than {value} seconds',
  videoSizeMoreThanAllowed: 'Video size is more than allowed, should be less than {value} MB',
  cameraVideoPermissionDenied: 'Camera Permission Denied',
  permissionDeniedCameraVideoMessage: '\nYou have denied the permission to access camera.\n\nWe need access to camera to record video and upload in post.\n\nGrant camera permission from device settings to record video and post them. Open device settings?',
  permissionDeniedCameraVideo: 'You have denied the permission to access device camera',
  microphoneVideoPermissionDenied: 'Microphone Permission Denied',
  permissionDeniedMicrophoneVideoMessage: '\nYou have denied the permission to access microphone.\n\nWe need access to microphone to record audio along with video and upload in post.\n\nGrant microphone permission from device settings to record audio and post it. Open device settings?',
  permissionDeniedMicrophoneVideo: 'You have denied the permission to access microphone',
  permissionDeniedStorageVideoMessage: '\nYou have denied the permission to access device storage.\n\nWe need access to device storage to record video using camera and save them on device storage and upload in post.\n\nGrant storage permission from device settings to record video and post it. Open device settings?',
  storageVideoPermissionDenied: 'Storage Permission Denied',
  permissionDeniedStorageVideo: 'You have denied the permission to access device storage',
  videoPermissionDenied: 'Videos Permission Denied',
  permissionDeniedVideoMessage: '\nYou have denied the permission to access videos.\n\nWe need access to videos to select videos from gallery and upload in post.\n\nGrant photos permission from device settings to select video and post it. Open device settings?',
  permissionDeniedGalleryVideoMessage: '\nYou have denied the permission to access device storage.\n\nWe need access to device storage to select videos from device storage and upload in post.\n\nGrant storage permission from device settings to select video and post it. Open device settings?',
  permissionDeniedGalleyImageMessage: '\nYou have denied the permission to access device storage.\n\nWe need access to device storage to select images from device storage and upload in post.\n\nGrant storage permission from device settings to select image and post it. Open device settings?',
  permissionDeniedVideo: 'You have denied the permission to access videos',
  discardVideo: 'Discard Video',
  discardVideoPromptMessage: 'Are you sure you want to discard the video?',
  cameraMountError: 'Unable to operate camera',
  videoRecordInvalid: 'Unable to record video correctly, please try again',
  videoRecordingInProgress: 'Video Recording in Progress, cannot exit',
  uploadingPostMediaMessage: 'Media uploading is in progress and will continue in background, upload progress and status can be tracked in home screen.',
  postMediaUploadingWithValue: 'Uploading Media #{value}',
  postMediaUploadingMessage: 'Media uploading in progress',
  postMediaUploadedWithValue: 'Media Uploaded #{value}',
  postMediaUploadedMessage: 'Media has been uploaded and your content is posted successfully.',
  postMediaUploadError: 'Upload Media Failed',
  postMediaUploadErrorWithValue: 'Media Upload #{value} Failed',
  postMediaUploadErrorMessage: 'An error occurred while uploading video, please upload it again to post the content.',
  postMediaUploadCancelWithValue: 'Media Upload #{value} Cancelled',
  postMediaUploadCancelMessage: 'Media upload was cancelled, please upload it again to post the content.',
  postMediaUploadErrorMessageWithValue: 'An error occurred while uploading video, please upload it again to post the content.\n{value}',
  postMediaUploadLimitReached: 'Uploading Media limit reached, i.e. {value}',
  uploadingInProgress: 'Uploading in Progress...',
  uploadStateError: 'Failed',
  uploadStateInProgressWithValue: 'In Progress ({value}%)',
  uploadStateComplete: 'Completed',
  retryUploadMedia: 'Retry Upload',
  retryUploadMediaMessage: 'Media upload failed due to reason:\n{value}\n\nRetry media upload or remove it from uploading list',
  walletBalanceWithValue: 'Wallet Balance {value}',
  expiredS: 'expired',
  walletBalanceExpired: 'wallet balance expired',
  walletBalanceExpiringIn: 'wallet balance expiring in {value}',
  expiringIn: 'expiring in {value}',
  hintExtendExpiring: '# loyalty points gets expired at displayed expiry time for individual businesses, please utilize it or make new purchase within expiry time to extend it by {value} days, for maximum of {value1} days',
  expiryMonthShortWithSpace: {
    'one': '{count} month ',
    'other': '{count} months '
  },
  expiryDayShortWithSpace: {
    'one': '{count} day ',
    'other': '{count} days '
  },
  expiryHourShortWithSpace: {
    'one': '{count} hour ',
    'other': '{count} hours '
  },
  expiryMinuteShortWithSpace: {
    'one': '{count} minute ',
    'other': '{count} minutes '
  },
  purchaseReviewed: 'Purchase Reviewed',
  guess: 'Guess',
  guessSubmitted: 'Your prediction has been submitted',
  yourGuess: 'Your Prediction',
  orderPaymentCardMessage: 'Payable through card.',
  payOnDelivery: 'Pay on Delivery',
  payAtStore: 'Pay at Store',
  payViaCard: 'Pay through Card',
  payOnDeliveryTotalValue: 'Total amount need to be paid on delivery',
  payAtStoreTotalValue: 'Total amount need to be paid at store',
  payViaTotalValue: 'Total amount need to be paid through card',
  balanceTransferTotalValue: 'Total amount need to be transferred to bank',
  selectCard: 'Select Card',
  placePickupOrderMessage: 'Order to be Picked from selected store.',
  placeDineInOrderMessage: 'Order to be Dined in selected store.',
  placeOrderMessage: 'Order to be delivered at the selected address. It will be delivered by store\'s delivery service (store drivers).',
  orderPaymentPODDeliveryMessage: 'Payable on delivery.',
  orderPaymentPODMessage: 'Payable at store.',
  orderPlaceCancellationMessage: '* Order once confirmed by store cannot be cancelled. Consumed offer and redeemed wallet balance wont be reverted back.',
  discounts: 'Discounts',
  noDiscounts: 'No Discount Found',
  moreItems: 'More Items',
  fromGivenValuesOnly: 'from given specific values only',
  orderPaymentBankTransferMessage: 'Payable through bank transfer. Transfer the amount once order gets confirmed by the store.',
  bankTransferDetailsUpdated: 'Bank Transfer Details Updated',
  commercialRecordDocument: 'Commercial Record Document',
  commercialRecordNumber: 'Commercial Record Document Number',
  maroofLink: 'Maroof E-Platform Web Link',
  notes: 'Notes',
  selectDistricts: 'Select Districts',
  becomeDriver: 'Become Driver',
  becomeDriverInfo: 'Become Driver Info',
  subHeaderBecomeDriver: 'Update required information and it will be shared among Rab7 Business Community.',
  becomeDriverInfoUpdated: 'Become Driver Information Updated',
  becomeDriverInfoUpdatedMessage: 'You have updated information, this is shared among Rab7 Business Community.',
  addInformation: 'Add Information',
  becomeDriverMessageTitle: 'Become Driver Information Required',
  becomeDriverMessageSubTitle: 'Some information is required to be filled in order to become driver. The infomration will be shared among Rab7 Business Community.',
  becomeDriverInformationDeleted: 'Become Driver Information Deleted',
  deleteBecomeDriverInformation: 'Delete Driver Information',
  areYouSureContinue: 'Are you sure to continue?',
  businessRegistrationDocument: 'Business Registration Document',
  locationNotAvailable: 'Location Not Available',
  locationNotAvailableMessage: 'Unable to fetch your current location, please allow us to fetch your current location to see relevant stores around you',
  locationNotAvailableInCartMessage: 'Unable to fetch your current location, please allow us to fetch your current location to process the order',
  mockedLocationDiscardInCartMessage: 'Driver location is not available or seems to be mocked. Please disable any mock location provider and try again with correct device location.',
  permissionDeniedLocationMessage: '\nYou have denied the permission to access device location.\n\n\n\nTo process the order and to experience nearby stores and offers you must keep the location available by following steps: \n\n• Open Device Settings, select permissions\n\n• Select Location, select allow while using the app\n\n• Turn on location service for device\n\n\n\nOpen Device Settings?',
  permissionDeniedLocationIosMessage: '\nYou have denied the permission to access device location.\n\n\nTo process the order and to experience nearby stores and offers you must keep the location available by following steps: \n\n• Open Device Settings, select location\n\n• Select while using the app\n\n• Turn on location service for device\n\n\n\nOpen Device Settings?',
  shopPermit: 'Shop Permit',
  shopPermitDocument: 'Shop Permit Document',
  shopPermitDocumentNumber: 'Shop Permit Document Number',
  spin: 'Spin',
  spinsLeftCounter: '{value1}/{value2} spins left',
  addCommentWithDots: 'Add Comment ...',
  discardChanges: 'Discard Changes?',
  getSlotForJackpot: 'Get identical images in selected row and win rewards',
  youWonJackpotPointsAndOffer: 'Congratulations! You Won {value1} Points and Offer',
  viewOffer: 'View Offer',
  spinCountComplete: 'You have spin all your turns, better luck next time',
  jackpotValidityFutureMessage: 'It will be available on\n{value1}',
  confirmAnswer: 'Confirm Answer?',
  titleIReviews: 'Reports',
  noIReviews: 'No Reports',
  addIReview: 'Add Report',
  editIReview: 'Edit Report',
  subHeaderAddIReview: 'Share you experience with the store',
  selectBusiness: 'Select Business',
  actionUrl: 'Action Link Url',
  actionUrlText: 'Action Link Title',
  likeWithValue: '{value} Like',
  likesWithValue: '{value} Likes',
  commentWithValue: '{value} Comment',
  commentsWithValue: '{value} Comments',
  country: 'Country',
  countryWithColon: 'Country:',
  totalAmountWillBeDeductedFromCard: 'Total amount will be deducted is ',
  rechargeAmountWithValue: 'Wallet recharged with ',
  amountDeductedInSAR: 'Payment will be charged in SAR as per currency exchange rate',
  amountDeductedInSARAndChargeableAmount: 'Payment will be charged in SAR as per currency exchange rate, chargeable amount will be SAR ',
  influencerLinkClickedSuccessful: 'Thanks for the action',
  spendsPerPoint: 'Spends Per Point',
  spendsPerPointHint: 'amount spent to earn one point',
  maximumEarnHint: 'maximum points that can be earned in a day',
  totalMoney: 'Total Points',
  hintWalletPointsUsableInOtherBusinesses: '# wallet balance also consumable in other businesses',
  playLeftCounter: '{value1} play left',
  playsLeftCounter: '{value1} plays left',
  emptyFlippedGameOver: 'Cross flipped, Game Over!',
  congratulationsYouWon: 'Congratulations, You Won!',
  youWon: 'You Won',
  playCountComplete: 'You have played all your turns, better luck next time',
  remainingOffersMessage: 'count of offers available to all customer',
  remainingOffersTodayMessage: 'count of offers available to all customer for today only',
  availabilityAllToday: 'Today\'s Availability for All',
  noPlaces: 'No Places Available',
  places: 'Places',
  place: 'Place',
  sharePlaceMessage: 'Have a look at the place, {title}. {url}',
  socialMediaAccounts: 'Social Media Accounts',
  event: 'Event',
  events: 'Events',
  noEvents: 'No Events',
  eventShareMessage: 'Checkout the event "{title}" on #Rab7. {url}',
  commentWithCountInBracket: 'Comments ({value})',
  youHaveParticipated: 'You have Participated',
  readMore: 'Read More',
  showLess: 'Show Less',
  description: 'Description',
  organiser: 'Organiser',
  startingInWithValue: 'Starting in {value}',
  ongoing: 'Ongoing',
  completed: 'Completed',
  participatingEvent: 'Participating ?',
  participatingEventMessage: 'Your name, mobile number will be shared with the business on participation. You won\'t be allowed to cancel the participation.\n\nContinue?',
  earnedPointsWithValue: 'You have earned {value1} Points',
  earnedOffer: 'You have earned the offer',
  earnedPointsAndOffer: 'You have earned {value1} Points and Offer',
  youHaveParticipatedViewOffer: 'You have participated, View Offer',
  completelyReserved: 'Completely Reserved',
  commentedInEvent: 'commented in event',
  repliedInEvent: 'replied in event',
  campaignOver: 'Campaign is Over',
  wonTheCampaign: 'You won the game',
  backAgainToCollectMore: 'Come back again after {value} to collect more.',
  becomeDriverMessage: 'To become a driver you need to click on below button and select the stores from whom you can provide delivery.\n You will be contacted by customer once they place order to deliver their packages from the store.',
  becomeDriverConfirmation: 'Become Driver Confirmation',
  deliverPackages: 'Deliver Packages',
  disableDelivery: 'Disable Delivery',
  disableDeliveryMessage: 'Disabling delivery will stop showing you in customer app, and customer wont be able to contact you to deliver their packages.\n\nContinue?',
  deliveryDisabled: 'Delivery Disabled',
  enableDelivery: 'Enable Delivery',
  deliveryEnabled: 'Delivery Enabled',
  enableDeliveryMessage: 'Enabling delivery will show you in customer app, and customer will contact you to deliver their packages.\n\nContinue?',
  liteDeliveryEnabledSelectStoresMessage: 'Delivery is enabled, select the stores from where you can provide delivery.',
  liteDeliveryEnabledMessage: 'Delivery is enabled, you will be contacted by customers for the delivery from approved stores.',
  liteDeliveryDisabledMessage: 'Delivery is disable, enable it to be contacted by customers for the delivery.',
  selectStores: 'Select Stores',
  selectMoreStores: 'Select More Stores',
  manageStores: 'View & Manage Stores',
  storesSelectedHintMessage: 'You have selected {value} stores from where you will deliver packages.',
  storesNotSelectedHintMessage: 'You have not selected any stores. Select the stores from where you can provide delivery.',
  noStoresAvailable: 'No Stores Available',
  hintSelectCityAndSelectBranches: '# select city and then from below list select the stores from where you can provide delivery',
  selectedStoreWithValue: '{value} store selected',
  selectedStoresWithValue: '{value} stores selected',
  selectStoresForDelivery: 'Select Stores for Delivery',
  selectStoresForDeliveryMessage: 'Your request will be sent to store\'s owners for approval, once they approve, you will be displayed to customers while placing orders and they will contact you for delivery.\n\nContinue?',
  approvalPendingML: 'Approval\nPending',
  approvalRejectedML: 'Approval\nRejected',
  disableDeliveryInStoreQ: 'Disable Delivery in Store?',
  enableDeliveryInStoreQ: 'Enable Delivery in Store?',
  deleteDeliveryInStoreQ: 'Delete Delivery Request in Store?',
  deliveryByLiteDriversMessage: 'Delivery of the order is provided by business drivers, you need to contact and deal with them to get the package delivered to you.',
  viewAvailableDrivers: 'View Available Drivers',
  availableDrivers: 'Available Drivers',
  informationRegardingDelivery: 'Information Regarding Delivery',
  continueToPayment: 'Continue to Payment',
  noAvailableDrivers: 'No Available Drivers',
  shareOrderInformation: 'Share Order Information',
  deliveryByLiteDriversMessage2: 'Ensure that you have to contact and deal with drivers to get the package delivered to you. You can deal with them before or after placing the order.',
  shareOrderInformationMessage: 'Are you sure you want to share order information with the driver?',
  orderInformationShared: 'Order Information Shared',
  orderShareInfoWithOrderNo: 'shared information of Order #{value}',
  deliveryByLiteDriversMessage3: 'Connect and deal with driver to get the order delivered to you.',
  requestForDeliveryInStoresStateApproved: 'Approved your request to provide delivery in their stores',
  requestForDeliveryInStoresStateRejected: 'Rejected your request to provide delivery in their stores',
  deliveryOnly: 'Delivery Only',
  pickupOnly: 'Store Pickup Only',
  cancelSavingCard: 'Cancel Save Card',
  cancelSavingCardMessage: 'Cancel Save Card Message',
  postsInChallengeWithValue: 'Posts in Challenge ({value})',
  campaignWillStartIn: 'campaign will start in',
  campaignIsEnded: 'Campaign is Ended',
  playNWin: 'Play & Win',
  youWonJackpotPoints: 'You Won {value} Store Loyalty Points',
  youWonJackpotOffer: 'You Won Offer',
  youWonJackpotOffers: 'You Won Offers',
  youWonEJackpotOffer: '& Won Offer',
  youWonEJackpotOffers: '& Won Offers',
  youWonJackpotGame: 'You got access Fun Activity',
  youWonJackpotGames: 'You got access Fun Activities',
  youWonEJackpotGame: '& got access to Fun Activity',
  youWonEJackpotGames: '& got access to Fun Activities',
  playAgain: 'Play Again',
  earnedStorePointsWithValue: 'You have earned {value} Store Loyalty Points',
  earnedRab7PointsWithValue: 'You have earned {value} Rab7 Loyalty Points',
  earnedERab7PointsWithValue: '& have earned {value} Rab7 Loyalty Points',
  earnedOffer1: 'You have earned Offer',
  earnedOffers: 'You have earned Offers',
  earnedEOffer1: '& have earned Offer',
  earnedEOffers: '& have earned Offers',
  earnedGame: 'You have earned access to Fun Activity',
  earnedGames: 'You have earned access to Fun Activities',
  earnedEGame: '& have earned access to Fun Activity',
  earnedEGames: '& have earned access to Fun Activities',
  earnedPointsTitle: 'Great!',
  gameEarnMessage: 'You have got below perks for playing the game. Keep exploring fun activities and win rewards. Good Luck!',
  gameWinLimitComplete: 'That\'s a wrap! All winners have been discovered. Ready for a new challenge?',
  gameRecurAgain: 'This game will restart again on\n{value}',
  fewMinutes: 'few minutes',
  gameShareMessage: 'Play & win the game "{title}" on #Rab7. {url}',
  guessImageMessage: 'Guess the image and enter in the field below, guess it correctly to be eligible to win the game',
  enterGuessHere: 'enter your guess here',
  guessRecorded: 'Your guess is recorded',
  guessNotMatched: 'Your guess was not matched to the defined possible values',
  getSlotForRoulette: 'Touch and toggle between images on all three cards, select your combination of images',
  getSlotForRoulette1: 'spin to get same images as you have selected to win',
  deleteAccount: 'Delete Account',
  deleteAccountMessage: 'Deleting you account will remove your account, your profile, your posts, your loyalty points in Rab7. Your account cannot be retrieved back after deletion.\n\nIn next step a verification code is sent to your registered mobile number, you need to enter the code to process your account deletion.\n\nContinue?',
  proceed: 'Proceed',
  accountDeleted: 'Your account has been deleted',
  enterVerificationCode: 'Enter Verification Code',
  enterVerificationCodeMessage1: 'enter verification code sent to your registered mobile number to proceed to account deletion',
  activitySocialContentShareMessage: 'Post your content to fulfill business need and win rewards for "{title}" on #Rab7. {url}',
  ended: 'Ended',
  contentWriting: 'Content Writing',
  postContent: 'Post Your Content',
  subHeaderAddContent: '{value}\nadd text or media you want to post',
  youHavePosted: 'You have posted {value} posts',
  youHavePostedS: 'You have posted {value} post',
  socialContentPostShareMessage: 'Have a look at the post on #Rab7, "{title}" for the contest "{campaign}". {url}',
  yourGuessIncorrect: 'Your guess was not right',
  allPostsWithValue: 'All Posts ({value})',
  myPostsWithValue: 'My Posts ({value})',
  dataNotLatest: 'Data displayed is not latest, updated to latest.',
  viewWinnerPost: 'View Winner Post',
  viewPost: 'View Post',
  activityQuestionShareMessage: 'Answer the survey "{title}" on #Rab7. {url}',
  activityQuestionnaireShareMessage: 'Test your knowledge and win "{title}" on #Rab7. {url}',
  activityOpinionShareMessage: 'Share you opinion for the campaign "{title}" on #Rab7. {url}',
  activityReviewsWithValue: {
    'one': '{count} review',
    'other': '{count} reviews'
  },
  consumableInAllBranches: 'Consumable in All Branches',
  consumableInSelectedBranches: 'Consumable in Selected Branches',
  viewBranches: 'View Branches',
  view: 'View',
  enterYourFeedback: 'enter your feedback',
  participateRewardsMessage: 'You have participated.\n\nYou have been rewarded with below perks for participation.',
  participateOfferRewardsMessage: 'You have participated and get access to offer.\n\nAlso you have been rewarded with below perks for participation.',
  participateOfferMessage: 'You have participated and get access to offer',
  genericEarnRewardsMessage: 'You have earned your rewards with below perks.',
  moreRab7PointsNeeded: 'More Rab7 Loyalty Points Needed',
  moreStorePointsNeeded: 'More Store Loyalty Points Needed',
  pointsConditionPassed: 'Points Condition Passed',
  pointsConditionsNRewards: 'Points Conditions & Rewards on Action',
  rab7PointsRequiredToAction: 'Rab7 Loyalty Points Required to Action',
  rab7PointsChargedOnAction: 'Rab7 Loyalty Points Deducted on Action',
  rab7PointsDeductedOnAction: 'Rab7 Loyalty Points Rewarded on action',
  storePointsRequiredToAction: 'Store Loyalty Points Required to Action',
  storePointsChargedOnAction: 'Store Loyalty Points Deducted on Action',
  storePointsDeductedOnAction: 'Store Loyalty Points Rewarded on action',
  offerRewardedOnAction: 'Offer Rewarded on Action',
  rab7PointsBalance: 'Rab7 Loyalty Points Wallet Balance',
  storePointsBalance: 'Store Loyalty Points Wallet Balance',
  totalOffersConsumed: 'Offers consumed by all customers',
  availableOffersToConsume: 'Offers available for all customers',
  availableOffersTodayToConsume: 'Offers available for today for all customers',
  availableOffersToMeToConsume: 'Offers available for me',
  consumableDay: 'Available Day',
  consumableTime: 'Available Time',
  collectMorePoints: 'Collect More Points',
  rewards: 'Rewards',
  stars: 'Stars',
  image: 'Image',
  clickToShowJackpotRewards: 'click here to view rewards corresponding to each image',
  noFunAvailable: 'No Fun Activities Available',
  allEarningsClaimed: 'All Earnings Claimed',
  pendingEarnings: 'Claim Pending Earnings',
  helpCollectStarsTitle: 'Photo Value',
  challengePhotoPlaceholder: 'Photo',
  doFastTimeLeftWithValue: 'hurry up, {value} left',
  guessImageShareMessage: 'Guess here "{title}" on #Rab7. {url}',
  guessShareMessage: 'Follow you instinct and predict the outcome in "{title}" on #Rab7. {url}',
  sponsored: 'Sponsored',
  pendingEarnClaimed: 'You have claimed all the pending earnings',
  pendingEarnLeft: '# you have pending earnings, please claim them before moving to wallets',
  generateVoucherCodePrompt: 'Generate Voucher Code ?',
  generateVoucherCodeAgainMessagePrompt: 'Ensure that you have redeemed the generated voucher or copy it for later use, generating another voucher will hide this voucher from display to show new generated voucher.\n\nContinue?',
  generateNewVoucherCode: 'Generate New Voucher Code',
  voucherCodeGenerated: 'Voucher Code Generated',
  code: 'Code',
  pin: 'Pin',
  generatedVoucherCode: 'Your Voucher',
  voucherEarnMessage: 'You have got below perks for generating the voucher.',
  voucherCodeCopied: 'Voucher Code Copied',
  voucherPinCopied: 'Voucher Pin Copied',
  enterHere: 'enter here',
  clickToShowCollectStarPointsMessage: 'flip cards having below images to collect corresponding {value1}',
  clickToShowCollectStarPoints: 'click here to view {value1} corresponding to each image',
  flipToCollectStars: 'Touch and flip {value} card(s) to collect {value1}.',
  activityCollectStarsShareMessage: 'Collect maximum {value1} and win this campaign "{title}" on #Rab7. {url}',
  youAreInTopCollectors: 'Congratulations! You are in top {value1} collectors.',
  yourStarsCollectionWithValue: 'Your Collected {value1}: {value}',
  topStarsCollectors: 'Top {value1} Collectors',
  starWithValue: '{value} {value1}',
  starsWithValue: '{value} {value1}',
  collectMaxStarsToWin: 'Collect maximum {value1} to win',
  starsCollected: '{value1} Collected',
  starsCollectedMessage: 'You have collected {value} {value1}.',
  starCollectedMessage: 'You have collected {value} {value1}.',
  cLikeWithValue: '{value} {value1}',
  cLikesWithValue: '{value} {value1}',
  shareNLikeActivityMessage: 'Open the link and give {value1} to help me won this campaign, "{title}" on #Rab7. {url}',
  rewardToLikeOthers: 'as a reward to give {value1}',
  shareNGetLike: 'Share & Get {value1}',
  getMaxLikedToWin: 'Share & Ask to get maximum {value1} to win rewards',
  likeUserMessage: 'You are allowed to give {value1} to only one user in the campaign, and not allowed to revert or modify it.\n\nContinue?',
  likeUser: 'Give {value1}',
  likeUserPrompt: 'Give {value1} ?',
  likeUserCampaignMessage: 'Give user {value1} and help user to won the campaign',
  topLikes: 'Top Gainers',
  youAreInTopLiked: 'Congratulations! You are in top {value1} gainers.',
  yourLikesWithValue: 'Your {value1}: {value}',
  youHaveLiked: 'You give {value1} to',
  yourActionSubmitted: 'Your action is submitted successfully',
  prediction: 'Prediction',
  chooseGuess: 'choose your guess',
  recordGuess: 'Record Guess ?',
  moreFunActivities: 'More Fun Activities',
  clickToShowJackpotRewardsMessage: 'get identical images from below images to win corresponding rewards',
  gameSpinDiscoverMessage: 'Spin and get correct images to reveal parts of hidden image, reveal the hidden image completely in given spins, and win rewards',
  showGameSpinDiscoverReward: 'click here to show part reveal count for each image',
  showGameSpinDiscoverRewardMessage: 'image and their corresponding parts count that will be unfolded',
  photoPartsCount: 'Image Parts Count to be Revealed',
  partsCount: 'Parts Reveal Count',
  revealThePhoto: 'Reveal the Image',
  revealedCounter: '{value1}/{value2} parts revealed',
  helpGreedySpinSubTitle: 'images and their corresponding {value1}, get all identical images in a row and get {value2} times',
  helpGreedySpinTitle: 'Photo Value',
  values: 'Values',
  showGameValues: 'click here to show {value} for each image',
  winnersWillBeDeclaredAt: 'Winners will be declared on\n{value}',
  notWinningMessage: 'You fell short to win, better luck next time.',
  restartAgainOn: 'Restart again on {value}',
  multiplierWithValue: '{value}x',
  gameGreedySpinMessage: 'Spin and collect {value} corresponding to image(s), get all identical images in a row and collect {value1} times',
  collectedValues: 'Your Collected {value}: {value1}',
  flipsLeftCounter: '{value1}/{value2}',
  gameOver: 'Game Over!',
  userEligibleToWin: 'You are eligible to win the rewards.',
  resetCollectedValuesPrompt: 'Play again will reset the existing score.\n\nContinue?',
  playAgainQ: 'Play Again ?',
  winningEligibleMessage: 'Congratulations! You are eligible to win rewards.',
  guide: 'Guide',
  spinsLeft: 'left',
  moreDetails: 'More Details',
  rewardsViewTitle: 'Win to get Rewards',
  rewardsSlotExtra: 'get below given identical images to earn corresponding rewards',
  participatePointsConditions: 'Participate Points Conditions',
  participateRewards: 'Participate to get Rewards',
  storePointsWithValue: '{value} Store Loyalty Points',
  rab7PointsWithValue: '{value} Rab7 Loyalty Points',
  andRab7PointsWithValue: '& {value} Rab7 Loyalty Points',
  offers1: 'Offers',
  andOffers1: '& Offers',
  andOffer1: '& Offer',
  funActivities1: 'Fun Activities',
  funActivity1: 'Fun Activity',
  andFunActivities1: '& Fun Activities',
  andFunActivity1: '& Fun Activity',
  gameFindHidden: 'Find Hidden',
  gameMatchPair: 'Match Pair',
  gameFlipCard: 'Flip Card',
  collectValue: 'Collect {value}',
  collectToWin: 'Get exciting rewards by collecting {value} till this tournament ends.',
  collectMaxToWin: 'Get exciting rewards by collecting as more {value} as you can till this tournament ends.',
  collectRequiredValueToWin: 'Get exciting rewards by collecting {value1} or more {value} till this tournament ends.',
  shareNGet: 'Share & Get',
  yourCollectionValue: 'Your Collected {value}',
  topCollectors: 'Top Collectors',
  topCollectorsMulti: 'Top\nCollectors',
  youSentToUserValue: 'You sent to\n{value}',
  getLikeRequestValue: 'Get {value} Request',
  giveOne: 'Give One',
  likeToUserValue: 'to {value}',
  youGave: 'You Gave',
  youAlreadyGave: 'You Already Gave',
  greatJob: 'Great Job!',
  great: 'Great!',
  noSpinsLeft: 'No Spins Left',
  gameRouletteMessage: 'Select your combo images and spin, and get all identical images in selected row and win rewards',
  selectCombo: 'Select Your Combo',
  gamePredictionMessage: 'Follow your instinct and predict the outcome to win.',
  reportPost: 'Report Post',
  information: 'Information',
  thisIsWinningPost: 'This is Winning Post',
  showingWinningPosts: 'Showing Winning Posts',
  showingAllPosts: 'Showing All Posts',
  postingIn: 'Posting in',
  addVideo: 'Add Video',
  titleHere: 'Title',
  showAllPosts: 'Show All Posts',
  showWinningPosts: 'Show Winning Posts',
  walletInfo: 'Wallet Information',
  reviewing: 'Reviewing',
  unableToPost: 'Unable to Post',
  clickHere: 'Click Here',
  gameCollectStar: 'Flip & Collect',
  referralWonFunActivities: 'Your referral won,\n& you got access to Fun Activities',
  referralWonEFunActivities: '& got access to Fun Activities',
  referralWonOffers: 'Your referral won,\n& you got Offers',
  referralWonEOffers: '& got Offers',
  swipeUp: 'Swipe Up',
  youWonReferralPoints: 'Your referral won\n& you got {value} Store Loyalty Points',
  referralWonEFunActivity: '& got Fun Activity',
  referralWonFunActivity: 'Your referral won\n& you got access to Fun Activity',
  referralWonEOffer: '& got Offer',
  referralWonOffer: 'Your referral won\n& you got Offer',
  referralRewardsWithValue: 'Refer to others & get Rewards on their first {value} win',
  referralRewards1WithValue: 'Refer to others & get Rewards on their first win',
  referralWonTheCampaign: 'You referral won the campaign & you got rewards',
  noChallengesAvailable: 'No Challenges Available',
  trends: 'Trends',
  myProfile: 'My Profile',
  valuePosts: '{value} Posts',
  valuePost: '{value} Post',
  valueViews: '{value} Views',
  valueView: '{value} View',
  ctaPlay: 'Jump In',
  ctaView: 'View Now',
  ctaAnswer: 'Answer Now',
  ctaReview: 'Review Now',
  ctaPredict: 'Predict Now',
  ctaGuess: 'Guess Now',
  ctaParticipate: 'Participate Now',
  ctaConsume: 'Consume Now',
  ctaPlayML: 'Play<br>Now',
  ctaViewML: 'View<br>Now',
  ctaAnswerML: 'Answer<br>Now',
  ctaReviewML: 'Review<br>Now',
  ctaPredictML: 'Predict<br>Now',
  ctaGuessML: 'Guess<br>Now',
  ctaParticipateML: 'Participate<br>Now',
  ctaConsumeML: 'Consume<br>Now',
  promptGenerateOfferCode: 'Generate Offer Code',
  promptGenerateOfferCodeMessage: 'Make sure to redeem the offer code at store before the offer ended or offer limit exhausted.',
  offerCodeGenerated: 'Offer Code Generated',
  generatedOfferCode: 'Offer Code QR',
  shareGenerateOfferCodeMessage: 'Share this redeem offer QR code at store to scan & redeem it, make sure to redeem it before the offer gets ended or offer limit gets exhausted.',
  gameFindImageMessage: 'Touch and flip {value} cards to find below given hidden image, and win rewards',
  gameFindImageMessage1: 'Touch and flip a card to find below given hidden image, and win rewards',
  getImageFlippedForJackpot: 'Touch and flip {value} cards having below given image without flipping card having cross, and win the game.',
  multipleFunActivitiesHere: 'Multiple Fun Activities Here',
  funActivityNotSupported: 'Fun Activity Not Supported',
  funActivityNotSupportedMessage: 'This type of fun activity is not supported by this version of app, please update to latest app.',
  updateNow: 'Update Now',
  gameTicTacToe: 'Game Tic Tac Toe',
  gameTicTacToeMessage: 'Play Tic Tac Toe [XO] game against bot and win more rounds than bot to win the game.',
  yourTicTacToeBelowImage: 'Avatar for the game as-',
  youLost: 'Game Over! Better luck next time',
  gameDraw: 'Game Draw',
  roundInfo: 'Round {value1}/{value2}',
  you: 'You',
  bot: 'Bot',
  gameQuestionGuide: 'Answer the survey and win.',
  gameQuestionnaireMessage: 'Answer the series of questions correctly, and win the questionnaire.',
  totalQuestions: 'Total Questions',
  answerToWin: 'Answer to Win',
  totalTime: 'Total Time',
  exitQuestionnaire: 'Exit Questionnaire',
  exitQuestionnaireMessage: 'It is recommended to complete it now. Anyhow you can resume it afterwards.\n\nExit Questionnaire ?',
  exit: 'Exit',
  questionValue: 'Que:  {value1} of {value2}',
  timeValue: 'Time:  {value1}',
  complete: 'Complete',
  completeQuestionnaire: 'Complete Questionnaire ?',
  completeQuestionnaireMessage: 'You are about to complete questionnaire. Ensure that you have selected answers properly. You are not allowed to make changes to answers once submitted.\n\n Continue ? ',
  proceedAnyway: 'Proceed Anyway',
  changeAnswer: 'Change Answer',
  correctAnswers: 'Your Correct Answers',
  correctAnswersValue: '{value1} of {value2} ({value3}%)',
  startAgain: 'Start Again',
  takeARest: 'Take a Rest',
  takeARestMessage: 'Take a rest & click and enjoy below given fun activity',
  continueToQuestions: 'Continue to Questions',
  questionnaireTimeOver: 'Time Over',
  gameEnded: 'Game Ended',
  gameEndedNScore: 'Game Ended \n Your Score:  {value} ',
  gameGamePuzzle: 'Solve the puzzle with tapping on image pieces to move to adjacent empty space. Arrange all image pieces correctly as per displayed complete image.',
  gameGamePuzzleTapMultiple: 'Solve the puzzle with tapping on image pieces and select correct image piece for the place. Arrange all image pieces correctly as per displayed complete image.',
  guideGameMatchThree: 'Play the game with swiping and moving the cards left, right, up, down. Arrange pair of three or more cards in horizontally or vertically to crush them and score.',
  guidePartInTime: ' Complete the game in given time.',
  guidePartInMoves: ' Complete the game in provided moves count.',
  guidePartInMovesNTime: ' Complete the game in provided moves count and in given time.',
  matchThreeGuidePartBelowGivenWeightage: 'Below is given the images and their weightage-',
  puzzleGuidePartCompleteGameToWin: 'End the game with putting all image pieces correctly to be eligible to win rewards.',
  brandDice: 'Brand',
  opponentWonMessage: 'Your opponent won, better luck next time',
  guideGameBrandRace: 'Spin and get your {value} avatar to move forward in the race, stay away from reverse image which will pull you back in the race. Finish the race before your opponent in provided spin count to be eligible to win rewards.',
  viewYourAvatar: 'Below given is your {value} avatar.',
  moveCount: 'Move Count',
  avatar: 'Avatar',
  startGame: 'Start',
  guideGameWhacAMole: 'Hit the images coming out of the holes and score, stay away from reverse image which will reduce your score.',
  identifyLocationShareMessage: 'Identify the location and win rewards in "{title}" on #Rab7. {url}',
  guideIdentifyLocation: 'Identity the place based on given text or image and pick the nearest location in the map to win rewards',
  identifyLocationForThePlace: 'Identify location for the place',
  pickedLocationSubmitted: 'Picked Location Submitted',
  correctLocation: 'Correct Location',
  yourPickedLocation: 'Your Picked Location',
  guidePokerMessage: 'Distribute the cards between you and the opponent, then turn over your cards. If the total of your cards is more, you win the round. Win more rounds than the opponent to win the game.',
  guidePokerCardsImages: 'Each card is having value 1-5, except below special card-',
  cardImage: 'Card Image',
  cardValue: 'Value',
  hint1GamePoker: 'click button to get cards',
  hint2GamePoker: 'flip to show your cards',
  trainings: 'Quiz',
  noTrainingAvailable: 'No Quiz Available',
  multipleItemsHere: 'Multiple Items Here',
  multiplePlacesHere: 'Multiple Places Here',
  multiplePostsHere: 'Multiple Posts Here',
  userActivityTopPoints: 'Activists',
  noUserActivityTopPoints: 'No Activists Available',
  userProfile: 'User Profile',
  addSocialAccounts: 'Add Social Accounts',
  editSocialAccounts: 'Edit Social Accounts',
  socialAccountsUpdated: 'Social Accounts Updated',
  socialAccountsAdded: 'Social Accounts Added',
  youtubeUsername: 'Youtube Username',
  facebookUsername: 'Facebook Username',
  instagramUsername: 'Instagram Username',
  twitterUsername: 'Twitter Username',
  snapchatUsername: 'Snapchat Username',
  tiktokUsername: 'Tiktok Username',
  userPosts: 'User Posts',
  userJobPosts: 'User Job Posts',
  userTalentPosts: 'User Resume Posts',
  userTrips: 'User Trips',
  userPackages: 'User Packages',
  userChallengePosts: 'User Posts in Challenge',
  myChallengePosts: 'My Posts in Challenge',
  userIReviews: 'User Reports',
  myIReviews: 'My Reports',
  challengePosts: 'Reel Posts',
  hintSocialAccountUsername: '# enter username only, excluding @',
  hintActivePercentile: '# rab7 active percentile\n# more you are active in app, better is your percentile',
  showResults: 'Show Results',
  answers: 'Answers',
  totalAnswersColon: 'Total Answers:',
  adVideo: 'Show Video',
  playNowWinnerList: 'Play Now',
  endedRestartingAgain: 'Ended, Restarting again',
  noWinnersYet: 'No Winners Yet, Hurry Up!',
  rewardsWon: 'Rewards Won',
  noPlayersPlayed: 'No Plays',
  players: 'Players',
  playersStats: 'Players Stats',
  offer1: 'Offer',
  reelTitleLengthPrompt: 'Enter title to be more than 15 letters.',
  enterRab7: 'Enter to Rab7 World',
  inSameTrendMessage: 'you are in same trend',
  games: 'Games',
  noReels: 'No Reels Found',
  reels: 'Reels',



  "generic.yes": "Yes",
  "generic.no": "No",
  "generic.ok": "Ok",
  "generic.done": "Done",
  "generic.cancel": "Cancel",
  "generic.click_here": "Click here",
  "generic.add": "Add",
  "generic.save": "Save",
  "generic.send": "Send",
  "generic.update": "Update",
  "generic.later": "Later",
  "generic.edit": "Edit",
  "generic.next": "Next",
  "generic.log_in": "Log In",
  "generic.submit": "Submit",
  "generic.server_error_msg": "Something went wrong, please try again!",
  "generic.server_not_reachable": "Unable to connect to server, please try again!",
  "generic.required": "Required",
  "generic.logout": "Logout",
  "generic.loading...": "Loading ...",
  "generic.select...": "Select...",
  "generic.search": "Search",
  "generic.means_mandatory": "means mandatory",
  "generic.likes": "Likes",
  "generic.dislikes": "Dislikes",
  "generic.comments": "Comments",
  "generic.your_feedback": "Your Feedback",
  "generic.your_comment": "Your Comment",
  "generic.reply_to": "Reply to",
  "generic.replying_to": "Replying to",
  "generic.image_file": "Image File",
  "generic.audio_file": "Audio File",
  "generic.branch": "Audio File",
  "generic.select_all": "Select All",
  "generic.select_branch": "Select Branch",
  "generic.date": "Date",
  "generic.start_date": "Start Date",
  "generic.end_date": "End Date",
  "generic.add_new": "Add New",
  "generic.active": "Active",
  "generic.inactive": "Inactive",
  "generic.approval_pending": "Approval Pending",
  "generic.rejected": "Rejected",
  "generic.name": "Name",
  "generic.name_ar": "Name (Arabic)",
  "generic.title": "Title",
  "generic.title_ar": "Title (Arabic)",
  "generic.description": "Description (English)",
  "generic.description_ar": "Description (Arabic)",
  "generic.photo": "Photo (English)",
  "generic.photo_ar": "Photo (Arabic)",
  "generic.audio": "Audio (English)",
  "generic.audio_ar": "Audio (Arabic)",
  "generic.tnc": "Terms & Conditions",
  "generic.tnc_ar": "Terms & Conditions (Arabic)",
  "generic.help": "Help",
  "generic.user": "User",
  "generic.activity": "Activity",
  "generic.gift": "Gift",
  "generic.spent": "Spent",
  "generic.earned": "Earned",
  "generic.redeemed": "Redeemed",

  "app.name": "Rab7",
  "app.name1": "Rab7",
  "app.name2": "Business",
  "app.not_found.404": "404",
  "app.not_found.text": "Page not found",
  "app.not_found.go_to_home": "Go to Home Page",

  "confirm.are_you_sure": "Are you sure?",

  "credential.password": "Password",
  "credential.old_password": "Old Password",
  "credential.new_password": "New Password",
  "credential.confirm_password": "Confirm Password",

  "user.profile.business_name.label": "Company name as in the Commercial Register",
  "user.profile.record_number.label": "Commercial Record Number",
  "user.profile.record_image.label": "Commercial Record image",
  "user.profile.owing_type.label": "Owning type",
  "user.profile.category.label": "Category",
  "user.profile.sub_category.label": "Sub category",
  "user.profile.short_name.label": "Short Business Name (English)",
  "user.profile.short_name_ar.label": "Short Business Name (Arabic)",
  "user.profile.logo.label": "Business Logo",
  "user.profile.contact_person_name.label": "Contact Person Name",
  "user.profile.contact_person_email.label": "Contact Person Email",
  "user.profile.contact_person_number.label": "Contact Person Number",
  "user.profile.city.label": "City",
  "user.profile.district.label": "District",
  "user.profile.geo_location.label": "Geo location",
  "user.profile.latitude.label": "Latitude",
  "user.profile.longitude.label": "Longitude",
  "user.profile.email.label": "Email",
  "user.profile.username.label": "User Name",
  "user.profile.mobile_number.label": "Mobile Number",
  "user.profile.mobile_number.hint": "New Mobile Number",
  "user.profile.success": "Profile updated successfully",

  "login.signup": "Don't have an account?<br><b>Register here</b>",
  "login.email": "Email",
  "login.password": "Password",
  "login.forgotpassword": "Forgot Password?",
  "login.app_name": "Rab7 Business",
  "login.mfa.title": "Login",
  "login.validation.email.required": "Email is required",
  "login.validation.password.required": "Password is required",

  "login.forgot_password.text": "Forgot Password?",
  "login.forgot_password.info": "Please write your email to recover your password, We will send one code on your registered email and mobile number, you need to enter the code and reset the password on next screen.",
  "login.forgot_password.reset_password": "Reset Password",
  "login.forgot_password.resend_code": "Resend Code",
  "login.forgot_password.resend_code.retry_in": "enable after {value} seconds",
  "login.forgot_password.reset_password.info": "Enter verification code sent to your registered email and mobile number",
  "login.forgot_password.new_password.info": "Please write new password",
  "login.forgot_password.send_otp_success": "The OTP has been sent successfully!",
  "login.forgot_password.change_password_success": "The password has been updated successfully!",

  "registration.go_through_all_step": "Please go through all registration steps to get started.",
  "registration.step1": "Step 1.",
  "registration.step2": "Step 2.",
  "registration.step3": "Step 3.",
  "registration.step4": "Step 4.",
  "registration.step1.title": "Step 1. Main Information:",
  "registration.step2.title": "Step 2. More Information:",
  "registration.step3.title": "Step 3. Location Information:",
  "registration.step4.title": "Step 4. Login Information:",
  "registration.to_go_to_login_page": " to go to login page!",
  "registration.i_agree": "I agree to the",
  "registration.tnc": " Terms, Conditions and Privacy",
  "registration.mobile_verification.title": "Mobile Verification",
  "registration.mobile_verification.resend_code": "Resend Code",
  "registration.mobile_verification.resend_code.retry_in": "enable after {value} seconds",
  "registration.mobile_verification.info": "Enter verification code sent to your registered mobile number",

  "registration.validation.name.required": "Required",
  "registration.validation.name.max_char": "Should not be more than {max}",
  "registration.validation.name_ar.required": "Required",
  "registration.validation.business_name.required": "Required",
  "registration.validation.record_number.required": "Required",
  "registration.validation.record_number.max_char": "Should not be more than {max}",
  "registration.validation.recordImageFile.required": "Required",
  "registration.validation.imageFile.required": "Required",
  "registration.validation.owing_type.required": "Required",
  "registration.validation.owing_type.min_num": "Should be more than {min}",
  "registration.validation.owing_type.max_num": "Should be less than {max}",
  "registration.validation.category_id.required": "Required",
  "registration.validation.sub_category_id.required": "Required",
  "registration.validation.contact_person_name.required": "Required",
  "registration.validation.contact_person_name.max_char": "Should not be more than {max}",
  "registration.validation.contact_person_number.required": "Required",
  "registration.validation.contact_person_email.required": "Required",
  "registration.validation.contact_person_email.max_char": "Should not be more than {max}",
  "registration.validation.locality_id.required": "Required",
  "registration.validation.sub_locality_id.required": "Required",
  "registration.validation.latitude.required": "Required",
  "registration.validation.longitude.required": "Required",
  "registration.validation.username.required": "Required",
  "registration.validation.username.min_char": "Minimum {min} character required",
  "registration.validation.username.max_char": "Should not be more than {max}",
  "registration.validation.username.invalid_char": "Only Alphabet, Number and Underscore are allowed",
  "registration.validation.email.required": "Required",
  "registration.validation.mobile_number.required": "Required",
  "registration.validation.mobile_number.min_char": "Should have at least {min} digits",
  "registration.validation.mobile_number.max_char": "Should have at max {max} digits",
  "registration.validation.password.required": "Required",
  "registration.validation.password.min_char": "Minimum {min} character required",
  "registration.validation.password.max_char": "Should not be more than {max}",
  "registration.validation.confirmPassword.required": "Required",
  "registration.validation.confirmPassword.not_matched": "Password should be same",

  "notification.messaged_you": "messaged you",
  "notification.replied_to_comment": "replied to your comment in <strong>{title}</strong>",
  "notification.system_message": "System Message",
  "notification.activity_approved": "approved your activity <strong>{title}</strong>",
  "notification.activity_rejected": "rejected your activity <strong>{title}</strong>",
  "notification.activity_reject_reason": "Reason - <strong>{rejectReason}</strong>",

  "navigation.home": "Home",
  "navigation.settings": "Settings",
  "navigation.profile.approval_pending": "Approval Pending",
  "navigation.profile.approval_rejected": "Approval Rejected",
  "navigation.insights": "Insights",
  "navigation.campaigns": "Campaigns",
  "navigation.sales": "Sales",
  "navigation.employees": "Employees",
  "navigation.management": "Management",
  "navigation.branches": "Branches",
  "navigation.loyalty_formulas": "Loyalty Formulas",
  "navigation.earn_point_formulas": "Earn Point Formulas",
  "navigation.redeem_point_formulas": "Redeem Point Formulas",
  "navigation.gifts": "Gifts",
  "navigation.chats": "Chats",
  "navigation.reviews": "Reviews",
  "navigation.activities": "Activities",
  "navigation.employee": "Employee",
  "navigation.customer": "Customer",
  "navigation.transactions": "Transactions",
  "navigation.user_balances": "Customers Balances",
  "navigation.system_users": "System Users",

  "admin.loyalty_formula_name": "Loyalty Formula Name",
  "admin.priority": "Priority",
  "admin.priority.hint": "1 is low priority and 10 is high when you have more than one formula",
  "admin.quantity": "Quantity",
  "admin.invite": "Invite",
  "admin.deleted": "Deleted",
  "admin.invited": "Invited",
  "admin.all": "All",
  "admin.none": "None",
  "admin.access": "Access",
  "admin.address": "Address (English)",
  "admin.availability": "Availability",
  "admin.actions": "Actions",
  "admin.branch": "Branch",
  "admin.role": "Role",
  "admin.photo": "Photo",
  "admin.rating": "Rating",
  "admin.creator": "Creator",
  "admin.type": "Type",
  "admin.recommended_aspect_ratio": "Recommended aspect ratio for image is 16:9",
  "admin.search_address": "Search Address",
  "admin.table.no_data": "No data available",
  "admin.select_geo": "Select Geo Location",

  "admin.list.action.toggle_availability": "Toggle Availability",
  "admin.list.action.reply": "Reply",
  "admin.list.action.view": "View",
  "admin.list.action.edit": "Edit",
  "admin.list.action.delete": "Delete",
  "admin.list.action.send_message": "Send Message",
  "admin.list.action.invite_again": "Invite Again",

  "admin.landing.welcome_to": "Welcome to ",
  "admin.landing.business_portal": " business portal",
  "admin.landing.approval_pending": "Your account is not yet approved. Until then you can make changes to business details.",
  "admin.landing.approval_rejected": "Your account is rejected.",
  "admin.landing.approval_rejected_reason": "Reason: {rejectReason}",
  "admin.landing.you_have_access_to": "You have access to:",

  "admin.profile.title": "Business Profile",
  "admin.profile.edit_profile": "Edit Profile",
  "admin.profile.change_password": "Change Password",
  "admin.profile.settings": "Settings",
  "admin.profile.settings.allow_bal_transfer.title": "Allow Balance Transfer",
  "admin.profile.settings.allow_bal_transfer.desc": "Do you want to allow customer to transfer earned points among themselves.",
  "admin.profile.change_mobile": "Change Mobile Number",
  "admin.profile.change_mobile.info": "Enter verification code sent to your this mobile number",
  "admin.profile.change_mobile.success": "The mobile number has been updated successfully!",
  "admin.profile.rating": "Rating",
  "admin.profile.total_earnings": "Total Earnings",
  "admin.profile.total_discount": "Total Discounts",
  "admin.profile.rab7_balance": "Rab7 Balance",

  "admin.post.list.title": "Reviews List",
  "admin.post.list.search": "Search Feedback",
  "admin.post.filter.type": "Type",
  "admin.post.filter.branch": "Branch",
  "admin.post.filter.type.both": "Both",
  "admin.post.table.post_title": "Feedback",
  "admin.post.table.date_posted": "Date Posted",
  "admin.post.table.comments_count": "Comments Count",
  "admin.post.table.rating_by": "Rating By",
  "admin.post.rating_by.visitor": "Visitor",
  "admin.post.rating_by.buyer": "Buyer",
  "admin.post.confirm_delete_msg": "Are you sure you want to delete post? You will not be able to restore it later.",
  "admin.post.comment.confirm_delete_msg": "Are you sure you want to delete post comment? You will not be able to restore it later.",
  "admin.post.validation.address.required": "Required",

  "admin.branch.list.title": "Branches List",
  "admin.branch.list.search": "Search name",
  "admin.branch.field.branch_name": "Branch Name (English)",
  "admin.branch.field.branch_name_ar": "Branch Name (Arabic)",
  "admin.branch.field.city.hint": "Type your city & district and choose from the list that will appear to you, if you didn't find, choose the nearest one",
  "admin.branch.field.geo_location.hint": "This location will help your customer to find you",
  "admin.branch.modal.hint.location": "select city first then use geo location to populate latitude and longitude",
  "admin.branch.add_new_branch": "Add New Branch",
  "admin.branch.edit_branch": "Edit Branch",
  "admin.branch.confirm_enable_msg": "Are you sure you want to enable branch?",
  "admin.branch.confirm_disable_msg": "Are you sure you want to disable branch? This will disable all employees associated to this branch. You can enable the branch later. You need to enable the employees associated to this branch explicitly.",
  "admin.branch.confirm_delete_msg": "Are you sure you want to delete branch? This will delete all employees associated to this branch. You will not be able to restore it later.",
  "admin.branch.validation.name.required": "Required",
  "admin.branch.validation.name_ar.required": "Required",
  "admin.branch.validation.address.required": "Required",
  "admin.branch.validation.localityId.required": "Required",
  "admin.branch.validation.subLocalityId.required": "Required",
  "admin.branch.validation.latitude.required": "Required",
  "admin.branch.validation.longitude.required": "Required",

  "admin.gift.list.title": "Gifts List",
  "admin.gift.list.search": "Search name",
  "admin.gift.table.gift_name": "Gift Name",
  "admin.gift.field.points_to_redeem": "Required points needed",
  "admin.gift.table.consumed_limit": "Consume Limit",
  "admin.gift.add_new_gift": "Add New Gift",
  "admin.gift.edit_gift": "Edit Gift",
  "admin.gift.confirm_enable_msg": "Are you sure you want to enable gift?",
  "admin.gift.confirm_disable_msg": "Are you sure you want to disable gift?",
  "admin.gift.confirm_delete_msg": "Are you sure you want to delete gift? You will not be able to restore it later.",
  "admin.gift.validation.name.required": "Required",
  "admin.gift.validation.imageFile.required": "Required",
  "admin.gift.validation.pointsToRedeem.required": "Required",
  "admin.gift.validation.startAt.required": "Required",
  "admin.gift.validation.endAt.required": "Required",
  "admin.gift.validation.description.max_char": "Description (English) can have at max {maxChar} characters",
  "admin.gift.validation.tnc.max_char": "Terms and Conditions (English) can have at max {maxChar} characters",
  "admin.gift.validation.description_ar.max_char": "Description (Arabic) can have at max {maxChar} characters",
  "admin.gift.validation.tnc_ar.max_char": "Terms and Conditions (Arabic) can have at max {maxChar} characters",

  "admin.loyalty_formula.field.name.hint": "Name is only for your reference",

  "admin.earnPointFormula.list.title": "Loyalty Earn Formulas",
  "admin.earnPointFormula.list.search": "Search name",
  "admin.earnPointFormula.table.min_invoice_value": "Minimum Invoice Value",
  "admin.earnPointFormula.table.max_points": "Maximum Points",
  "admin.earnPointFormula.field.spends_per_point": "Amount spend to get one point",
  "admin.earnPointFormula.field.min_bill_to_earn_point": "Minimum invoice value to earn points",
  "admin.earnPointFormula.field.max_earn_points": "Maximum daily points that can be earned",
  "admin.earnPointFormula.add_new_earnPointFormula": "Add New Earn Point Formula",
  "admin.earnPointFormula.edit_earnPointFormula": "Edit Earn Point Formula",
  "admin.earn_point_formula.validation.name.required": "Required",
  "admin.earn_point_formula.validation.priority.required": "Required",
  "admin.earn_point_formula.validation.priority.min_num": "Should be more than {min}",
  "admin.earn_point_formula.validation.priority.max_num": "Should be less than {max}",
  "admin.earn_point_formula.validation.startAt.required": "Required",
  "admin.earn_point_formula.validation.endAt.required": "Required",
  "admin.earn_point_formula.validation.spendsPerPoint.required": "Required",

  "admin.redeemPointFormula.list.title": "Loyalty Redeem Formulas",
  "admin.redeemPointFormula.list.search": "Search name",
  "admin.redeemPointFormula.table.min_invoice_amount": "Min Invoice Amount",
  "admin.redeemPointFormula.table.min_points_can_redeem": "Min Redeem with single invoice",
  "admin.redeemPointFormula.table.max_points_can_redeem": "Max Redeem with single invoice",
  "admin.redeemPointFormula.table.min_wallet_balance_to_redeem": "Min Wallet Balance to redeem",
  "admin.redeemPointFormula.field.min_spends_to_redeem": "Minimum Invoice Amount to Redeem Points",
  "admin.redeemPointFormula.field.min_points_can_redeem": "Minimum Redeem Points With Single Invoice",
  "admin.redeemPointFormula.field.max_points_can_redeem": "Maximum Redeem Points With Single Invoice",
  "admin.redeemPointFormula.field.max_points_can_redeem_daily": "Maximum Redeem Points Daily",
  "admin.redeemPointFormula.field.min_wallet_balance_to_redeem": "Minimum Wallet Balance To Redeem Points",
  "admin.redeemPointFormula.add_new_redeemPointFormula": "Add New Redeem Point Formula",
  "admin.redeemPointFormula.edit_redeemPointFormula": "Edit Redeem Point Formula",
  "admin.redeemPointFormula.confirm_enable_msg": "Are you sure you want to enable loyalty redeem formula?",
  "admin.redeemPointFormula.confirm_disable_msg": "Are you sure you want to disable loyalty redeem formula?",
  "admin.redeemPointFormula.confirm_delete_msg": "Are you sure you want to delete loyalty redeem formula? You will not be able to restore it later.",
  "admin.redeem_point_formula.validation.name.required": "Required",
  "admin.redeem_point_formula.validation.priority.required": "Required",
  "admin.redeem_point_formula.validation.priority.min_num": "Should be more than {min}",
  "admin.redeem_point_formula.validation.priority.max_num": "Should be less than {max}",
  "admin.redeem_point_formula.validation.start_at.required": "Required",
  "admin.redeem_point_formula.validation.end_at.required": "Required",
  "admin.redeem_point_formula.validation.minSpendsToRedeem.required": "Required",
  "admin.redeem_point_formula.validation.minSpendsToRedeem.min_num": "Should be more than {min}",
  "admin.redeem_point_formula.validation.minPointsCanRedeem.required": "Required",
  "admin.redeem_point_formula.validation.minPointsCanRedeem.min_num": "Should be more than {min}",
  "admin.redeem_point_formula.validation.maxPointsCanRedeem.required": "Required",
  "admin.redeem_point_formula.validation.maxPointsCanRedeem.min_num": "Should be more than {min}",
  "admin.redeem_point_formula.validation.maxPointsCanRedeemDaily.required": "Required",
  "admin.redeem_point_formula.validation.maxPointsCanRedeemDaily.min_num": "Should be more than {min}",
  "admin.redeem_point_formula.validation.minWalletBalanceToRedeem.required": "Required",
  "admin.redeem_point_formula.validation.minWalletBalanceToRedeem.min_num": "Should be more than {min}",

  "admin.employee.list.title": "Employee Management",
  "admin.employee.list.search": "Search by name, mobile",
  "admin.employee.field.role.help": "Role can be either cashier or manager",
  "admin.employee.field.mobile_numbers": "Employee Mobile Number",
  "admin.employee.field.mobile_numbers.hint": "Please press enter to add",
  "admin.employee.field.branch.hint": "If no branch choosing this employee is accessing all branches",
  "admin.employee.add_new_employee": "Invite Employee",
  "admin.employee.edit_employee": "Edit Employee",
  "admin.employee.table.email": "Email",
  "admin.employee.confirm_enable_msg": "Are you sure you want to enable employee?",
  "admin.employee.confirm_disable_msg": "Are you sure you want to disable employee? It will also disable him from all existing branch and after enabling him you need to assign him to the relative branch manually.",
  "admin.employee.confirm_delete_msg_invitation": "Are you sure you want to delete the invitation? You will not be able to restore it later.",
  "admin.employee.confirm_delete_msg_employee": "Are you sure you want to delete employee? You will not be able to restore it later.",
  "admin.employee.confirm_invite_msg": "Are you sure you want to resend invitation?",
  "admin.employee.validation.mobile_numbers.required": "Required",
  "admin.employee.validation.role.required": "Required",
  "admin.employee.validation.branchIds.required": "Required",
  "admin.employee.validation.access.required": "Required",

  "admin.chats.table.chat_with": "Chat With",
  "admin.chats.table.last_message": "Last Message",
  "admin.chats.table.last_message_at": "Last Message At",

  "admin.customerChats.list.title": "Customer Chats List",
  "admin.customerChats.list.search": "Search name, username and rab7 number",

  "admin.employeeChats.list.title": "Employee Chats List",
  "admin.employeeChats.list.search": "Search name and username",

  "admin.supportChats.modal.title": "Support @Rab7",

  "admin.activity.list.title": "Activities List",
  "admin.activity.list.search": "Search title, details",
  "admin.activity.filter.type": "Type",
  "admin.activity.filter.state": "State",
  "admin.activity.filter.city": "City",
  "admin.activity.add_offer": "Add Offer",
  "admin.activity.add_activity": "Add Ad",
  "admin.activity.table.clicked_count": "Clicked Count",
  "admin.activity.table.consumed_count": "Consumed Count",
  "admin.activity.table.answer_count": "Answered Count",
  "admin.activity.table.displayed_count": "Displayed Count",
  "admin.activity.table.likes_count": "Likes Count",
  "admin.activity.table.dislikes_count": "Dislikes Count",
  "admin.activity.table.rating": "Rating",
  "admin.activity.table.raters_count": "Raters Count",
  "admin.activity.confirm_enable_msg": "Are you sure you want to enable activity?",
  "admin.activity.confirm_disable_msg": "Are you sure you want to disable activity?",
  "admin.activity.confirm_delete_msg": "Are you sure you want to delete activity? You will not be able to restore it later.",
  "admin.activity.comment.confirm_delete_msg": "Are you sure you want to delete activity comment? You will not be able to restore it later.",
  "admin.activity.reject.title": "Reject Reason",
  "admin.activity.section.basic_details": "Basic Details",
  "admin.activity.section.display_limit": "Display Limit",
  "admin.activity.offer.section.display_limit": "Offer Elements",
  "admin.activity.section.display_criteria": "Display Criteria",
  "admin.activity.offer.section.display_criteria": "Target Criteria",
  "admin.activity.section.question": "Question",
  "admin.activity.criteria.hint": "Leave empty to target all",
  "admin.activity.field.activity_type": "Activity Type",
  "admin.activity.field.display_limit": "Display Limit",
  "admin.activity.field.display_limit_per_user": "Display Limit Per User",
  "admin.activity.field.consume_limit": "Consume Limit",
  "admin.activity.field.consume_limit_per_user": "Consume Limit Per User",
  "admin.activity.field.click_limit": "Click Limit",
  "admin.activity.field.answer_limit": "Answer Limit",
  "admin.activity.field.transfer_limit_per_user": "Transfer Limit Per User",
  "admin.activity.field.clicked_points": "Clicked Points",
  "admin.activity.field.answered_points": "Answered Points",
  "admin.activity.field.consumed_points": "Consumed Points",
  "admin.activity.field.display_space": "Display Place",
  "admin.activity.field.allow_transfer": "Allow Transfer",
  "admin.activity.field.is_code_needed": "Verification Code Needed to Consume",
  "admin.activity.field.select_branch": "Branch where offer can consume",
  "admin.activity.field.select_regions": "Target Region",
  "admin.activity.field.select_localities": "Target City",
  "admin.activity.field.select_sub_localities": "Target District",
  "admin.activity.field.gender": "Target Gender",
  "admin.activity.field.marital_status": "Target Marital Status",
  "admin.activity.field.select_age_group": "Target Age Group",
  "admin.activity.field.select_education_level": "Target Education Level",
  "admin.activity.field.select_nationality": "Target Nationality",
  "admin.activity.field.select_profession": "Target Profession",
  "admin.activity.field.select_companies": "Company where target customers work",
  "admin.activity.field.select_company_level": "Target Company Level",
  "admin.activity.field.criteria_earned_system_points": "Target Earned System Points",
  "admin.activity.field.question_title": "Question Title (English)",
  "admin.activity.field.question_title_ar": "Question Title (Arabic)",
  "admin.activity.field.question_options_1": "Question Option 1 (English)",
  "admin.activity.field.question_options_1_ar": "Question Option 1 (Arabic)",
  "admin.activity.field.question_options_2": "Question Option 2 (English)",
  "admin.activity.field.question_options_2_ar": "Question Option 2 (Arabic)",
  "admin.activity.field.question_options_3": "Question Option 3 (English)",
  "admin.activity.field.question_options_3_ar": "Question Option 3 (Arabic)",
  "admin.activity.field.question_options_4": "Question Option 4 (English)",
  "admin.activity.field.question_options_4_ar": "Question Option 4 (Arabic)",
  "admin.activity.field.right_option": "Right Option",
  "admin.activity.field.right_option.hint": "If empty then user can answer any thing, if wrong user will get hint two times to answer the right one",
  "admin.activity.field.right_option_hint": "Right Option Hint (English)",
  "admin.activity.field.right_option_hint_ar": "Right Option Hint (Arabic)",
  "admin.activity.field.feedback_hint": "Feedback Hint (English)",
  "admin.activity.field.feedback_hint_ar": "Feedback Hint (Arabic)",
  "admin.activity.display_spaces.acknowledgements": "Acknowledgements",
  "admin.activity.display_spaces.feeds": "Feeds",
  "admin.activity.display_spaces.special_prompts": "Special Prompts",
  "admin.activity.display_spaces.everywhere": "Everywhere",
  "admin.activity.display_spaces.mine": "Offer",
  "admin.activity.modal.ratings": "Ratings",
  "admin.activity.validation.type.required": "Required",
  "admin.activity.validation.title.required": "Required",
  "admin.activity.validation.priority.required": "Required",
  "admin.activity.validation.priority.min_num": "Should be more than {min}",
  "admin.activity.validation.priority.max_num": "Should be less than {max}",
  "admin.activity.validation.start_at.required": "Required",
  "admin.activity.validation.end_at.required": "Required",

  "admin.transaction.title": "Transactions List",
  "admin.transaction.search": "Search spend",
  "admin.transaction.field.transaction_type": "Transaction Type",
  "admin.transaction.type.earn": "Earn",
  "admin.transaction.type.redeem": "Redeem Gift",
  "admin.transaction.type.consume_offer": "Consume Offer",
  "admin.transaction.type.refund": "Refund",
  "admin.transaction.type.transfer": "Transfer",

  "admin.userBalance.title": "Customers Balances List",
  "admin.userBalance.search": "Search Customers Balances",
  "admin.userBalance.filter.minPoints": "Min Points",
  "admin.userBalance.filter.maxPoints": "Max Points",

  "admin.systemUser.list.title": "System Users List",
  "admin.systemUser.list.search": "Search email, mobile",
  "admin.systemUser.add_new_user": "Add System User",
  "admin.systemUser.edit_user": "Edit System User",
  "admin.system_user.validation.mobile_number.required": "Required",
  "admin.system_user.validation.email.required": "Required",
  "admin.system_user.validation.branchIds.required": "Required",
  "admin.system_user.validation.access.required": "Required",

  "admin.insights.sort.title": "Sort by :",
  "admin.insights.reset_all": "Reset All",
  "admin.insights.filters": "Filters",
  "admin.insights.filter.date_rang": "Date Range",
  "admin.insights.filter.hours": "Hours",
  "admin.insights.filter.days": "Days",
  "admin.insights.filter.day_of_week": "Week Days",
  "admin.insights.filter.months": "Months",
  "admin.insights.filter.region": "Region",
  "admin.insights.filter.city": "City",
  "admin.insights.filter.district": "District",
  "admin.insights.filter.gender": "Gender",
  "admin.insights.filter.marital_status": "Marital Status",
  "admin.insights.filter.age_group": "Age Group",
  "admin.insights.filter.education_level": "Education Level",
  "admin.insights.filter.branch": "Branch",
  "admin.insights.filter.employee": "Employee's Name",
  "admin.insights.filter.transaction_type": "Transaction Type",
  "admin.insights.filter.landing_options": "Landing Options",
  "admin.insights.filter.ratings": "Rating",
  "admin.insights.filter.rating_by": "Rating By",
  "admin.insights.filter.activity": "Activity",
  "admin.insights.filter.answers": "Answers",
  "admin.insights.filter.transferred_to": "Transferred To",
  "admin.insights.filter.like": "Like",
  "admin.insights.filter.preference": "Preference",
  "admin.insights.filter.nationality": "Nationality",
  "admin.insights.filter.profession": "Profession",
  "admin.insights.filter.company": "Company",
  "admin.insights.filter.visits": "Customer Frequency",
  "admin.insights.filter.gift": "Gift",
  "admin.insights.filter.offer": "Offer",
  "admin.insights.filter.title.general": "General",
  "admin.insights.filter.title.demographic": "Demographic",
  "admin.insights.filter.title.geographic": "Geographic",
  "admin.insights.filter.title.timeline": "Timeline",
  "admin.insights.filter.title.transactional": "Transactional",

  "admin.branch.filter.city": "City",
  "admin.activity.count_limit.hint": "Leave empty for no limit",
  "admin.address_ar": "Address (Arabic)",
  "admin.branch.validation.address_ar.required": "Required",
  "admin.activity.field.select_criteria_targeted_customers": "Target Customers by Rab7 Number",
  "admin.activity.field.select_criteria_targeted_all_customers": "Targeting to all Customers",
  "admin.activity.field.target_only_selected_customers": "Target only selected customers",
  "admin.profile.passed_for_approval": "Your profile is passed to rab7 admin for approval. You will be notified by email and mobile number.",

  "profile.validation.bio.max_char": "Should not be more than {max}",
  "admin.activity.field.link": "Link (English)",
  "admin.activity.field.link_ar": "Link (Arabic)",
  "admin.activity.link.hint": "Put url you want to open when photo is clicked",
  "admin.activity.smart_ad.criteria.hint": "Leave empty to display at all",
  "admin.activity.smart_ad.field.select_branch": "Branch where Smart Ad will display",
  "admin.transfer_transaction.title": "Transfer Transactions",
  "admin.transfer_transaction.search": "Search",
  "admin.transfer_transaction.from_user": "From User",
  "admin.transfer_transaction.to_user": "To User",
  "admin.transfer_transaction.points": "Points",
  "admin.transfer_transaction.relation": "Relation",
  "admin.transfer_transaction.createdAt": "Created At",
  "admin.transfer_transaction.filter.relation": "Relation",
  "navigation.transferTransactions": "Transfer Transactions",

  "admin.system_notification.title": "System Notification",
  "admin.insights.filter.customers": "Customers",
  "admin.insights.filter.top_customers": "Top Customers",

  "notification.commented_in_activity": "commented in activity <strong>{title}<strong>",
  "notification.review": "reviewed you",
  "notification.count_text": "and {count} other",
  "notification.counts_text": "and {count} others",

  "admin.activity.field.target_only_selected_business": "Target only my customers",
  "admin.activity.target_only_selected_business.hint": "Targeting all customers of all businesses charge will be applied",

  "user.profile.form_image.label": "Stamped Accept Form Image",
  "user.profile.form_image.hint": "<a href='https://f1db5c47-94e6-4de9-837c-d70fa07f0494.filesusr.com/ugd/a3cf48_b573ddf23f804a40a935131ffaa39e21.doc?dn=Chumber.doc' target='_blank'>Download the form, fill it, stampe it from chumber thru ABSHER and upload a scanned copy of it</a>",

  "user.profile.bio.label": "Bio",

  "admin.gift.quantity.hint": "Leave blank when unlimited",
  "admin.systemUser.confirm_disable_msg": "Are you sure you want to disable system user?",
  "admin.systemUser.confirm_enable_msg": "Are you sure you want to enable system user?",
  "admin.activity.field.question_option": "Question Option {number} (English)",
  "admin.activity.field.question_option.add": "Add Option",
  "admin.activity.field.question_option_ar": "Question Option {number} (Arabic)",
  "admin.activity.validation.options.name.required": "Required",
  "admin.activity.validation.options.name.max_char": "Should not be more than {maxChar} characters long",
  "admin.activity.validation.options.name_ar.max_char": "Should not be more than {maxChar} characters long",
  "admin.activity.field.select_user_interests": "Target User Interests",

  "admin.insights.focus_area.insights": "Interest",
  "admin.insights.fetch_insights": "Get Insights",

  "admin.customerChats.disabled.message": "The chat has been ended by customer. You are not allowed to send message.",
  "admin.activity.edit_activity_display_criteria": "Activity Targeting",
  "admin.activity.add_question": "Add Question",
  "admin.activity.list.action.edit_display_criteria": "Edit Activity Targeting",
  "admin.activity.activity_modal.success.message": "Your ads/offer/question is created and is passed to [Rab7 Support Team] for approval, and we will shift you to targeting screen where you can select the niche that you want to target customer for your ads/offer/question, leave the targeting empty if you want to target all the customers.",
  "admin.activity.activity_modal.target_customers": "Target Customers",
  "admin.activity.question.field.select_branch": "Branch where Question will display",

  "admin.insights.full_screen_map.compress": "Compress",
  "admin.insights.full_screen_map.expand": "Expand",
  "admin.insights.answers": "Answers",

  "registration.validation.form_image.required": "Required",

  "admin.activity.add_ad_title": "Create Smart Ad",
  "admin.activity.add_offer_title": "Create Offer",
  "admin.activity.add_question_title": "Add New Question",
  "admin.activity.edit_ad_title": "Edit Smart Ad",
  "admin.activity.edit_offer_title": "Edit Offer",
  "admin.activity.edit_question_title": "Edit Question",

  "admin.activity.smart_ad.field.display_limit_per_user": "View Limit Per User",
  "admin.activity.offer.link.hint": "Put url you want to open when photo is clicked",
  "admin.activity.offer.field.consume_limit": "Offers Consume Limit",
  "admin.activity.offer.field.consume_limit_per_user": "Offers Consume Limit Per User",

  "admin.activity.question.section.display_limit": "Question Period",
  "admin.activity.smart_ad.section.display_limit": "Smart Ad Elements",

  "admin.language.hint": "(English) for English mobile users \n (Arabic) for Arabic mobile users",

  "admin.activity.add_buyer_opinion_title": "Create Buyer Opinion",
  "admin.activity.edit_buyer_opinion_title": "Edit Buyer Opinion",
  "admin.activity.add_buyer_opinion": "Add Buyer Opinion",
  "admin.branch.table.branch_name": "Branch Name",
  "generic.name_en": "Name (English)",
  "generic.title_en": "Title (English)",
  "admin.activity.add_employee_offer": "Add Employee Offer",
  "admin.activity.add_employee_offer_title": "Add Employee Offer",
  "admin.activity.edit_employee_offer_title": "Edit Employee Offer",
  "admin.activity.section.feedback": "Feedback",
  "admin.activity.buyer_opinion.section.display_limit": "Opinion Elements",
  "generic.tnc_en": "Terms & Conditions (English)",
  "admin.activity.buyer_opinion.field.select_branch": "Branch where Opinion will display",
  "admin.employee.table.name": "Name",
  "admin.activity.table.title": "Title",
  "admin.activity.visitor_opinion.section.display_limit": "Opinion Elements",
  "admin.activity.add_visitor_opinion_title": "Create Visitor Opinion",
  "admin.activity.edit_visitor_opinion_title": "Edit Visitor Opinion",
  "admin.activity.add_visitor_opinion": "Add Visitor Opinion",
  "admin.activity.offer.field.consumable_days": "Offer Consumable Week Days",
  "admin.activity.consumable_days.hint": "Allow user to consume the offer in selected week days.\nLeave empty if you want this offer to be consumable in all days.",

  "admin.earnPointFormula.table.amount_spend": "Amount Spend to Earn One Point",
  "admin.earnPointFormula.table.availability": "Availability",
  "admin.earnPointFormula.table.toggle_redeem_formula_state": "Toggle Redeem Formula State",

  "navigation.products": "Products",
  "navigation.product_categories": "Categories",
  "navigation.product_items": "Products",

  "admin.productCategory.list.title": "Product Categories",
  "admin.productCategory.list.search": "Search",
  "admin.productCategory.edit": "Edit Product Category",
  "admin.productCategory.add_new": "Create Product Category",
  "admin.productCategory.validation.name.required": "Required",
  "admin.productCategory.validation.name_ar.required": "Required",
  "admin.productCategory.validation.name.max_char": "Should not be more than {max}",
  "admin.productCategory.validation.name_ar.max_char": "Should not be more than {max}",
  "admin.productCategory.confirm_enable_msg": "Are you sure you want to enable product category?",
  "admin.productCategory.confirm_disable_msg": "Are you sure you want to disable product category? This will disable all products in this category. You can enable the category later. You need to enable the products in this category explicitly.",
  "admin.productCategory.confirm_delete_msg": "Are you sure you want to delete product category? This will delete all products in this category. You will not be able to restore it later.",

  "admin.productItems.field.category": "Category",
  "admin.productItems.field.price": "Price",
  "admin.productItems.field.discount_price": "Discount Price",
  "admin.productItems.field.details": "Details",
  "admin.productItems.field.details_ar": "Details (Arabic)",
  "admin.productItems.field.allow_chat": "Allow Chat",
  "admin.productItems.field.allow_chat.hint": "Allow customers to initiate chat on this product",
  "admin.productItem.list.title": "Products",
  "admin.productItem.list.search": "Search",
  "admin.productItems.list.name": "Name",
  "admin.productItems.list.category": "Category",
  "admin.productItems.list.price": "Price",
  "admin.productItems.list.discount_price": "Discount Price",
  "admin.productItems.list.likes": "Likes",
  "admin.productItems.list.reaches": "Reaches",
  "admin.productItem.add_product": "Add Product",
  "admin.productItem.edit_product": "Edit Product",
  "admin.productItem.confirm_enable_msg": "Are you sure you want to enable product?",
  "admin.productItem.confirm_disable_msg": "Are you sure you want to disable product?",
  "admin.productItem.confirm_delete_msg": "Are you sure you want to delete product? You will not be able to restore it later.",

  "admin.productItem.validation.categoryId.required": "Required",
  "admin.productItem.validation.name.required": "Required",
  "admin.productItem.validation.name.max_char": "Should not be more than {max} characters",
  "admin.productItem.validation.name_ar.required": "Required",
  "admin.productItem.validation.name_ar.max_char": "Should not be more than {max} characters",
  "admin.productItem.validation.details.required": "Required",
  "admin.productItem.validation.details.max_char": "Should not be more than {max} characters",
  "admin.productItem.validation.details_ar.required": "Required",
  "admin.productItem.validation.details_ar.max_char": "Should not be more than {max} characters",
  "admin.productItem.validation.imageUrls.required": "Should have atleast {min} images",
  "admin.productItem.validation.price.required": "Required",
  "admin.productItem.validation.price.min": "Should be more than {min}",

  "admin.earnPointFormula.list.discount": "Discount",
  "admin.earnPointFormula.discount.confirm_enable_msg": "Are you sure you want to enable discount?",
  "admin.earnPointFormula.discount.confirm_disable_msg": "Are you sure you want to disable discount?",
  "admin.earnPointFormula.confirm_delete_msg": "Are you sure you want to delete discount? You will not be able to restore it later.",

  "admin.employeeChats.disabled.message": "The chat has been ended by employee. You are not allowed to send message.",
  "admin.supportChats.disabled.message": "The chat has been ended by support. You are not allowed to send message.",

  "admin.activity.state.suspended": "Suspended",
  "admin.activity.state.waiting": "Waiting for Targeting",
  "admin.activity.activity_modal.target_all": "Target All",
  "admin.action.copy_link": "Copy Link",
  "admin.notification.link_copied": "Link copied.",

  "admin.profile.settings.notify_reviews.title": "Notify Reviews",
  "admin.profile.settings.notify_review_comments.title": "Notify Review Comments",
  "admin.profile.settings.notify_activity_comments.title": "Notify Campaign Comments",
  "admin.profile.settings.notify_messages.title": "Notify Chat Messages",

  "navigation.products_and_services": "Products & Services",
  "navigation.branch_and_employees": "Branches & Employees",
  "navigation.transfer_transactions": "Transfer Transactions",
  "navigation.customer_balances": "Customer Balances",

  "admin.list.action.comment": "Comment",

  "admin.system_user.permission.ad": "Ad",
  "admin.system_user.permission.offer": "Offer",
  "admin.system_user.permission.question": "Question",
  "admin.system_user.permission.buyer_opinion": "Buyer Opinion",
  "admin.system_user.permission.visitor_opinion": "Visitor Opinion",
  "admin.system_user.permission.review": "Review",
  "admin.system_user.permission.customer_chat": "Customer Chat",
  "admin.system_user.permission.employee_chat": "Employee Chat",
  "admin.system_user.permission.support_chat": "Support Chat",
  "admin.system_user.permission.transaction": "Transaction",
  "admin.system_user.permission.customer_balance": "Customer Balance",
  "admin.system_user.permission.loyalty_points": "Loyalty Points",
  "admin.system_user.permission.product": "Product",
  "admin.system_user.permission.gift": "Gift",
  "admin.system_user.permission.branch": "Branch",
  "admin.system_user.permission.employee": "Employee",
  "admin.system_user.permission.system_user": "System User",
  "admin.system_user.permission.profile": "Profile",
  "admin.system_user.permission.insights_campaign": "Insights Campaign",
  "admin.system_user.permission.insights_product": "Insights Product",
  "admin.system_user.permission.insights_profile": "Insights Profile",
  "admin.system_user.permission.insights_sales": "Insights Sales",

  "admin.system_user.permission.type.view": "View",
  "admin.system_user.permission.type.comment": "Comment",
  "admin.system_user.permission.type.manage": "Manage",

  "generic.clear": "Clear",

  "admin.activity.modal.answers": "Answers",
  "admin.activity.modal.totalAnswers": "Total Answers",

  "admin.post.filter.customer": "Customer",

  "admin.employeeChats.broadcast.title": "Broadcast",
  "admin.employeeChats.broadcast.employees": "Employees",
  "admin.employeeChats.broadcast.branches": "Branches",

  "admin.employeeChats.broadcast.validation.text.required": "Required",
  "admin.employeeChats.broadcast.validation.image_file.required": "Required",
  "admin.employeeChats.broadcast.validation.audio_file.required": "Required",

  "login.registration": "Registration",
  "login.register": "Register",
  "login.mobileNumber": "Mobile Number",
  "login.validation.tnc.required": "Please accept Terms, Privacy Policy & Usage Agreement for registration",
  "login.verifyMobile.title": "Verify Mobile Number",
  "login.verifyMobile.subTitle": "enter verification code sent to mobile number",
  "login.bySignUpAgreeTo": "By registering, you agree to our",
  "login.tnc": "Terms, Privacy Policy & Usage Agreement",
  "login.mobileNumberCode": "+966",
  "login.verificationCode": "Verification Code",
  "account.informationUpdated": "Business Information Submitted",
  "account.informationUpdatedMessage": "Business information has been submitted and will be reviewed by our team. You will be notified via email and sms to your registered email address and mobile number respectively once account is approved.",
  "registration.success": "Registration Successful",
  "registration.successMessage": "You have successfully registered, login now and enter to Rab7 world",
  "registration.companyInformation": "Company Information",
  "registration.businessInformation": "Business Information",
  "registration.LocationInformation": "Location Information",
  "registration.bankAccountInformation": "Bank Account Information",
  "registration.taxInformation": "Tax Information",
  "registration.personalInformation": "Personal Information",
  "registration.businessType": "Business Document Type",
  "registration.typeBusiness": "Commercial Record",
  "registration.typeFreelancer": "Freelancer",
  "registration.businessNameEn": "Company Name (English)",
  "registration.businessNameAr": "Company Name (Arabic)",
  "registration.recordNumberCommercial": "Commercial Record Document Number",
  "registration.recordImageCommercial": "Commercial Record Document",
  "registration.recordNumberFreelance": "Freelancer Document Number",
  "registration.recordImageFreelance": "Freelancer Document",
  "registration.issueDate": "Issue Date",
  "registration.expiryDate": "Expiry Date",
  "registration.category": "Category",
  "registration.nameEn": "Name (English)",
  "registration.nameAr": "Name (Arabic)",
  "registration.logoImage": "Logo",
  "registration.keywords": "Keywords",
  "registration.bio": "Bio",
  "registration.bank": "Bank",
  "registration.bankBranch": "Bank Branch",
  "registration.bankAccountName": "Account Name (in english only)",
  "registration.bankAccountNumber": "Account Number",
  "registration.bankIban": "IBAN",
  "registration.identityExpiryDate": "National Identity Expiry Date",
  "registration.identityNumber": "National Identity Number",
  "registration.identityNameFirst": "First Name",
  "registration.identityNameLast": "Last Name",
  "registration.identityImage": "National Identity Document",
  "registration.locality": "City",
  "registration.subLocality": "District",
  "registration.location": "Location",
  "registration.pickLocation": "Pick Location from Map",
  "registration.taxNumber": "TIN Number",
  "registration.taxDocument": "VAT Document",

  "registration.hint.businessNameCommercialEn": "# Company Name as in Commercial Record Document in English",
  "registration.hint.businessNameCommercialAr": "# Company Name as in Commercial Record Document in Arabic",
  "registration.hint.businessNameFreelanceEn": "# Company Name as in Freelancer Document in English",
  "registration.hint.businessNameFreelanceAr": "# Company Name as in Freelancer Document in Arabic",
  "registration.hint.keywords": "# Enter one or more keywords that describe your business, to make Business search results more accurate. Separate keywords with comma.",
  "registration.hint.city": "# Type your city and choose from the list, if you didn't find, choose the nearest one",
  "registration.hint.district": "# Type your district and choose from the list, if you didn't find, choose the nearest one",
  "registration.hint.businessLocation": "# Select city & district and then move the marker and place at exact location in the map",

  "registration.validation.invalidAccountNumber": "Invalid Account Number",
  "registration.validation.invalidIban": "Invalid IBAN",
  "registration.validation.identityNameFirstIncorrect": "Should be more than 3 characters",
  "registration.validation.identityNameLastIncorrect": "Should be more than 3 characters",
  "registration.validation.businessLocation": "Select city & district and then move the marker and place at exact location in the map",

  "generic.validation.required": "Required",
  "generic.tooltip.changeLanguage": "Change Language",
  "generic.tooltip.home": "Home",
  "generic.tooltip.manageAccount": "Manage Account",
  "generic.proceed": "Proceed",
  "generic.information": "Information",
  "generic.back": "Back",
  "generic.dragDropClickImage": "Drag & Drop or Click here",
  "generic.imagePreview": "Image Preview",
  "generic.invalidDateFormat": "Invalid Date Format",
  "generic.invalidDate": "Invalid Date",

  "home.accountCreated": "Account Created",
  "home.accountCreatedMessage": "You account has been created, update your business information and submit for approval. Our team will review the provided information and approve the account. You will be notified via email and sms to your registered email address and mobile number respectively once account is approved.",
  "home.accountPending": "Business Information is being Reviewed",
  "home.accountPendingMessage": "You have submitted your business information and is under review. You will be notified via email and sms to your registered email address and mobile number respectively once account is approved.",
  "home.accountRejected": "Business Information Rejected",
  "home.accountRejectedMessage": "Your submitted business information has been rejected. Please update the required information and submit again for approval. Our team will review the provided information and approve the account. You will be notified via email and sms to your registered email address and mobile number respectively once account is approved.",
  "home.accountApproved": "Business Account Approved",
  "home.accountApprovedMessage": "Business account has been approved. Now setup and manage the business using our mobile app for Android and iOS. Download app on Google Play Store and App Store.",
  "home.rejectionReason": "Rejection Reason",
  "home.addBusinessInformation": "Add Business Information",
  "home.updateBusinessInformation": "Update Business Information",

  "filePicker.fileLimitExceedMessage": "Maximum allowed number of files exceeded. Only {value} allowed. ",
  "filePicker.fileAddedMessage": "File {value} successfully added. ",
  "filePicker.fileRemovedMessage": "File {value} removed.",
  "filePicker.fileDropRejectMessage": "File {value} was rejected. ",
  "filePicker.fileNotSupported": "File type not supported. ",
  "filePicker.fileTooBig": "File is too big. Size limit is {value}. ",

  "login.recoverPassword": "Recover Password",
  "login.changePassword": "Change Password",

  "login.alreadyAccountLogin": "Already have an account?<br><b>Login here</b>",
  "login.loginHere": "Login here",
  "login.name": "Name",
  "login.username": "Username",
  "login.hint.username": "# Choose a username that describes your business, one word of length between 3-25, with only a-z 0-9 _ characters",
  "login.validation.usernameLength": "Should be between 3-25 characters",
  "login.validation.passwordLength": "Should be between 5-20 characters",
  "login.validation.incorrectMobileNumber": "Incorrect Mobile Number",

  "registration.hint.accountNumber": "# Should be between 10-20 numbers",
  "registration.hint.iban": "# Should be of length 24, starting with SA and followed by numbers",
  "registration.validation.accountNumber": "Incorrect Account Number, should be between 10-20 numbers",
  "registration.validation.iban": "Incorrect IBAN, should be of length 24, starting with SA and followed by numbers",
  "registration.businessNameFreelancerEn": "Freelancer Registration Name (English)",
  "registration.businessNameFreelancerAr": "Freelancer Registration Name (Arabic)",
  "registration.businessNameIndividualEn": "Maroof Registration Name (English)",
  "registration.businessNameIndividualAr": "Maroof Registration Name (Arabic)",
  "registration.typeMaroof": "Maroof",
  "registration.maroofLink": "Maroof E-Platform Web Link",
  "registration.expressService": "Express Store Service",
  "registration.darkStoreService": "Dark Store Service",
  "registration.hintDarkStoreService": "# Dark Store Service: \n• Business may take time to prepare items and shipping in 24 hours or more and may serving other cities.\n• Items prepared not urgently or ready made. \n• Shipping may take 24 hours or more.\n• Covering large area full city and other city or all country.\n• Businesses like- electronics, mobiles, roasted coffee, clothes, shoes, long term food, etc.",
  "registration.hintExpressService": "# Express Store Service: \n• Item prepared once customer request.\n• Item does not have long term expiration.\n• Deliver it instantly, Usually delivery between 10-90 minutes.\n• Covering small area like some districts\n• Serving same city not outside it\n• Business like- restaurant, sweet shops, coffee shops, etc.",
  "registration.maroofSelectHint": "# Account with Maroof does not support Payment Gateway currently, only Cash On Delivery is supported",
  "home.accountUpdateMessage": "Information needed are commercial record document/freelancer document/maroof, business name, logo, location, bank account details, personal identity document. Please make all documents handy and then proceed.",
  "registration.addressEn": "Address (English)",
  "registration.addressAr": "Address (Arabic)",
  "registration.selectBusiness": "Select Business",

  "redeemOffer.title": "Redeem Offer",
  "redeemOffer.offerCode": "Offer Code",
  "redeemOffer.rab7Number": "Rab7 Number",
  "redeemOffer.selectBranch": "Select Branch",
  "redeemOffer.submit": "Submit",
  "redeemOffer.promptTitleRedeemCode": "Redeem Offer",
  "redeemOffer.promptTitleError": "Error",
  "redeemOffer.promptTitleSuccess": "Offer Redeemed",
  "redeemOffer.promptMessageError": "Error Message",
  "redeemOffer.promptMessageSuccess": "Offer is successfully redeemed",
  "redeemOffer.redeem": "Redeem",
  "redeemOffer.redeemOfferFailed": "Redeem Offer Failed",
  "redeemOffer.validation.selectBranch": "Select Branch",
  "redeemOffer.validation.enterCorrectCode": "Enter Correct Offer Code",
  "redeemOffer.validation.enterCorrectRab7Number": "Enter Correct Rab7 Number",
  "redeemOffer.reset": "Reset",
  "redeemOffer.offer": "Offer",
  "redeemOffer.codeColonValue": "Code: {value}",
  "generic.agoDay": "{count} Day",
  "generic.agoDays": "{count} Days",
  "generic.agoHour": "{count} Hour",
  "generic.agoHours": "{count} Hours",
  "generic.agoMinute": "{count} Minute",
  "generic.agoMinutes": "{count} Minutes",
  "generic.agoMonth": "{count} Month",
  "generic.agoMonths": "{count} Months",
  "generic.agoSecond": "{count} Second",
  "generic.agoSeconds": "{count} Seconds",
  "generic.agoYear": "{count} Year",
  "generic.agoYears": "{count} Years",
  "generic.ended": "Ended",
  "generic.startingInTime": "Starting in ",
  "generic.endingInTime": "Ending in ",
  "home.welcomeComma": "Welcome,",
  "menu.discover": "Discover",
  "menu.campaigns": "My Campaigns",
  "menu.myRewards": "My Rewards",
  "menu.marketingCampaigns": "Marketing",
  "menu.loyaltyCampaigns": "Loyalty",
  "menu.educationCampaigns": "Education",
  "menu.trainingCampaigns": "Training",
  "menu.socialCampaigns": "Social Fun",
  "menu.influencerCampaigns": "Influencer",
  "menu.profile": "Profile",
  "menu.settings": "Settings",
  "profile.updated": "Profile Updated Successfully",
  "profile.logoutQ": "Logout ?",
  "filePicker.resizeImage": "Resize Image",
  "generic.addNew": "Add New",
  "generic.manageCampaign": "Manage Campaign",
  "generic.addMarketingCampaign": "Add Marketing Campaign",
  "generic.manageMarketingCampaign": "Manage Marketing Campaign",
  "generic.addLoyaltyCampaign": "Add Loyalty Campaign",
  "generic.manageLoyaltyCampaign": "Manage Loyalty Campaign",
  "generic.addEducationCampaign": "Add Education Campaign",
  "generic.manageEducationCampaign": "Manage Education Campaign",
  "generic.addTrainingCampaign": "Add Training Campaign",
  "generic.manageTrainingCampaign": "Manage Training Campaign",
  "generic.addSocialCampaign": "Add Social Campaign",
  "generic.manageSocialCampaign": "Manage Social Campaign",
  "generic.addInfluencerCampaign": "Add Influencer Campaign",
  "generic.manageInfluencerCampaign": "Manage Influencer Campaign",
  "generic.marketingCampaigns": "Marketing Campaigns",
  "generic.loyaltyCampaigns": "Loyalty Campaigns",
  "generic.educationCampaigns": "Education Campaigns",
  "generic.trainingCampaigns": "Training Campaigns",
  "generic.socialCampaigns": "Social Fun Campaigns",
  "generic.influencerCampaigns": "Influencer Campaigns",
  "generic.addOffer": "Add Motive",
  "generic.manageOffer": "Manage Reward",
  "list.noDataFound": "No Data Available",
  "list.noMoreDataFound": "No More Data Available",
  "list.noMoreCampaignsFound": "No More Campaigns Available",
  "list.noRewardsFound": "No Rewards Available, rewards added in campaign will be displayed here.",
  "list.noMoreRewardsFound": "No More Rewards Available",
  "tooltip.action.more": "More Actions",
  "tooltip.action.edit": "Edit",
  "profile.image": "Profile Image",
  "campaign.active": "Active",
  "campaign.inactive": "Inactive",
  "campaign.paused": "Paused",
  "campaign.viewsOver": "Views Over",
  "campaign.noPlan": "No Plan",
  "campaign.suspended": "Suspended",
  "campaign.status": "Status",
  "campaign.views": "Views",
  "campaign.plays": "Plays",
  "campaign.start": "Start",
  "campaign.end": "End",
  "campaign.consumes": "Consume",
  "campaign.actions": "Actions",
  "campaign.delete": "Delete",
  "campaign.edit": "Edit",
  "campaign.activate": "Activate",
  "campaign.deactivate": "Deactivate",
  "campaign.linkCopied": "Link Copied Successfully!",
  "campaign.deactivateCampaignWithId": "Deactivate Campaign \"{value}\"",
  "campaign.activateCampaignWithId": "Activate Campaign \"{value}\"",
  "campaign.deleteCampaignWithId": "Delete Campaign \"{value}\"",
  "campaign.deleteCampaignMsg": "Ensure that the campaign cannot be recovered once deleted.",
  "campaign.campaignDeactivatedWithId": "Campaign Deactivated",
  "campaign.campaignActivatedWithId": "Campaign Activated",
  "campaign.campaignDeletedWithId": "Campaign Deleted",
  "campaign.deactivateRewardWithId": "Deactivate Reward \"{value}\"",
  "campaign.activateRewardWithId": "Activate Reward \"{value}\"",
  "campaign.deleteRewardWithId": "Delete Reward \"{value}\"",
  "campaign.deleteRewardMsg": "Ensure that the reward cannot be recovered once deleted.",
  "campaign.rewardDeactivatedWithId": "Reward Deactivated",
  "campaign.rewardActivatedWithId": "Reward Activated",
  "campaign.rewardDeletedWithId": "Reward Deleted",
  "action.deactivate": "Deactivate",
  "action.activate": "Activate",
  "action.delete": "Delete",
  "campaign.selectUsageType": "Select Campaign Goal",
  "campaign.selectRewardType": "select reward type to start",
  "manage.previous": "Previous",
  "manage.next": "Continue",
  "manage.continue": "Continue",
  "manage.update": "Update",
  "manage.create": "Create",
  "campaign.tabCampaignGoal": "Goal",
  "campaign.tabFunActivityLibrary": "Library",
  "campaign.tabRewardLibrary": "Library",
  "campaign.tabDetails": "Details",
  "campaign.tabCustomization": "Customise",
  "campaign.tabQuestionOptions": "Question",
  "campaign.tabQuestions": "Questions",
  "campaign.tabTargeting": "Targeting",
  "campaign.tabReward": "Rewards",
  "campaign.tabAdvertisement": "Advertisement",
  "campaign.tabVouchers": "Vouchers",
  "campaign.tabPreview": "Preview",
  "campaign.tabPlans": "Plans",
  "campaign.tabCharge": "Charge",
  "campaign.tabRecharge": "Recharge",
  "reward.tabDetails": "Details",
  "reward.tabVouchers": "Vouchers",
  "activity.manage.image": "Image",
  "activity.manage.video": "Video",
  "activity.manage.title": "Title",
  "activity.manage.type": "Type",
  "activity.manage.startDate": "Start Date",
  "activity.manage.endDate": "End Date",
  "activity.manage.description": "Description",
  "activity.manage.startDateAfterCurrentDate": "Start date should be after current date",
  "activity.manage.endDateAfterStartDate": "End date should be after campaign start date",
  "activity.manage.feedbackAbout": "Feedback About",
  "activity.manage.opinionImage": "Feedback Image",
  "activity.manage.guessImage": "Guess Image",
  "activity.manage.questionImage": "Question Image",
  "activity.manage.questionTitle": "Question Title",
  "activity.manage.guessTitle": "Guess Title",
  "activity.manage.questionOption": "Option {value}",
  "activity.manage.addQuestionOption": "Add Option",
  "activity.manage.addAnotherOption": "Add Another Option",
  "activity.manage.rightOption": "Right Option Number",
  "activity.manage.hintRightOption": "# enter right option from {value1} to {value2}",
  "activity.manage.correctRightOption": "enter correct right option from {value1} to {value2}",
  "activity.manage.subUserDemographic": "User Demographic",
  "activity.manage.subUserGeographic": "User Geographic",
  "activity.manage.subUserActions": "User Actions",
  "activity.manage.topHintCustomization": "Refer the preview and customise the fields as per your need. It is optional.",
  "activity.manage.subSpecificUsers": "Specific Users",
  "activity.manage.topHintTargetingCampaign": "Target users based on their demographic, geographic & actions whom you want to give access to this campaign. Leave empty to target this to all users.",
  "activity.manage.topHintLoyaltyRewards": "Enter loyalty points you want to give to winners & put the rewards which they can redeem using their loyalty points balance.",
  "activity.manage.topHintEventRewards": "Enter rewards you want to give to participants.",
  "activity.manage.topHintEventLoyaltyRewards": "Enter loyalty points you want to give to participants & put the rewards which they can redeem using their loyalty points balance.",
  "activity.manage.valueFrom": "{value} From",
  "activity.manage.valueTo": "{value} To",
  "activity.manage.sectionHintAdBudget": "Select duration, views count as per your requirement and complete the payment to make the campaign live.",
  "activity.manage.sectionHintAdBudgetExtend": "Select duration, views count to top up in existing plan as per your requirement and complete the payment to extend the campaign.",
  "activity.manage.autoRestartDaily": "Daily",
  "activity.manage.autoRestart3Days": "Every 3 Days",
  "activity.manage.autoRestart7Days": "Every 7 Days",
  "activity.manage.autoRestart10Days": "Every 10 Days",
  "activity.manage.predictionTitle": "Describe what to predict",
  "activity.manage.predictionPlaceholder1": "Option 1 Title",
  "activity.manage.predictionPlaceholder2": "Option 2 Title",
  "activity.manage.prediction1Image": "Option 1 Image",
  "activity.manage.prediction2Image": "Option 2 Image",
  "activity.manage.predictionAnswer1": "Option 1 Answer",
  "activity.manage.predictionAnswer2": "Option 2 Answer",
  "activity.manage.identifyLocationTitle": "About the Location",
  "activity.manage.identifyLocationLatitude": "Location Latitude",
  "activity.manage.identifyLocationLongitude": "Location longitude",
  "activity.manage.participantsLimit": "Event Participants Limit",
  "activity.manage.leaveEmptyUnlimited": "# enter participation limit, leave empty to set unlimited",
  "activity.manage.contactNumber": "Contact Number",
  "activity.manage.identifyLocationImage": "Location Image",
  "activity.manage.contentTypes": "Content Types",
  "activity.manage.contentTypeText": "Text",
  "activity.manage.contentTypeImage": "Image",
  "activity.manage.contentTypeAudio": "Audio",
  "activity.manage.contentTypeVideo": "Video",
  "activity.manage.videoFromGallery": "Video From Gallery Allowed",
  "activity.manage.cardPlaceholderImage": "Card Placeholder Image",
  "activity.manage.sharePlaceholderImage": "Share Placeholder Image",
  "activity.manage.sharePlaceholderText": "Share Placeholder Text",
  "activity.validation.optionRequired": "Option Required",
  "activity.manage.puzzleImage": "Puzzle Image",
  "activity.manage.placeholderImageDescription": "your placeholder image in place of default",
  "activity.manage.placeholderImagePrediction1": "your image for option 1",
  "activity.manage.placeholderImagePrediction2": "your image for option 2",
  "activity.manage.imageToBeDiscovered": "Image to be Revealed",
  "activity.manage.locationAnswer": "Pick Correct Location",
  "activity.manage.hintLocationAnswer": "# select the correct location in the map as per the question asked to identify the location",
  "activity.manage.moveMapToAdjust": "move map to adjust",
  "activity.manage.mapRadiusMeterB": "Radius: <b>{value} meters</b>",
  "activity.manage.mapRadiusKmsB": "Radius: <b>{value} Kms</b>",
  "activity.manage.mapRadiusMeter": "Radius: {value} meters",
  "activity.manage.mapRadiusKms": "Radius: {value} Kms",
  "activity.manage.placeholderTargetingSelect": "select from options",
  "activity.manage.placeholderTargetingSelectMobile": "enter mobile number without country code & select from option",
  "activity.manage.selectGeofence": "Select Geofence Region",
  "activity.manage.winLimit": "Winners Count",
  "activity.manage.hintWinLimit": "# leave empty to set unlimited winners",
  "activity.manage.topHintReward": "Select rewards you want to give to users, select from already created rewards or add new reward. You can set winners count as per your need.",
  "activity.manage.selectFuns": "search & select rewards",
  "activity.manage.selectorAddRewards": "# select from existing rewards or add new reward",
  "activity.manage.sectionAddOffer": "Add New Offer",
  "activity.manage.sectionAddVoucher": "Add New Voucher",
  "activity.manage.offerTitle": "Reward Title",
  "activity.manage.offerImage": "Offer Image",
  "activity.manage.voucherTitle": "Voucher Title",
  "activity.manage.voucherImage": "Voucher Image",
  "activity.manage.cancelReward": "Cancel",
  "activity.manage.saveReward": "Save Reward",
  "activity.manage.saveRewardError": "Save the reward or cancel it to proceed",
  "activity.manage.selectRewardType": "Select reward type",
  "activity.manage.sectionTotalWinLimit": "Total Winners Count",
  "activity.manage.sectionHintTotalWinLimit": "# winners count throughout the campaign including all campaign restarts, leave empty to set unlimited winners",
  "activity.manage.winGLimit": "Total Winners Count",
  "activity.manage.hintWinGLimit": "# winners count throughout the campaign including all auto restarts, leave empty to set unlimited winners",
  "activity.manage.newRewardAdded": "New Reward Created!",
  "admin.activity.addCampaignQ": "Create Campaign ?",
  "admin.activity.addRewardQ": "Create Reward ?",
  "admin.activity.updateCampaignQ": "Update Campaign ?",
  "admin.activity.updateRewardQ": "Update Reward ?",
  "activity.manage.updated": "Campaign Updated!",
  "activity.manage.added": "Campaign Created!",
  "activity.manage.rewardUpdated": "Reward Updated!",
  "activity.manage.rewardAdded": "Reward Created!",
  "activity.validation.requiredMoreThanZero": "Required, should be more than zero",
  "activity.validation.requiredEmptyOrMoreThanZero": "Required, should be empty to set unlimited or more than zero",
  "activity.manage.participationPoints": "Participation Points",
  "activity.manage.participationRewards": "Participation Rewards",
  "activity.manage.created": "Campaign Created Successfully!",
  "activity.manage.campaignTitle": "Campaign Title",
  "activity.manage.rewardTitle": "Reward Title",
  "activity.manage.searchPlaceOrCoordinates": "Search a place or coordinates",
  "campaign.manage.recommendedFun": "Recommendations",
  "activity.manage.noRewards": "No Rewards",
  "activity.manage.noRewardsM": "select if you don't want to reward user",
  "activity.manage.addNewReward": "Add New Reward",
  "activity.manage.addNewRewardM": "add new offer or voucher",
  "activity.manage.selectRewardsM": "search & select existing offer or voucher",
  "activity.manage.addPoints": "Add Reward Points",
  "activity.manage.rewardPoints": "Reward Points",
  "activity.manage.addPointsM": "select and add loyalty points to reward",
  "activity.manage.sectionRewards": "Rewards List",
  "activity.manage.selectReward": "Select Type",
  "activity.manage.searchNSelectReward": "search & select reward",
  "activity.manage.atleastOneRewardNeeded": "add reward to proceed",
  "activity.manage.noRewardAdded": "No Rewards Added Yet",
  "activity.manage.hintAddRewardsToList": "# add rewards using above options i.e. select from existing rewards or add new reward",
  "activity.manage.rewardPointsWithValue": "Rewards Loyalty Points: {value}",
  "activity.manage.downloadQR": "Download QR",
  "activity.manage.copyLink": "Copy Link",
  "activity.manage.directLink": "Direct Access Link",
  "activity.manage.normalLink": "Normal Share Link",
  "activity.manage.directLinkCopied": "Direct Access Link Copied",
  "activity.manage.directQRCopied": "Direct Access QR Downloading Started",
  "activity.manage.normalLinkCopied": "Normal Share Link Copied",
  "activity.manage.normalQRCopied": "Normal Share QR Downloading Started",
  "activity.manage.close": "Close",
  "activity.manage.rewardSelected": "Reward Selected",
  "activity.manage.rewardAddedSnack": "Reward Added",
  "activity.manage.addCode": "Add Code",
  "activity.manage.enterVoucherCode": "Enter Voucher Code",
  "activity.manage.atleastOneVocuherNeeded": "add atleast one voucher code to proceed",
  "activity.manage.noVoucherCodeAdded": "No Voucher Codes Added Yet",
  "activity.manage.hintAddVoucherToList": "# add voucher code using above field",
  "activity.manage.vouchersCodes": "Voucher Codes",
  "activity.manage.vouchersList": "Vouchers List",
  "activity.manage.redeemedVouchersList": "Redeemed Vouchers",
  "activity.manage.vouchers": "Vouchers",
  "activity.manage.campaignIdWithValue": "Campaign ID #{value}",
  "activity.manage.referenceCustomization": "Customization Reference",
  "home.gettingStarted": "Getting Started",
  "home.gettingStartedM": "No Campaigns Available, select your goal and Add New Campaign",
  "activity.manage.actionButtonTitle": "Link Text",
  "activity.manage.actionButtonLink": "Link to Open",
  "generic.retry": "Retry",
  "activity.manage.subscriptionPlans": "Subscription Plans",
  "activity.manage.selectPlan": "Select a subscription plan",
  "activity.plan.viewsCount": "Views",
  "activity.plan.viewsCountPerDay": "Views Per Day",
  "activity.plan.duration": "Duration",
  "activity.plan.durationDay": "{value} day",
  "activity.plan.durationDays": "{value} days",
  "activity.plan.durationUptoYear": "upto 1 year",
  "activity.plan.durationUptoDays": "upto {value} days",
  "activity.plan.uptoValue": "upto {value}",
  "activity.plan.priceFree": "Free",
  "activity.plan.unlimited": "Unlimited",
  "activity.plan.flexible": "Flexible",
  "activity.plan.campaigns": "Campaigns",
  "activity.plan.onlyWithValue": "Only {value}",
  "activity.plan.buttonFree": "Get Started [only {value} available]",
  "activity.plan.buttonFreeLimitExhausted": "Freemium [All Limit Utilised]",
  "activity.plan.buttonFreeOver": "Freemium Utilised",
  "activity.plan.buttonContinuePlanWithValue": "Continue {value}",
  "activity.plan.choosePlanWithValue": "Start with {value1} {value2}",
  "activity.plan.userTargeting": "User Targeting",
  "activity.plan.yes": "Yes",
  "activity.plan.no": "No",
  "activity.plan.rab7AppDisplay": "Rab7 Promoting",
  "activity.plan.price": "Price",
  "activity.plan.priceFromValue": "starting {value1} {value2}",
  "activity.plan.cta1": "Call to Action",
  "activity.plan.cta2": "End Playing Action",
  "activity.plan.rechargeFree": "Recharge Free Quota",
  "activity.plan.analytics": "Analytics",
  "activity.plan.availableViews": "Available Views",
  "activity.plan.totalViews": "Total Views",
  "activity.plan.dailyViews": "Views per Day",
  "activity.plan.ended": "Plan is Ended",
  "activity.plan.endingInTime": "Plan Ending in",
  "activity.plan.campaignPlan": "Campaign Plan",
  "activity.plan.campaignPlanC": "Campaign Plan: ",
  "activity.plan.dateNoSet": "Not Set",
  "activity.manage.viewCampaignPlan": "View Campaign Plan",
  "activity.manage.switchToPaidPlan": "Upgrade Plan",
  "activity.manage.subscribeToPlan": "Subscribe to Plan",
  "activity.manage.tooltipExtend": "Extend views/duration in current plan",
  "activity.manage.actionExtend": "Extend",
  "activity.manage.tooltipSubscribe": "Subscribe to a plan",
  "activity.manage.actionSubscribe": "Subscribe",
  "activity.manage.tooltipRenew": "Renew the plan",
  "activity.manage.actionRenew": "Renew",
  "activity.manage.tooltipUpgrade": "Upgrade to premium plan",
  "activity.manage.actionUpgrade": "Upgrade",
  "activity.manage.actionExtendL": "Extend Current Plan",
  "activity.manage.actionSubscribeL": "Subscribe a Plan",
  "activity.manage.actionRenewL": "Renew Plan",
  "activity.manage.actionUpgradeL": "Upgrade Plan",
  "activity.manage.cta1Title": "Front CTA",
  "activity.manage.hintCta1Link": "# link you want to open when action button is clicked, by default your profile page is opened",
  "activity.manage.cta2Title": "End CTA",
  "activity.manage.cta2Video": "CTA Video",
  "activity.manage.addLocation": "Pick Location",
  "activity.manage.removeLocation": "Remove Location",
  "activity.manage.addGeofence": "Pick Geofence",
  "activity.manage.removeGeofence": "Remove Geofence",
  "activity.manage.proceedPaymentQ": "Proceed Payment ?",
  "activity.manage.proceedPaymentMessage": "You are now proceeding to pay the charges, your changes have been saved and you will be taken to payment gateway to process the payment. Once you process the payment selected plan will be activated. Continue ?",
  "activity.manage.proceedPaymentExtendQ": "Proceed Payment to Extend ?",
  "activity.manage.proceedPaymentExtendMessage": "You are now proceeding to pay the charges, your changes have been saved and you will be taken to payment gateway to process the payment. Once you process the payment selected plan will be extended. Continue ?",
  "activity.manage.proceedPaymentRenewQ": "Proceed Payment to Renew ?",
  "activity.manage.proceedPaymentRenewMessage": "You are now proceeding to pay the charges, your changes have been saved and you will be taken to payment gateway to process the payment. Once you process the payment selected plan will be restarted. Continue ?",
  "activity.manage.payWithValue": "Pay {value1} {value2}",
  "activity.manage.payment": "Payment",
  "activity.manage.cancelPaymentQ": "Cancel Payment ?",
  "activity.manage.cancelPayment": "Cancel Payment",
  "activity.manage.cancelPaymentMessage": "Cancelling the payment will stop your campaign from being live. If you are unable to process the payment now, you can process the payment later from the campaigns list to make the campaign live. Are you sure you want to cancel the payment?",
  "activity.manage.duration": "Duration (in days)",
  "activity.manage.viewsCount": "Views",
  "activity.manage.dailyViewsCount": "Views Per Day",
  "activity.manage.shareLink": "Share Link",
  "activity.plan.planActiveNoModify": "Your current plan is active & it cannot be modified. You are only allowed to update the plan once it is ended.",
  "activity.manage.viewLimitOver": "Plan Views Limit is Over",
  "activity.manage.upgradeToIncreaseViews": "Upgrade to premium plan to increase views limit",
  "activity.manage.expandToIncreaseViews": "Extend premium plan to increase views limit",
  "activity.manage.renewToIncreaseViews": "Renew plan to keep campaign live",
  "activity.manage.loyaltyRewardFee": "Loyalty Points required to redeem this reward",
  "home.accountSuspendedMessage": "Your account has been suspended.",
  "activity.usageType.branding": "Marketing",
  "activity.usageType.loyalty": "Loyalty",
  "activity.usageType.employee": "Training",
  "activity.usageType.school": "Education",
  "activity.usageType.party": "Social Fun",
  "activity.usageType.influencer": "Influencer",
  "activity.usageType.dbranding": "Spread your brand using the brand logo images inside the fun activities",
  "activity.usageType.dloyalty": "Make users loyal to you by providing them some loyalty points while playing",
  "activity.usageType.demployee": "Strong the skills of your employees and enhance their productivity",
  "activity.usageType.dschool": "Organise quiz or exam to evaluate your students",
  "activity.usageType.dparty": "Have some fun using the playing in the parties",
  "activity.usageType.dinfluencer": "Engage your followers and strong the bond between you and them",
  "campaign.manage.spinFun": "Spin Games",
  "campaign.manage.challengeFun": "Challenges",
  "campaign.manage.smartFun": "Smart",
  "campaign.manage.cardFun": "Card Games",
  "campaign.manage.investigateFun": "Investigate",
  "campaign.manage.informativeFun": "Informative",
  "campaign.manage.guessFun": "Guessing",
  "campaign.manage.otherFun": "Others",
  "activity.type.offer": "Reward",
  "activity.type.voucher": "Voucher",
  "activity.type.smartAd": "Smart Ad",
  "activity.manage.1.title": "Smart Ad",
  "activity.type.visitorOpinion": "Rate & Review",
  "activity.manage.7.title": "Rate & Review",
  "activity.type.prediction": "Prediction",
  "activity.manage.12.title": "Prediction",
  "activity.type.guess": "Guess",
  "activity.manage.19.title": "Guess",
  "activity.type.questionnaire": "Quiz",
  "activity.manage.28.title": "Quiz",
  "activity.type.event": "Event",
  "activity.manage.16.title": "Event",
  "activity.manage.16.description": "Plan a event and share to users for their participation.",
  "activity.type.challenge": "Reel",
  "activity.manage.11.title": "Reel",
  "activity.type.contentWriting": "Content Writing",
  "activity.manage.21.title": "Content Writing",
  "activity.manage.21.description": "Ask users to post their content based on your requirements.",
  "activity.type.shareNLike": "Share & Score",
  "activity.manage.17.title": "Share & Score",
  "activity.manage.17.description": "Game to spread your word like chain reaction. Users share their links to other users and ask them to click to collect the score.",
  "activity.type.collectStar": "Flip & Score",
  "activity.manage.18.title": "Flip & Score",
  "activity.manage.18.description": "Flip game in which user flip the cards and collect the score, collection is available again after some period of break.",
  "activity.type.gameSlot": "Slot",
  "activity.manage.13.title": "Slot",
  "activity.type.gameGreedySpin": "Greedy Spin",
  "activity.manage.24.title": "Greedy Spin",
  "activity.manage.24.description": "Spin game where user spin multiple slots and score weightage points given to each image.",
  "activity.type.gameBettingPic": "Blackjack",
  "activity.manage.25.title": "Blackjack",
  "activity.type.gameRoulette": "Roulette",
  "activity.manage.20.title": "Roulette",
  "activity.type.gameSpinNDiscover": "Bingo",
  "activity.manage.22.title": "Bingo",
  "activity.type.gameFlipCard": "Flip Card",
  "activity.manage.15.title": "Flip Card",
  "activity.type.gameFindHidden": "Find Hidden",
  "activity.manage.23.title": "Find Hidden",
  "activity.manage.23.description": "Card game having multiple cards with only one card having image under it, user need to flip the card and find the image in given tries.",
  "activity.type.gameMatchPair": "Match Pair",
  "activity.manage.26.title": "Match Pair",
  "activity.type.gameTicTacToe": "Tic Tac Toe",
  "activity.manage.27.title": "Tic Tac Toe",
  "activity.type.gameMatchThree": "Match Three",
  "activity.manage.29.title": "Match Three",
  "activity.type.gameWhacAMole": "Whac A Mole",
  "activity.manage.31.title": "Whac A Mole",
  "activity.type.gameBrandRace": "Brand Race",
  "activity.manage.32.title": "Brand Race",
  "activity.type.gamePoker": "Poker",
  "activity.manage.33.title": "Poker",
  "activity.type.identifyLocation": "Map",
  "activity.manage.34.title": "Map",
  "activity.type.gamePuzzle": "Puzzle",
  "activity.manage.30.title": "Puzzle",
  "activity.manage.12.winInfo": "Put rewards for winners to increase engagement. User who predict right will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.19.winInfo": "Put rewards for winners to increase engagement. Users who guess right will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.28.winInfo": "Put rewards for winners to increase engagement. Users with correct answers more than required will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.36.winInfo": "Put rewards for winners to increase engagement. Users with correct answers more than required will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.16.winInfo": "Put rewards you want to give who participate the event.",
  "activity.manage.11.winInfo": "Put rewards for content creators to increase engagement. Users whose content you find relevant and download will get the rewards.",
  "activity.manage.21.winInfo": "Put rewards for content creators to increase engagement. Users whose content you find relevant and download will get the rewards.",
  "activity.manage.17.winInfo": "Put rewards for winners to increase engagement. Users with most shared links clicked by other users will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.18.winInfo": "Put rewards for winners to increase engagement. Users with top score will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.13.winInfo": "Put rewards for winners to increase engagement. Users whose slot stopped at the same image in the selected row will win the game. Winners will be declared instantly.",
  "activity.manage.24.winInfo": "Put rewards for winners to increase engagement. Users with top score will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.25.winInfo": "Put rewards for winners to increase engagement. Users with top score will win the rewards. Winners will be declared at end of the campaign.",
  "activity.manage.20.winInfo": "Put rewards for winners to increase engagement. Users whose slot stopped at the same selected image in the selected row will win the game. Winners will be declared instantly.",
  "activity.manage.22.winInfo": "Put rewards for winners to increase engagement. Users who reveal the hidden picture completely will win the game. Winners will be declared instantly.",
  "activity.manage.15.winInfo": "Put rewards for winners to increase engagement. Users who flip the non empty cards without flipping the empty card for all the tries will win the game. Winners will be declared instantly.",
  "activity.manage.23.winInfo": "Put rewards for winners to increase engagement. Users who find the hidden image in given tries will win the game. Winners will be declared instantly.",
  "activity.manage.26.winInfo": "Put rewards for winners to increase engagement. Users who match all the pairs in given tries will win the game. Winners will be declared instantly.",
  "activity.manage.27.winInfo": "Put rewards for winners to increase engagement. Users who win more rounds than the bot will win the game. Winners will be declared instantly.",
  "activity.manage.29.winInfo": "Put rewards for winners to increase engagement. Users with highest score will win the game. Winners will be declared at end of the campaign.",
  "activity.manage.31.winInfo": "Put rewards for winners to increase engagement. Users with highest score will win the game. Winners will be declared at end of the campaign.",
  "activity.manage.32.winInfo": "Put rewards for winners to increase engagement. Users who finish the race in given spins count will win the game. Winners will be declared at end of the campaign.",
  "activity.manage.33.winInfo": "Put rewards for winners to increase engagement. Users who win more rounds than the bot will win the game. Winners will be declared instantly.",
  "activity.manage.34.winInfo": "Put rewards for winners to increase engagement. Users who choose the closest coordinates from the map will win the game. Winners will be declared at end of the campaign.",
  "activity.manage.30.winInfo": "Put rewards for winners to increase engagement. Users who solve the puzzle will win the game. Winners will be declared instantly.",
  "generic.language": "Language",
  "activity.manage.totalCostPerViewWithValue": "~ Cost per 1K Views: {value1} {value2}",
  "activity.manage.selectRewards": "Select Existing Reward",
  "activity.manage.placeholderTargetingSelectSearch": "search & from options",
  "activity.manage.brandPlaceholderText": "Brand Placeholder Text",
  "activity.manage.vs": "VS",
  "activity.manage.scoreValuePlaceholder": "Score Placeholder Text",
  "activity.manage.helpResetGameDays": "ⓘ campaign will restart again after the specified days, leave empty to set to not start again.",
  "activity.manage.resetGameDays": "Auto Restart Days",
  "activity.manage.hintEnterVoucherCode": "Enter a voucher code and click Add Code button to add, repeat the same to add multiple voucher codes",
  "activity.manage.hintLoyaltyRewards2": "Enter loyalty rewards which users can redeem by consuming their earned loyalty points.",
  "activity.requiredLoyaltyPointsToRedeem": "enter loyalty points required to redeem the reward",
  "activity.manage.atleastOneLoyaltyRewardNeeded": "choose atleast one loyalty reward to proceed, or set zero loyalty points earning for winners",
  "activity.winPoints": "Loyalty Points for Winners",
  "activity.eventPoints": "Loyalty Points for Participants",
  "activity.hintLoyaltyPointsParticipants": "# enter loyalty points you want to give to users who participate, put 0 if you don't want to give loyalty points",
  "activity.addLoyaltyOffer": "Add Loyalty Reward",
  "activity.manageLoyaltyOffer": "Manage Loyalty Reward",
  "activity.campaignDetails": "Campaign Details",
  "activity.campaignTitle": "Campaign Title",
  "activity.rewardTitle": "Reward Title",
  "activity.rewardDetails": "Reward Details",
  "activity.eventTitle": "Event Title",
  "activity.eventDetails": "Describe your Event",
  "activity.opinionTitle": "What feedback do you need from users ?",
  "activity.contentTitle": "What content do you want from users ?",
  "activity.questionTitle": "Question Prompt",
  "activity.questionImageDesc": "Pick the image about which you want to ask to users",
  "activity.correctAnswer": "Correct Answer",
  "activity.correctAnswerHint": "Enter correct answer, based on this correct answer winners will be declared, whose answer matches this answer",
  "activity.correctLocation": "Correct Location",
  "activity.correctLocationHint": "Pick correct location, based on this correct location winners will be declared, whose picked location is close to this location",
  "activity.locationImageDesc": "Pick the image of the location whose coordinates you want to ask to users",
  "map.searchPlace": "Search a place or coordinate",
  "filter.male": "Male",
  "filter.female": "Female",
  "filter.countries": "Countries",
  "filter.regions": "Regions",
  "filter.cities": "Cities",
  "filter.districts": "Districts",
  "filter.gender": "Gender",
  "filter.ageGroups": "Age Groups",
  "filter.nationalities": "Nationalities",
  "filter.users": "Users",
  "filter.targetSelectedUsers": "Target Above Selected Users Only",
  "filter.userInterests": "User Interests",
  "activity.question": "Question",
  "activity.addAnotherQuestion": "Add Another Question",
  "activity.validationOptionRequired": "enter atleast one option",
  "activity.selectRightOption": "Select Right Option",
  "activity.correctErrorAbove": "Fill above required fields before adding new question",
  "activity.moveUpQuestion": "Move Up this Question",
  "activity.moveDownQuestion": "Move Down this Question",
  "activity.removeQuestion": "Remove this Question",
  "activity.expandQuestion": "Expand this Question",
  "activity.collapseQuestion": "Collapse this Question",
  "activity.correctAnswersCount": "Correct Answers Count",
  "activity.minCorrectAnswersCount": "Minimum Correct Answers Count",
  "filter.gamePlays": "Customer Actions Count",
  "filter.noGamePlays": "Zero Actions",
  "filter.gamePlaysRange": "Actions in Range",
  "filter.actionsFrom": "Actions From",
  "filter.actionsTo": "Actions To",
  "activity.hintLoyaltyPointsWinners": "# enter loyalty points you want to give to users when they win",
  "activity.enterNonZeroLoyaltyPoints": "enter more than zero loyalty points you want to give to users when they win",
  "login.signUpSuccessMessage": "Your account has been created, proceeding now to complete your profile.",
  "login.resetPassword": "Reset Password",
  "login.passwordResetSuccess": "Password Reset Successful",
  "login.signIn": "Sign In",
  "login.welcome": "Welcome to Rab7",
  "login.completeProfile": "Complete Your Profile",
  "login.completeProfileMessage": "Complete your profile by entering below details and we will personalise your experience based on given information.<br><strong>Inaccuracy in entering information will result ineligibility for rewards.</strong><br>This will not be shown publicly except your name.",
  "login.age": "Age",
  "validation.enterName": "enter your name",
  "validation.selectGender": "select gender",
  "validation.selectAge": "enter your age",
  "validation.selectNationality": "select your nationality",
  "menu.play": "Play",
  "activity.manage.campaignImageDesc": "Your image in place of default image for the campaign",
  "activity.manage.rewardImageDesc": "Your image in place of default image for the reward",
  "activity.preview": "Preview",
  "activity.previewR": "Preview Representation",
  "campaign.tabCTA1": "Front CTA",
  "campaign.tabCTA2": "End CTA",
  "activity.manage.cta2ImageDesc": "Image/Video to display after play ends [video max duration {value} seconds]",
  "activity.manage.cta2VideoDesc": "Video you want to display after play gets ended",
  "login.oldPassword": "Old Password",
  "login.newPassword": "New Password",
  "login.confirmPassword": "Confirm Password",
  "login.passwordMismatch": "New Password and Confirm Password should be same",
  "activity.sectionReward": "Reward",
  "activity.hintAddRewardToList": "# add offer or voucher using above option",
  "activity.ctaTitle.visitNow": "Visit Now",
  "activity.ctaTitle.shopNow": "Shop Now",
  "activity.ctaTitle.downloadNow": "Download Now",
  "activity.ctaTitle.learnMore": "Learn More",
  "activity.ctaTitle.followUs": "Follow Us Now",
  "activity.ctaTitle.orderNow": "Order Now",
  "campaign.tabRecur": "Recurrence",
  "activity.noRewards": "No Rewards",
  "activity.manage.cta1ImageDesc": "Image in place of your profile image",
  "profile.deny": "Deny",
  "profile.allow": "Allow",
  "profile.later": "Later",
  "profile.fetchAgain": "Fetch Again",
  "profile.askForLocation": "Allow Rab7 to fetch your location for seamless experience & to provide you more available campaigns and rewards in your area.",
  "profile.askForLocationAgain": "Unable to fetch your location as location is denied or turned off, ensure that you have turned on location and have allowed Rab7 to fetch your location, and then proceed again.",
  "profile.locationInProgress": "Location fetching is in progress...",
  "profile.locationFetchedCorrectly": "Your location is fetched correctly.",
  "profile.locationDenied": "Unable to fetch your location as location is denied or turned off. Proceed with filling below information.",
  "profile.locationTimeout": "It is taking longer that usual to fetch your location, reset assure and processed with filling below information.",
  "location.locationDenied": "Unable to fetch your location as location is denied or turned off",
  "location.locationTimeout": "It is taking longer that usual to fetch your location.",
  "menu.rewardsWon": "Rewards Won",
  "activity.noRewardsWon": "No Rewards Available",
  "tab.play": "Play",
  "tab.build": "My Campaigns",
  "location.learnHotToTurnOn": "Learn how to turn on Location Services",
  "activity.manage.winLimitPerRecurring": "Winners Count Per Recurring",
  "manage.prev": "Prev",
  "manage.nxt": "Next",
  "activity.plan.reward": "Add Reward",
  "activity.plan.listing": "Customise Listing",
  "activity.plan.anytime": "Anytime",
  "activity.plan.cantChange": "Can't Change",
  "activity.manage.directLinkDetail": "Accessible to any user, bypassing user targeting criteria.",
  "activity.manage.directLinkDetailFree": "Share link to give access.",
  "activity.manage.normalLinkDetail": "Accessible to users who fulfill targeting criteria.",
  "activity.manage.manageSuccessMessage": "Share link to give access. Upgrade to premium to list in Rab7 world, target users and more features.",
  "activity.manage.manageSuccessMessageNoPlan": "Select a plan to make the campaign live.",
  "activity.manage.placeholderTargetingSelectRab7No": "enter Rab7 Number & select from option",
  "activity.manage.geofence": "Geofence Region",
  "activity.manage.hintSelectGeofence": "# move map to adjust the selected marker, zoom in/out to select the region",
  "activity.manage.geofenceCenter": "Center: <b>{latitude}, {longitude}</b>",
  "generic.remove": "Remove",
  "generic.none": "None",
  "login.hintEmail": "# enter your working email where we send email for verification",
  "campaign.tabWinLimit": "Win Limit",
  "activity.noMoreWinners": "No More Winners",
  "activity.noMorePlayerStats": "No More Player Stats",
  "activity.visitLink": "Visit Link",
  "login.invalidEmail": "Enter correct email",
  "login.joinRab7": 'Join Rab7',
  "login.signInRab7": 'Welcome Back!',
  "login.or": "or",
  "login.signUpGoogle": "Sign up with Google",
  "login.signInGoogle": "Sign in with Google",
  "login.signUpApple": "Sign up with Apple",
  "login.signInApple": "Sign in with Apple",
  "login.forgotPassword": "Forgot Password ?",
  "activity.notTargetedToUser": "This campaign is not available to you currently",
  "activity.notExist": "Campaign does not exist",
  "activity.manage.16.winInfoP": "",
  "activity.manage.11.winInfoP": "who will be notified at end of the campaign, & when you download their content.",
  "activity.manage.21.winInfoP": "who will be rewarded when you download their content.",
  "activity.manage.13.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.20.winInfoP": "who will be notified instantly.",
  "activity.manage.22.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.23.winInfoP": "who will be notified instantly.",
  "activity.manage.26.winInfoP": "who will be notified instantly.",
  "activity.manage.27.winInfoP": "who will be notified instantly.",
  "activity.manage.33.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.30.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.4.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.12.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.15.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.17.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.18.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.19.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.24.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.25.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.28.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.36.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.29.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.31.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.32.winInfoP": "who will be notified at end of the campaign.",
  "activity.manage.34.winInfoP": "who will be notified at end of the campaign.",
  "filePicker.fileDurationLong": "{value} file duration is more than allowed, should be less than {duration} seconds.",
  "login.loginToPlay": "Join Rab7 or Login into your account to proceed",
  "common.shareDataCopied": "Share Data Copied.",
  "activity.reportMessage": "Report the campaign if you find this harmful, dangerous, misinformation, spam, misleading etc.",
  "activity.describeToReport": "Your reason to report",
  "login.registerSuccess": "Welcome to Rab7!",
  "login.registerSuccessMessage": "Your Rab7 account is almost ready.<br/><br/>Proceeding now to complete your profile.",
  "login.accountActivated": "Your account has been activated.",
  "login.emailUnverified": "Unverified",
  "login.resendVerificationLink": "Resend Verification Link",
  "login.resendVerificationLinkFailed": "Resend Verification Link Failed",
  "login.resendVerificationLinkSuccess": "Verification link sent to registered email.",
  "login.accountActivationPending": "Account Activation Pending",
  "login.accountActivationPendingMessage": "You have not verified your email address yet. Open the verification link sent to your registered email or resend again.",
  "activity.youWonGame": "You won the game",
  "login.openRab7": "Open Rab7",
  "campaign.tabReferral": "Referral",
  "campaign.tabReview": "Review",
  "activity.helpTabReferral": "Add reward for affiliates whose shared link won the game.",
  "activity.previewF": "Final Preview",
  "activity.showAsLinkOnlyMessage": "Do you want the campaign to be accessible via share links only, i.e. not displayed in Rab7 play list ?",
  "activity.subUserInterests": "User Interests",
  "activity.howToRedeemOffer": "How to redeem offer",
  "activity.guideSlot": "Get all identical images in selected row and win the game.",
  "activity.noRewardsProvided": "No win rewards have been added by campaigner",
  "activity.plan.voucher": "Add Voucher",
  "activity.plan.date": "Control Start Date",
  "activity.plan.changeDefaultImages": "Change Default Images",
  "location.mapMovedToCurrentLocation": "Map moved to current location",
  "activity.manage.shareLinkCopied": "Share Link Copied",
  "activity.showOtherCampaigns": "Show Other Campaigns",
  "location.refresh": "Refresh",
  "activity.locationDenied": "Turn on location to see more campaigns.",
  "activity.locationTimeout": "Location is not available. Try again to see more campaigns.",
  "installApp": "Install Rab7 App ?",
  "install": "Install",
  "campaign.tabDefaults": "Defaults",
  "activity.gameDefaultImages": "Game Default Images",
  "activity.manage.topHintRewards": "Set rewards for winners,",
  "activity.tabDefaultHint": "Replace the game default images with your own.",
  "activity.tabTargetHint": "Target users by demographics, location, and behavior, or leave blank to target all users.",
  "activity.tabPlanHint": "For the most impactful and engaging campaigns, choose Premium.",
  "activity.tabRecurHint": "Make the campaign reusable for users after a period to re-engage them, or make it a one-time event.",
  "activity.tabWinHint": "Set winners limit.",
  "activity.tabReferralHint": "Reward the affiliate whose first shared link won the game.",
  "activity.tabReviewHint": "Review the campaign and choose how you want it to be seen: by link only, or by link and in the Rab7 play list.",
  "activity.manage.addNewOffer": "Add Offer",
  "activity.manage.addNewVoucher": "Add Voucher",
  "activity.publicAccess": "Public",
  "activity.linkOnlyAccess": "By Link",
  "activity.publicAccessHint": "will make targeted user seeing the campaign in play list, reach a wide audience.",
  "activity.linkOnlyAccessHint": "will make user only see the campaign thru the link, keep it private.",
  "campaign.tabPlayTries": "GamePlay",
  "activity.tabPlayTriesHint": "Set the number of spins tries and game replays.",
  "activity.gamesCount": "Game Replays Count",
  "activity.spinsPerGame": "Spinner Tries",
  "activity.winProbability": "Win Probability {value}%",
  "activity.manage.payNow": "Pay Now",
  "activity.manage.totalCost": "Total Price:",
  "activity.manage.subUserGeofence": "User Geofence",
  "activity.openLink": "Open",
  "activity.validation.requiredValueOrMore": "required, should be {value} or more",
  "activity.questionnaireCorrectAnswerPrompt": "selected answer is incorrect, choose correct answer.",
  "activity.hintOfferValidity": "# reward validity in days after associated campaign ends",
  "activity.offerValidity": "Reward Validity",
  "activity.mute": "Mute",
  "activity.unmute": "Unmute",
  "activity.tabQuestionsHint": "Add your questions",
  "campaign.declareWinners": "Declare Winners",
  "campaign.declareWinnersQ": "Declare Winners ?",
  "campaign.winnersDeclared": "Winners Declared Successfully",
  "activity.showAnswerDistribution": "Show Answers Distribution",
  "activity.showCheckRightOption": "Answer Correction",
  "activity.tabGamePlayQuestionHint": "Show or hide answers distribution.",
  "activity.tabGamePlayQuestionnaireHint": "Configure questionnaire actions.",
  "activity.gameTimeInSecs": "Game Time (in seconds)",
  "activity.hintGameTime": "# leave empty or enter zero to set no time limit",
  "activity.correctAnswersCountHint": "# minimum correct answers to pass or win, from 0 to {value}",
  "activity.roundsPerGame": "Rounds per Game",
  "activity.youHaveGuessed": "Guess recorded. ",
  "activity.youHaveGuessedNUpdate": "Guess recorded. Change before timer ends.",
  "guideGuessMessage": "Guess correctly to win the game.",
  "activity.userGuessRight": "Your guess was right.",
  "activity.userGuessWrong": "Almost there! Keep guessing.",
  "activity.roundDraw": "Round Draw",
  "activity.validationBetween": "should be between {value1} and {value2}",
  "activity.manage.4.description": "Engage your audience to identify their segment & interests.",
  "activity.openCampaign": "Open Campaign",
  "activity.optionRequired": "option required",
  "activity.optionAlreadyPresent": "option already present, enter another",
  "activity.errorGameLoading": "Error occurred in loading the game",
  "activity.socketTimeout": "Unable to connect to game server",
  "activity.youHavePredicated": "Prediction set.",
  "activity.userPredictionRight": "Your prediction was accurate!",
  "activity.userPredictionWrong": "Almost there! Keep guessing.",
  "campaign.tabPredict": "Predict",
  "activity.tabPredictionHint": "Add prediction with options",
  "activity.predictionDateHint": "Date upto when users can predict",
  "activity.predictionEndDate": "Prediction End Date",
  "activity.dummyPredictionTitle": "Predict the score of the match  ",
  "activity.dummyPredictionOption1Title": "Al Hilal",
  "activity.dummyPredictionOption2Title": "Al Nassr",
  "activity.twoValues": '{value1}/{value2}',
  "gameBettingPicMessage": "Spin & score maximum to win the game, scoring will be based on multiplier for each column.",
  "activity.allowGuessAgain": "You can guess it again.",
  "activity.flipsPerGame": "Card Flips Count",
  "activity.tabFlipTriesHint": "Set the number of flips and game replays.",
  "activity.validationValueBetween": "required, should be between {value1} and {value2}",
  "activity.loadingDots": "loading...",
  "activity.allowPredictionAgain": "You can predict it again.",
  "downloadContentSocialMessage": 'Downloading the content will make the user win the rewards.\n\nAre you sure you want to download the content?',
  "downloadContentAgainMessage": 'Are you sure you want to download the content?',
  "download": 'Download',
  "downloadContent": 'Download Content',
  "guidePartScoreMaximumToWin": "Score maximum to be eligible to win the game.",
  "guidePartScoreMoreThanValueToWin": "Score {value} or more to be eligible to win the game.",
  "activity.gameReverseImage": "Reverse Image",
  "gameMatchPairMessage": "Flip card and match it with other similar card in given time and flips count, and win the game.",
  "activity.type.gamePuzzleSlide": "Puzzle Slide",
  "activity.manage.35.title": "Puzzle Slide",
  "campaign.tabIdentifyLocation": "Location",
  "activity.location": "Location",
  "activity.tabIdentifyLocationHint": "Describe the location",
  "activity.describeLocation": "Describe the location to be identified",
  "activity.locationImage": "Location Image",
  "activity.selectLocation": "Pick Location",
  "activity.pickedLocation": "Picked Location",
  "activity.sponsor": "Sponsor",
  "activity.manage.buildYourPremiumPlan": "Build Your Plan",
  "activity.manage.extendYourPremiumPlan": "Extend Your Plan",
  "activity.noReelsFound": "No Reels Available",
  "generic.showLess": "...show less",
  "generic.showMore": "...show more",
  "activity.addPost": "Add Post",
  "activity.postAdded": "Post Added Successfully!",
  "activity.reelTitle": "Caption Your Media",
  "activity.reelVideo": "Video",
  "activity.reelAudio": "Audio",
  "activity.reelImage": "Image",
  "activity.reelMediaRequired": "Media required, add a video or audio or image",
  "activity.postAnonymous": "Post as Anonymous",
  "campaign.tabReelFront": "Describe",
  "activity.tabReelFrontHint": "Describe your requirements for the reel",
  "campaign.tabReelSubPostsHint": "Promote your reels in between user posted reels",
  "activity.reelFrontTitle": "Describe your needs",
  "filePicker.fileSizeLong": "{value} file size is more than allowed, should be less than {size} MB.",
  "activity.addAnotherPost": "Add Another Post",
  "activity.moveUpSubPost": "Move Up this Post",
  "activity.moveDownSubPost": "Move Down this Post",
  "activity.removeSubPost": "Remove this Post",
  "activity.expandSubPost": "Expand this Post",
  "activity.collapseSubPost": "Collapse this Post",
  "activity.correctErrorSubPost": "Fill above required fields before adding new post",
  "activity.subPostLink": "Link",
  "activity.subPostLinkText": "Link Title",
  "activity.reportCampaign": "Report Campaign",
  "activity.reportPost": "Report Post",
  "activity.reportPostMessage": "Report the post if you find this harmful, dangerous, misinformation, spam, misleading etc.",
  "activity.winnersByRandom": "Random Post",
  "activity.winnersByTopViewed": "Top Viewed Post",
  "activity.winnersByTopLiked": "Top Liked Post",
  "activity.winnersByDownloadedOnly": "Downloaded Post Only",
  "activity.winnersBy": "Winners By",
  "activity.downloadComplete": "Reel Post Downloaded.",
  "activity.swipeUpForMore": "swipe up for more",
  "activity.addYourPost": "add your post",
  "activity.roundsFinish": "Rounds Finished, You Lost",
  "campaign.declareWinnersPrompt": "Winners are declared at end of the campaign, however you can declare it now. Choose an option-",
  "campaign.declareWinnersNEndCampaign": "Declare Winners & End Campaign",
  "campaign.declareWinnersNRestartCampaign": "Declare Winners & Restart Campaign",
  "campaign.chooseDeclareWinnersOption": "choose applicable option to process declare winners",
  "campaign.offerVideo": "Reward Video",
  "campaign.offerVideoDesc": "Video about the reward",
  "login.backToLogin": "Back to <b>Login</b>",
  "login.accountVerification": "Rab7 Account Verification",
  "tab.add": "New Campaign",
  "activity.start": "Start",
  "campaign.declareWinnersNEndCampaignQ": "Declare Winners & End Campaign ?",
  "campaign.declareWinnersNRestartCampaignQ": "Declare Winners & Restart Campaign ?",
  "generic.continueQ": "Continue ?",
  "campaign.declareWinnersNEndCampaignHint": "# winners will be declared and the campaign is ended",
  "campaign.declareWinnersNRestartCampaignHint": "# winners will be declared and the campaign is restarted again after 10 minutes",
  "campaign.declareWinnersNEndCampaignPrompt": "Winners will be declared and the campaign is ended. <br><br>Continue ?",
  "campaign.declareWinnersNRestartCampaignPrompt": "Winners will be declared and the campaign is restarted again after 10 minutes. <br><br>Continue ?",
  "menu.redeemOfferSteps": "Redeem Steps",
  "manage.chooseNStart": "Start Now",
  "manage.customizeNow": "Customize Now",
  "activity.empty": "_",
  "activity.tabUsageHint": "What is your campaign goal!",
  "activity.tabTypeHint": "Select Fun Activity",
  "campaign.selectType": "Select fun activity to start",
  "manage.leaveCampaign": "Leave Manage Campaign ?",
  "manage.leaveMessage": "Your changes will not been saved.",
  "manage.leave": "Leave",
  "filter.allGender": "All",
  "activity.tabPriceHint": "Build your plan",
  "activity.tabTargetingHint": "Target users by demographics, location, and behavior",
  "activity.tabCustomizeHint": "Customize your game",
  "activity.tabDetailsHint": "Listing details",
  "activity.gamePlayImages": "Replace game images with your own",
  "activity.bgAudio": "Replace background audio with your own",
  "activity.tabDetailsHeader": "Customize campaign listing details",
  "activity.tabRewardDetailsHeader": "Customize reward details",
  "campaign.coverImage": "Cover Image",
  "campaign.publicLinkTitle": "Choose how you want it to be seen",
  "campaign.publicLinkSubTitle": "Review the campaign and choose how you want it to be seen: by link only, or by link and in the Rab7 play list.",
  "activity.manage.sectionResetDays": "Auto-Restart Campaign",
  "activity.manage.sectionHintAutoRestart": "Campaign can be made to restart periodically. Attract users again and again with automatic campaign recycling.",
  "activity.selectMedia": "Select Media",
  "activity.hintQuestionOptions": "# fill only options which you want to display",
  "activity.tabQuestionHint": "Ask your question",
  "activity.tabGuessHint": "What to guess",
  "activity.tabCustomizeFunHint": "Customize fun activity",
  "activity.questionNOptions": "Question and Options",
  "activity.questionRightOption": "Right Option",
  "activity.guessTitle": "Guess Prompt",
  "activity.previewPlayListSubtitle": "See how your game will look in play listing",
  "activity.previewPlaySubtitle": "See how your game will look in play screen",
  "activity.predictionValidity": "Prediction Validity",
  "activity.questionnaireConfigs": "Questionnaire Configs",
  "activity.showCheckRightOptionHint": "Prompt to correct the option if incorrect selected",
  "activity.showAnswerDistributionSubTitle": "Users can see answer trends after submitting their answer",
  "activity.tabCTA1Hint": "Direct users with a personalized Front CTA",
  "activity.manage.cta1Image": "Front CTA Image",
  "activity.manage.cta1Name": "Front CTA Title",
  "activity.manage.cta1Link": "Front CTA Link",
  "activity.offerDetails": "Offer Details",
  "activity.typeReward": "Reward",
  "generateCode": "Generate Code",
  "activity.tabRewardHint": "Game rewards and winners",
  "activity.imageObliqueVideo": "Cover Image/Video",
  "activity.setWinnersLimit": "Set Winners Limit",
  "activity.editRewardInMyRewards": "ⓘ Edit Reward is available in My Rewards",
  "activity.reelMedia": "Select Media",
  "activity.coverImage": "Cover Image",
  "activity.tabVoucherHint": "Manage Vouchers",
  "activity.manage.hintRedeemedVoucherCodes": "Vouchers that have been claimed by users",
  "activity.tabCTAHint": "Add CTAs to your game",
  "activity.manage.autoRestartNone": "No Restart",
  "activity.saveNExit": "Save & Exit",
  "campaign.finished": "Finished",
  "campaign.live": "Live",
  "time.inSecond": "in {count} second",
  "time.inSeconds": "in {count} seconds",
  "time.inHour": "in {count} hour",
  "time.inHours": "in {count} hours",
  "time.inMinute": "in {count} minute",
  "time.inMinutes": "in {count} minutes",
  "time.inDay": "in {count} day",
  "time.inDays": "in {count} days",
  "time.inMonth": "in {count} month",
  "time.inMonths": "in {count} months",
  "time.inYear": "in {count} year",
  "time.inYears": "in {count} years",
  "redeemOffer.rab7ColonValue": "Store Rab7 Number: <b>{value}</b>",
  "redeemOffer.rewardInfo": "Offer Information",
  "redeemOffer.storeInfo": "Store Information",
  "campaign.manage.entertainmentFun": "Entertainment",
  "campaign.manage.engagementFun": "Engagement",
  "campaign.manage.feedbackFun": "Feedback",
  "campaign.manage.contentFun": "Content",
  "campaign.selectFunToPreview": "Select a fun to show its preview",
  "picker.fileNotWorking": "Media file is not working, should be {value}",
  "picker.fileTypeNotSupported": "File type is not supported, must be {value}",
  "campaign.typeSpinDiscoverShort": "Bingo",
  "activity.noRewardsText": "Nothing",
  "menu.editProfile": "Edit Your Profile",
  "menu.accountDetails": "Account Details",
  "menu.myReels": "My Reels",
  "menu.others": "Others",
  "menu.changePassword": "Change Password",
  "menu.needHelpQ": "Need Help?",
  "menu.emailUsAtSupport": "Email Us: rab7@rab7.com",
  "menu.needHelpEmailUs": "Need Help? Email Us",
  "menu.tnc": "Privacy & Usage",
  "menu.language": "Language",
  "campaign.myReels": "My Reels",
  "campaign.answerToContinue": "answer to continue to fun",
  "list.noCampaignsFound": "No fun activities available currently, check back later",
  "list.noCampaignsFoundNoLocation": "No fun activities available, enable the location and try again.",
  "campaign.ensureLocationOnAndRetry": "Look for fun nearby?<br>Turn on location and try again.",
  "picker.recordImageVideo": "Take Photo or Video",
  "picker.recordImage": "Take Photo",
  "picker.recordVideo": "Take Video",
  "picker.recordAudio": "Record Audio",
  "picker.openFile": "Choose File",
  "redeemOffer.promptMessageRedeemCode": "Ensure that you have validated Sponsor name, Sponsor Rab7 Number, & Offer availability.<br><br>Continue ?",
  "campaign.titleOfferName": "Offer Name",
  "campaign.titleSponsorName": "Sponsor Name",
  "campaign.titleSponsorRab7Number": "Sponsor Rab7 Number",
  "activity.helpRedeemTitle1": "Claim",
  "activity.helpRedeemSubTitle1": "Winner generates a unique QR code for their reward.",
  "activity.helpRedeemTitle2": "Redeem",
  "activity.helpRedeemSubTitle2": "Winner visits the sponsor's location to redeem their reward.",
  "activity.helpRedeemTitle3": "Verify",
  "activity.helpRedeemSubTitle3": "Sponsor scans the QR code using their camera app.",
  "activity.helpRedeemTitle4": "Authenticate",
  "activity.helpRedeemSubTitle4": "Sponsor validates the page belonging to the <b>RAB7.COM</b>",
  "activity.helpRedeemTitle5": "Match",
  "activity.helpRedeemSubTitle5": "Sponsor verifies the reward belongs to his Rab7 number.",
  "activity.helpRedeemTitle6": "Fulfill",
  "activity.helpRedeemSubTitle6": "Sponsor confirms the reward and marks it as redeemed.",
  "activity.helpRedeemTitle7": "Enjoy",
  "activity.helpRedeemSubTitle7": "Winner receives and enjoys their reward.",
  "activity.helpRedeemNote": "If the reward is a coupon, the winner can use the code for online shopping without having to follow the previous steps.",
  "activity.helpNote": "NOTE",
  "campaign.uploadMedia": "Upload Media",
  "activity.tabUsage": "Select Goal",
  "activity.tabCustomizeGame": "Customize Game",
  "activity.tabCustomize": "Customize Fun",
  "activity.tabType": "Select Fun",
  "activity.tabReelFront": "Describe Need",
  "activity.tabReelSubPosts": "Promote Posts",
  "activity.tabDetails": "Listing Details",
  "activity.tabCTA": "CTAs",
  "activity.tabCTA1": "Front CTA",
  "activity.tabReferral": "Referral",
  "activity.tabTargeting": "Target Users",
  "activity.tabReview": "Review",
  "activity.tabPrice": "Build Plan",
  "activity.tabQuestions": "Questions",
  "activity.tabQuestion": "Question",
  "activity.tabGuess": "Ask to Guess",
  "activity.tabPrediction": "Ask to Predict",
  "activity.tabIdentifyLocation": "About Location",
  "activity.tabVoucher": "Add Vouchers",
  "activity.tabReward": "Add Motive",
  "activity.actionPrevious": "Prev",
  "activity.actionUpdate": "Update",
  "activity.actionPreviousC": "Previous",
  "activity.type.question": "Research",
  "activity.manage.4.title": "Research",
  "activity.campaignInsight": "Campaign Insight",
  "activity.insight": "Insight",
  "activity.selectChart": "Select Chart",
  "activity.selectFocus": "Select Focus",
  "activity.filters": "Filters",
  "activity.showInsight": "Show Insight",
  "activity.resetFilters": "Reset",
  "activity.applyFilters": "Apply",
  "activity.hideQuestions": "Hide Title",
  "activity.showQuestions": "Show Title",
  "generic.login": "Login",
  "activity.percentCorrectAnswer": "Pass Percent",
  "activity.moneyWithCurrency": "{value1} {value2}",
  "activity.hashQWithNumber": "#Q-{value}",
  "activity.hashQWithNumberNName": "#Q-{value}: {value2}",
  "activity.hashPostWithNumber": "#Post-{value}",
  "activity.questionnaireQuestion": "Question",
  "list.noCampaignsFoundNoLogin": "No fun activities available, login to see relevant fun activities",
  "activity.planDurationQ": "How many days do you want your campaign to run?",
  "activity.planViewQ": "How many people do you want to reach?",
  "activity.payNPublish": "Pay & Publish",
  "activity.publishNow": "Publish Now",
  "activity.resetPreview": "Reset Preview",
  "generic.startInDay": "Starting in {count} Day",
  "generic.startInDays": "Starting in {count} Days",
  "generic.startInHour": "Starting in {count} Hour",
  "generic.startInHours": "Starting in {count} Hours",
  "generic.startInMinute": "Starting in {count} Minute",
  "generic.startInMinutes": "Starting in {count} Minutes",
  "generic.startInMonth": "Starting in {count} Month",
  "generic.startInMonths": "Starting in {count} Months",
  "generic.startInSecond": "Starting in {count} Second",
  "generic.startInSeconds": "Starting in {count} Seconds",
  "generic.startInYear": "Starting in {count} Year",
  "generic.startInYears": "Starting in {count} Years",
  "generic.endInDay": "Ending in {count} Day",
  "generic.endInDays": "Ending in {count} Days",
  "generic.endInHour": "Ending in {count} Hour",
  "generic.endInHours": "Ending in {count} Hours",
  "generic.endInMinute": "Ending in {count} Minute",
  "generic.endInMinutes": "Ending in {count} Minutes",
  "generic.endInMonth": "Ending in {count} Month",
  "generic.endInMonths": "Ending in {count} Months",
  "generic.endInSecond": "Ending in {count} Second",
  "generic.endInSeconds": "Ending in {count} Seconds",
  "generic.endInYear": "Ending in {count} Year",
  "generic.endInYears": "Ending in {count} Years",
  "campaign.startInDay": "Campaign will start in {count} Day",
  "campaign.startInDays": "Campaign will start in {count} Days",
  "campaign.startInHour": "Campaign will start in {count} Hour",
  "campaign.startInHours": "Campaign will start in {count} Hours",
  "campaign.startInMinute": "Campaign will start in {count} Minute",
  "campaign.startInMinutes": "Campaign will start in {count} Minutes",
  "campaign.startInMonth": "Campaign will start in {count} Month",
  "campaign.startInMonths": "Campaign will start in {count} Months",
  "campaign.startInSecond": "Campaign will start in {count} Second",
  "campaign.startInSeconds": "Campaign will start in {count} Seconds",
  "campaign.startInYear": "Campaign will start in {count} Year",
  "campaign.startInYears": "Campaign will start in {count} Years",
  "time.sinceSecond": "since {count} second",
  "time.sinceSeconds": "since {count} seconds",
  "time.sinceHour": "since {count} hour",
  "time.sinceHours": "since {count} hours",
  "time.sinceMinute": "since {count} minute",
  "time.sinceMinutes": "since {count} minutes",
  "time.sinceDay": "since {count} day",
  "time.sinceDays": "since {count} days",
  "time.sinceMonth": "since {count} month",
  "time.sinceMonths": "since {count} months",
  "time.sinceYear": "since {count} year",
  "time.sinceYears": "since {count} years",
  "payment.invoice": 'Simplified Tax Invoice',
  "payment.campaignPlan": "Campaign Plan",
  "payment.billTo": "Bill To",
  "payment.totalWithValue": "Total Including VAT ({value}%)",
  "payment.item": "Item",
  "payment.unit": "Unit",
  "payment.vat": "VAT",
  "payment.itemTotal": "Total",
  "payment.subTotal": "Sub Total",
  "payment.price": "Price",
  "payment.vatWithValue": "VAT ({value}%)",
  "payment.dateWithValue": "Date: {value}",
  "payment.timeWithValue": "Time: {value}",
  "payment.emailWithValue": "Email: {value}",
  "payment.businessNameUTF8": "Sennem Solutions for Telecommunication and Information Technology",
  "payment.businessNameWithValue": "Sennem Solutions for Telecommunication and Information Technology",
  "payment.businessPlace": "Riyadh, Saudi Arabia",
  "payment.businessTINWithValue": "VAT Number: 31021037490003",
  "payment.businessCommercialRecordWithValue": "CR Number: 1010451372",
  "campaign.tabPayment": "Payment",
  "campaign.tabPaymentL": "Payment",
  "payment.saveCard": "Save this card for future use",
  "payment.payWithValue": "Pay {value1} {value2}",
  'payment.stringCardNumber': 'Card Number',
  'payment.stringCardExpiry': 'MM/YY',
  'payment.stringCvv': 'CVV',
  'payment.stringCardHolderName': 'Card Holder Name',
  'payment.noBackNoReloadHint': 'payment is processing, please do not press back or reload',
  'payment.processing': 'Payment Processing',
  'payment.success': 'Payment Success',
  'payment.failed': 'Payment Failed',
  'payment.cancelled': 'Payment Cancelled',
  'payment.failedMsgDetail': 'If amount gets deducted, it will be reverted back in 3-15 days. Please connect support if you need any assistance, transaction Id: {value}.',
  'payment.processingMsg': 'Payment is in Processing',
  'payment.processingMsgDetail': 'Waiting for payment confirmation, it may take upto 30 minutes to get payment confirmation.<br>You can recheck status here or in transactions in settings, you will also be notified via email.',
  'payment.checkStatus': 'Check Status',
  'payment.retryPayment': 'Retry Payment',
  "payment.cancelQ": "Cancel Payment ?",
  "payment.cancel": "Cancel Payment",
  "payment.cancelMsg": "Cancelling the payment will stop your campaign from being live. Also ensure that the payment can only be cancelled if it is not yet charged. <br><br>If you are unable to process the payment now, you can process the payment later from the campaigns list to make the campaign live. <br><br>Continue ?",
  "payment.savedCards": "Saved Cards",
  "payment.addNewCard": "New Credit/Debit Card",
  "payment.cardExpired": "Expired",
  "payment.deleteCardQ": "Remove Card ?",
  "payment.deleteCard": "Remove Card",
  "payment.cardDeleted": "Payment Card Removed",
  "payment.monthYear": "{valueMonth}/{valueYear}",
  "payment.noSavedCards": "No Saved Cards",
  "payment.downloadInvoice": "Download Invoice",
  "activity.dummySurvey": "How likely are you to recommend our services to a friend or colleague?",
  "activity.dummySurveyO1": "Very likely",
  "activity.dummySurveyO2": "Likely",
  "activity.dummySurveyO3": "Neutral",
  "activity.dummySurveyO4": "Unlikely",
  "activity.dummySurvey2": "How frequently you check your campaign progress?",
  "activity.dummySurvey2O1": "Hourly",
  "activity.dummySurvey2O2": "Daily",
  "activity.dummySurvey2O3": "Weekly",
  "activity.dummySurvey2O4": "Monthly",
  "activity.dummyGuess": "Which is most effective?",
  "activity.dummyGuessO1": "Interactive quiz",
  "activity.dummyGuessO2": "Challenges",
  "activity.dummyGuessO3": "Game content",
  "activity.dummyGuessO4": "All of above",
  "activity.dummySurveyTitle": "Share your thoughts about Rab7",
  "activity.postVideoSizeDuration": "# video max duration {value1} seconds, max size {value2}Mb",
  "record.videoCaptureError": "Unable to record video, ensure that you have enabled the camera & microphone and granted permission to access it.",
  "record.imageCaptureError": "Unable to record video, ensure that you have enabled the camera and granted permission to access it.",
  "record.audioCaptureError": "Unable to record audio, ensure that you have enabled the microphone and granted permission to access it.",
  "record.videoCaptureErrorAndroid": "Unable to record video, ensure that you have enabled the camera & microphone and granted permission to access it. Use app in Google Chrome for better results.",
  "record.imageCaptureErrorAndroid": "Unable to record video, ensure that you have enabled the camera and granted permission to access it. Use app in Google Chrome for better results.",
  "record.audioCaptureErrorAndroid": "Unable to record audio, ensure that you have enabled the microphone and granted permission to access it. Use app in Google Chrome for better results.",
  "record.audioError": "Error in record audio, please try again.",
  "record.imageError": "Error in capture photo, please try again.",
  "record.videoError": "Error in record video, please try again.",
  "record.usePhoto": "Use Photo",
  "record.useAudio": "Use Audio",
  "record.useVideo": "Use Video",
  "record.photoCaps": "PHOTO",
  "record.videoCaps": "VIDEO",
  "record.recordAudioCaps": "RECORD AUDIO",
  "generic.close": "Close",
  "activity.exitPlay": "Exit Game",
  "activity.exitPlayMessage": "Exiting the game play in between will mark the game as played and you wont be able to play this turn again, it is recommended to finish the game and then exit. Are you sure you want to exit anyhow ?",
  "activity.planEnd": "Plan End",
  "activity.planStart": "Plan Start",
  "activity.noPlanSet": "No Plan",
  "login.joinRab7Short": "Join Rab7",
  "game.avatarYours": "Your's",
  "game.avatarOpponent": "Opponent's",
  "activity.offerMinMaxLimit": "# number of reward winners, not less than 1 and not more than 100",
  "activity.dummyReelCaption": "Create a Reel featuring your favorite dish, the vibrant atmosphere, or your memorable moments with friends and family.",
  "activity.dummyReelPost1Caption": "From seed to soul, passion poured onto every plate.",
  "activity.dummyReelPost2Caption": "Tastebud journey awaits! Dive into our passion-woven menu, crafted with fresh delights for pure bliss.",
  "activity.dummyReelPost3Caption": "Where artistry meets appetite, creating edible masterpieces.",
  "activity.dummyReelPost4Caption": "Freshness unfolds, transformed by love, into a symphony of flavors.",
  "payment.inclusiveOfVAT": "(inclusive of VAT)",
  "activity.noRewardsWonMessage": "No rewards yet!<br>Engage & win! Answer, play, discuss-<br>each action unlocks rewards. Let's fill your treasure trove! ✨",
  "login.moveBackFromCode": "Cancel, Go Back",
  "login.verification": "Account Verification",
  "login.codeRequired": "enter verification code",
  "login.codeInvalid": "verification code is invalid",
  "login.hintRegisterCode": "# enter verification code sent to email",
  "login.registerVerification": "Email Verification",
  "login.verifyAccountMessage": "Verify your account by entering verification code sent to your registered email.",
  "login.verifyEmailRegisterMessage": "Verify email belongs to you by entering code sent to your email.",
  "login.updatePassword": "Update Password",
  "login.verifyAccountResetPasswordMessage": "Verify your account by entering verification code sent to your registered email, and set new password.",
  "settings.aboutUsHeading": "Settings > About Us",
  "settings.othersHeading": "Settings > Others",
  "settings.changePasswordHeading": "Settings > Change Password",
  "settings.accountDetailsHeading": "Settings > Account Details",
  "settings.editProfileHeading": "Settings > Edit Your Profile",
  "menu.aboutUs": "About Us",
  "about.title1": "Who are we?",
  "about.subtitle1": "Rab7.com are a team of passionate dedicated to creating engaging and data-driven tools for the digital age.",
  "about.title2": "What problem are we solving?",
  "about.subtitle2": "Business owners, influencers, employers, educators, or individuals struggle to create engaging and interactive experiences for their audience.",
  "about.title3": "What is our solution?",
  "about.subtitle3": "Rab7.com provides a user-friendly platform to build interactive experiences like quizzes, games, Reel, Feedback & surveys, all fully customizable with your branding.",
  "about.title4": "Who can benefit from our platform?",
  "about.subtitle4": "Anyone who wants to connect with their audience in a more meaningful way: business owners, influencers, teachers, employers, and individuals!",
  "about.title5": "What makes our platform unique?",
  "about.subtitle5": "We offer a wide variety of interactive formats, seamless personalization options, and built-in audience segmentation tools.",
  "about.title6": "How does our platform benefit users?",
  "about.subtitle6": "Create engaging content, gather valuable audience insights, and drive action with clear calls to action.",
  "about.title7": "How does our platform benefit the audience?",
  "about.subtitle7": "Enjoy interactive experiences, personalize their journey, and have their voices heard through quick questions.",
  "about.title8": "What kind of analytics do we offer?",
  "about.subtitle8": "Gain in-depth insights into your audience demographics, geographic, and interests.",
  "about.title9": "Is our platform easy to use?",
  "about.subtitle9": "Absolutely! Our intuitive interface allows anyone to create interactive experiences without technical expertise.",
  "about.title10": "What is our mission?",
  "about.subtitle10": "We empower users to build deeper connections with their audiences through interactive and insightful experiences.",
  "about.noteInfo": "The Rab7.com website is owned by Sennem Solutions for Telecommunication and Information Technology Company, and we are registered in the Saudi Business Center with the number (0000003896).",
  "activity.chargeFree": "Free",
  "activity.answerDistribution": "Question Results",
  "activity.transactions": "Campaign Transactions",
  "payment.download": "Download",
  "payment.stateSuccess": "Success",
  "payment.stateFailed": "Failed",
  "payment.stateWaiting": "Processing",
  "menu.transactions": "Transactions",
  "campaign.transactions": "Campaign Transactions",
  "settings.campaignTransactions": "Settings > Campaign Transactions",
  "payment.downloadComplete": "Invoice Downloaded.",
  "payment.refreshStatus": "Refresh",
  "payment.refreshStatusTooltip": "Refresh Payment Status",
  "payment.retry": "Retry",
  "payment.id": "&nbsp;Id",
  "payment.charge": "Price",
  "payment.campaign": "Campaign",
  "payment.paymentStillProcessing": "Payment is being processed and still waiting for confirmation.",
  "campaign.noTransactions": "No Transactions",
  "selectLanguage": "Select Language",
  "campaign.tabOpinion": "Feedback",
  "activity.tabOpinion": "About Feedback",
  "activity.tabOpinionHint": "Describe the feedback about",
  "activity.aboutImage": "About Image",
  "activity.aboutFeedback": "About Feedback",
  "activity.aboutFeedbackPlaceholder": "Ask for Feedback",
  "activity.feedbackContents": "Feedback Contents",
  "activity.feedbackContentsHint": "How do you want the feedback to be given",
  "activity.feedbackContentRating": "Rating",
  "activity.feedbackContentImage": "Image",
  "activity.feedbackContentAudio": "Audio",
  "activity.feedbackContentVideo": "Video",
  "activity.feedbackContentText": "Text",
  "activity.minRatingPlaceholder": "Min Rating Placeholder",
  "activity.maxRatingPlaceholder": "Max Rating Placeholder",
  "activity.minRatingPlaceholderSmall": "Min Rating",
  "activity.maxRatingPlaceholderSmall": "Max Rating",
  "activity.minRatingPlaceholderHint": "like bad, poor, etc.",
  "activity.maxRatingPlaceholderHint": "like good, overwhelming, etc.",
  "activity.shareFeedback": "Share you feedback",
  "activity.shareFeedbackDummy": "Share you feedback about the services offered by Rab7",
  "activity.ratingMinPoor": "Poor",
  "activity.ratingMaxOver": "Excellent",
  "activity.pickContentType": "select atleast one",
  "activity.guideOpinion": "Share you feedback and win.",
  "activity.review": "Post Review",
  "activity.feedbackPosted": "Feedback Posted",
  "activity.commentsHere": "enter feedback here",
  "activity.ratingRequired": "choose rating",
  "activity.mediaOrTextRequired": "media or text required",
  "activity.feedbackRequired": "feedback required",
  "activity.mediaRequiredAdd": "media required, add ",
  "activity.mediaOrTextRequiredAdd": "media required, add ",
  "media.image": "image",
  "media.audio": "audio",
  "media.video": "video",
  "generic.slash": "/",
  "picker.orRecordAudio": ' or Record Audio',
  "activity.viewOpinions": 'View Feedbacks',
  "activity.reviews": "Campaign Feedbacks",
  "guideSurvey": "Complete the survey and win.",
  "activitySurveyShareMessage": "Complete the survey and win \"{title}\" on #Rab7. {url}",
  "activity.type.survey": "Survey",
  "activity.manage.36.title": "Survey",
  "exitSurvey": "Exit Survey",
  "exitSurveyMessage": "It is recommended to complete it now. Anyhow you can resume it afterwards. Exit Survey ?",
  "completeSurvey": "Complete Survey ?",
  "completeSurveyMessage": "You are about to complete survey. Continue ? ",
  "surveyCompleted": "You have completed the survey.",
  "activity.dummyQuestionnaire": "Rab7 Whiz Quiz!",
  "activity.dummyQuestion": "Rab7 lets you create interactive stories with drag-and-drop. What kind of content can you make without writing code?",
  "activity.dummyQuestionO1": "Quizzes, Games & Challenges",
  "activity.dummyQuestionO2": "Images only",
  "activity.dummyQuestionO3": "Long articles",
  "activity.dummyQuestionO4": "Social media feeds",
  "activity.dummyQuestion2": "Rab7 personalizes stories for engagement with features like custom music and visuals. What can it do?",
  "activity.dummyQuestion2O1": "Add your music",
  "activity.dummyQuestion2O2": "Custom visuals",
  "activity.dummyQuestion2O3": "Branching storylines",
  "activity.dummyQuestion2O4": "All of the Above",
  "activity.tabInQuestion": "Question",
  "activity.tabInQuestionHint": "Add your question",
  "activity.noQuestion": "Without",
  "activity.selectQuestion": "Existing",
  "activity.addNewQuestion": "New",
  "activity.optionQuestion": "Question",
  "activity.selectExistingQuestion": "Select Existing Question",
  "activity.selectQuestionToProceed": "select question to proceed",
  "activity.share": "Share",
  "activity.open": "Open",
  "activity.insightS": "Insight",
  "activity.contactEmail": "<a href='mailto:{value}' style='color: white'>Email: {value}</a>",
  "activity.price": "Price",
  "activity.qPrice": "Question Price",
  "activity.noPosts": "No Posts Available",
  "activity.shareYourEmailForContact": "Show my email",
  "activity.campaignStart": "Campaign Start Date",
  "activity.showPreview": "Show Preview",
  "activity.hidePreview": "Hide Preview",
  "activity.landingTitle": "<br>Connect Your Audience",
  "activity.landingSubTitle": "<br>Pick Fun<br>Customise It<br>Set reward<br>Get Analytics",
  "activity.startNow": "Start Now",
  "generic.addCampaign": "Add New Campaign",
  "activity.offerMedia": "Reward Image/Video",
  "activity.voucherMedia": "Voucher Image/Video",
  "activity.imageVideoRequired": "image or video required",
  "activity.consumedVouchers": "Vouchers Consumed",
  "activity.referralReward": "Referral Reward",
  "activity.referAndWin": 'You will also get the reward once those with whom you participate win the challenge. Press the share button and share it now...',
  "activity.referralManageHint": "Reward the affiliate whose first shared link won the game.",
  "activity.viewOpinionsS": "Feedbacks",
  "activity.viewAnswersS": "Stats",
  "activity.opinionDefaultQ": "Share your feedback",
  "activity.endCta2": "Call to Action (CTA)",
  "activity.tabCTA2": "Call to Action (CTA)",
  "activity.tabCTA2Hint": "Craft Call to Action (CTA) to drive users",
  "activity.manage.cta2Image": "CTA Media",
  "activity.manage.cta2Name": "CTA Title",
  "activity.manage.cta2Link": "CTA Link",
  "activity.finalPreview": "Final Preview",
  "activity.noWinners": "No Winners",
  "manage.save": "Save",
  "activity.answerSuccess": 'Your answer is submitted.',
  "activity.searchInEnglishOnly": '# search using english letters only',
  "activity.options": "Options",
  "activity.opponents": "Options",
  "activity.opponent1": "Option 1",
  "activity.opponent2": "Option 2",
  "activity.predictionPlaceholder1Small": "Name 1",
  "activity.predictionPlaceholder2Small": "Name 2",
  "record.retake": "Retake",
  "activity.preparingPost": "Uploading Post",
  "activity.preparingCampaign": "Preparing Your Campaign",
  "activity.questionHere": "Configure your question",
  "activity.option1Here": "Option 1",
  "activity.option2Here": "Option 2",
  "activity.option3Here": "Option 3",
  "activity.option4Here": "Option 4",
  "activity.guideAnswerToContinueToFun": "Answer to continue to fun activity.\n\n",
  "activity.guideChallenge": "Post your reel and win.\n\n",
  "login.bySignInAgreeTo": "By signing in, you agree to our",
  "login.signInOrSignUp": "SignIn or Register",
  "app.slogan": "Engage, Lead & Analyze",
  "howItWorks.title1": "Pick Your Fun",
  "howItWorks.subtitle1": "Choose from library the interactive experience (games, feedback, content, engagement).",
  "howItWorks.title2": "Brand It Yours",
  "howItWorks.subtitle2": "Personalize it with your unique logos, images, and messaging.",
  "howItWorks.title3": "Motivate & Reward",
  "howItWorks.subtitle3": "Drive participation with rewards , offers vouchers.",
  "howItWorks.title4": "Target the niches",
  "howItWorks.subtitle4": "Choose your targeted audiences demography & geography.",
  "howItWorks.title5": "Drive Clear Action",
  "howItWorks.subtitle5": "Set a calls to action, directing them to desired outcome (website visit, purchase, etc.).",
  "howItWorks.title6": "Ask & Segment",
  "howItWorks.subtitle6": "Ask a questions to segment your audience and gain deeper customer insights.",
  "howItWorks.title7": "Choose Your Reach",
  "howItWorks.subtitle7": "Select the ideal number of impressions (100-100k) to target your specific audience size.",
  "howItWorks.title8": "Set Your Campaign Duration",
  "howItWorks.subtitle8": "Choose the campaign duration (1-90 days) that best aligns with your goals (short-term promotion vs long-term brand awareness).",
  "howItWorks.title9": "Share & Measure Success",
  "howItWorks.subtitle9": "Generate a unique (link & QR code) then share it across your network and social media accounts.",
  "howItWorks.title10": "Discover Audience",
  "howItWorks.subtitle10": "Uncover key demographics, geographic data, and interests of your audience to refine future strategies and campaigns.",
  "howItWorks.actionHeading": "Ready to create win-win engagement",
  "activity.imageOrVideoTextRequired": "image or video, and text required",
  "activity.describeQuiz": "Describe Quiz",
  "activity.describeSurvey": "Describe Survey",
  "activity.quizPrompt": "Quiz Prompt",
  "activity.surveyPrompt": "Survey Prompt",
  "menu.howRab7Works": "How Rab7 Works",
  "menu.whatIsRab7": "What is Rab7",
  "generic.startFreeNow": "Start Free Now",
  "generic.joinRab7Now": "Join Rab7 Now",
  "activity.manage.7.description": "• Users rate a topic you specify.<br>• A random raffle is held for those who rate to win a reward.",
  "activity.manage.11.description": "• Users create content on a specific topic in a reel format.<br>• Everyone can view the posts, with your ads in between.<br>• The winning reel gets the reward you specify.",
  "activity.manage.12.description": "• Users predict the outcome of a competition you set up (e.g., a football match).<br>• Those who predict the correct outcome are eligible for a reward raffle.",
  "activity.manage.13.description": "• Users try to get matching pictures in a specified row.<br>• They are eligible for a reward raffle if they get a matching row.",
  "activity.manage.15.description": "• Users flip cards to find a hidden picture.<br>• Users are eligible for a reward raffle if they don't land on the deadly card.",
  "activity.manage.19.description": "• Users guess the correct answer to a picture-related question from 4 choices you provide.<br>• Those who answer the correct choice are eligible for a reward raffle.",
  "activity.manage.20.description": "• Users choose the order of the pictures in the top row.<br>• The spinner spins, and if their order matches the user's order, they win the reward.",
  "activity.manage.22.description": "• The picture is revealed if the spinner stops on certain pictures.<br>• Users who reveal the entire picture are eligible for a reward raffle.",
  "activity.manage.25.description": "• Each column multiplies the points.<br>• The user with the highest total points wins the reward.",
  "activity.manage.26.description": "• Users flip cards to collect matching pictures.<br>• Users are eligible for a reward raffle if they collect them within the specified attempts.",
  "activity.manage.27.description": "• Users compete against the bot in XO to win the round.<br>• Users are eligible for a reward raffle if they win the most rounds.",
  "activity.manage.28.description": "• Users answer a series of questions you set up.<br>• Those who exceed the success rate you specify are eligible for the raffle.",
  "activity.manage.29.description": "• Users arrange three matching pictures to break the row or column for points.<br>• Users are eligible for a reward raffle if they score 60 or more.",
  "activity.manage.30.description": "• Users arrange the scattered picture by flipping the pieces.<br>• Users are eligible for a reward raffle if they arrange it within the specified time.",
  "activity.manage.31.description": "• Users try to catch the correct pictures and avoid the wrong ones to collect points.<br>• The user who collects 35 points within the specified time is eligible for a reward raffle.",
  "activity.manage.32.description": "• Users compete against the bot with each spin of the spinner.<br>• If they win the race, they are eligible for a reward raffle.",
  "activity.manage.33.description": "• Users compete against the bot by flipping three cards.<br>• The highest total points wins the round.<br>• Users are eligible for a reward raffle if they win the most rounds.",
  "activity.manage.34.description": "• Users search and identify the location you specify on a map.<br>• The user closest to the location you specified wins the reward.",
  "activity.manage.35.description": "• Users complete the puzzle by flipping the pieces.<br>• Users are eligible for a reward raffle if they complete it within the specified time.",
  "activity.manage.36.description": "• Users complete the survey you set up.<br>• A random raffle is held for those who complete the survey to win a reward.",
  "activity.shareGeneric": '{title}, click here- {url}',
  "activity.youHavePredicatedNUpdate": "Prediction set. Change allowed before timer ends.",
  "activity.valuePercent": "{value}%",
  "activity.resultProbability": "Prediction Summary",
  "activity.predictionDrawValue": "NR {value}%",
  "activity.withoutRewards": "Without",
  "activity.savingCampaign": "Saving Your Campaign",
  "activity.joinRab7ForFurther": "Join Rab7 to Proceed",
  "activity.joinRab7ForGenerateCode": "Join Rab7 to generate code.",
  "activity.joinRab7ToPost": "Join Rab7 to add your reel.",
  "activity.joinRab7ToViewAllPosts": "Join Rab7 to view all the reels.",
  "activity.wonProceedToJoinMessage": "Congratulations, you won the game.<br><br>Proceed to Join Rab7 to claim the associated rewards if any.",
  "activity.wonEligibleProceedToJoinMessage": "Congratulations, you are eligible to win the reward if any.<br><br>Proceed to Join Rab7 to claim the associated rewards if any.",
  "activity.gameOverProceedToJoinMessage": "Game Complete!<br><br>Proceed to Join Rab7 and play the game again.",
  "activity.gameSubmitProceedToJoinMessage": "Your response is recorded!<br><br>Proceed to Join Rab7 and play the game again.",
  "activity.continueAnyway": "Explore as Guest",
  "activity.joinToGetBenefitsMessage": "Join Rab7 before moving to fun, so that you will get relevant fun recommendation and direct access to the won rewards.<br>However, you can explore it as guest.",
  "campaign.ensureLogin": "Join Rab7 and try again to see if it is applicable to you.",
  "campaign.subscribePlanToProceed": "Subscribe to a plan to proceed.",
  "campaign.savingThenMovingToPlanToProceed": "Your campaign is being saved and then you will be directed to choose plan duration and views. Your campaign will be published once you complete the payment.",
  "admin.activity.saveCampaign": "Save Campaign",
  "campaign.save": "Save",
  "activity.prepareCampaignAfterJoin": "Join Rab7 to publish your campaign.",
  "campaign.publishCampaign": "Publish Campaign",
  "location.pickManually": "Pick Location",
  "location.pickManuallyPrompt": "Location service not working?<br>You can pick your location from map.",
  "location.hintPickManual": "For the best campaigns near you, select your current location. Wrong location means irrelevant offers and potentially crowded events.",
  "game.score": "Score: {value}",
  "activity.chooseThemeColor": "Choose Your Theme Color",
  "activity.voucherCodeDetailsPlaceholder": "voucher details, how to redeem, terms, etc.",
  "activity.winLimitMoreThanVouchers": "winners count should be less than or equal to vouchers",
  "activity.voucherMoreThanWinLimitForReferral": "vouchers should be more than winners count to be available for referral",
  "activity.voucherMinMaxLimit": "# number of reward winners, between 1 and vouchers count",
  "location.zoomInMapToPickLocation": "zoom in map & choose more accurate location.",
  "location.pickLocationGoodToGo": "that's great! we are able to fetch your selected location.",
  "campaign.userWonJoinUsDetail": "Congratulations, You won the reward!",
  "campaign.userWonJoinUsDetailMessage": "You need to join Rab7 to view your voucher.",
  "activity.postDownloadedByCampaigner": "Your post have been downloaded by campaigner.",
  "game.scoreRounds": "(You) {value1} - {value2} (Bot)",
  "activity.wonChallengeMessageNR": "You need to join Rab7 to view your voucher.",
  "activity.joinGenericNR": "Level up your entertainment with Rab7! Join for fun and rewards.",
  "activity.passChallenge": "Challenge complete! Well done.",
  "activity.passChallengeMessageNR": "You need to join Rab7 to be eligible to enter the prize draw, before-",
  "activity.gameOverChallenge": "Game Over! Better Luck Next Time.",
  "activity.gameOverChallengeNR": "Fell short? Don't Worry!",
  "activity.gameOverChallengeMessageNR": "We will give you another try just because you join Rab7, and we are confident that you will win.",
  "activity.gameOverChallengeTitle": "Didn't end the fun yet!",
  "activity.gameOverChallengeMessage": " Share the challenge with your friends on your social network. When they play the challenge, you'll get a new chance to participate as well! ",
  "activity.gameOverChallengePlayAgain": "Game Over! Play Again.",
  "activity.updateActionMessageNR": "Join Rab7 & edit your guess! We believe in you.",
  "activity.updateActionRewardMessageNR": "Free guess update for joining Rab7! Win incoming!",
  "activity.locationPickedNUpdate": "Location set. Change before ends.",
  "activity.locationPicked": "Location set.",
  "activity.billingName": "Bill Issued For",
  "activity.billingInfo": "Billing Details",
  "activity.validationBillName": "Billing Name required, minimum 5 letters.",
  "generatedVoucherCodes": 'Your Vouchers',
  "activity.voucherDetails": "Voucher Details",
  "campaign.endCampaign": "End Campaign",
  "campaign.endCampaignPrompt": "This campaign will end without winners. In case you want to end the campaign and declare winners, go to <strong>Declare Winners</strong> option.",
  "campaign.endedDone": "Campaign Ended",
  "location.marked": "Set",
  "play.joinRab7Title": "Fuel Your Fun with Rab7!",
  "play.joinRab7SubTitle": "Start your personalized adventure today for exclusive rewards.",
  "play.joinRab7Action": "Join Rab7 Now",
  "play.joinRab7MaybeLater": "Maybe Later...",
  "validation.enterEmail": "enter valid email",
  "login.joined": " Your Rab7 account is ready! <br> Membership ID: <strong>{value}</strong>. <br><br>You can update your name anytime in Settings under \"Edit Profile\". ",
  "menu.games": "My Games",
  "games.noGames": "You haven't added any games yet. Use the \"+\" button to add a game now.",
  "games.notAdded": "No more games available.",
  "games.addNew": "Add Your Game",
  "games.uploadGame": "Upload Game",
  "games.gameInfo": "Game Info",
  "games.gameValidation": "Validation",
  "games.gameFileMsg": "Upload game zip file as per given guidelines",
  "games.gameGuidelines": "Games Guidelines",
  "games.seeGuidelines": "See Guidelines Here",
  "games.addGame": "Add Game",
  "games.manageGame": "Manage Game",
  "games.upload": "Upload",
  "games.saveGameQ": "Save Game ?",
  "games.publishGameQ": "Publish Game ?",
  "games.uploadGameQ": "Upload Game ?",
  "games.uploadGameSubtitle": "Ensure that you have followed the recommended guidelines for the game.",
  "games.savingGame": "Saving Your Game",
  "games.publishingGame": "Publishing Your Game",
  "games.uploadingGame": "Uploading Your Game",
  "games.tabValidation": "Game Validation",
  "games.tabInfo": "Basic Information",
  "games.tabInfoShort": "Game Info",
  "games.tabUpload": "Upload Game",
  "games.gamePreview": "Game Preview",
  "games.playGameToValidate": "Play the game to verify it meets the required criteria:",
  "games.ensureGameDisplayedCorrectly": "Ensure the game is displayed correctly, or refer to the guidelines to make the necessary adjustments.",
  "games.leaveAdd": "Discard Add Game ?",
  "games.leaveManage": "Leave Manage Game ?",
  "games.leaveManageMsg": "Your changes will not been saved.",
  "games.leaveAddMsg": "Your game will be discarded.",
  "games.shareLink": "Share Link",
  "games.share": "Share",
  "games.campaigns": "Campaigns",
  "games.createdAt": "Created",
  "games.deactivateGameWithId": "Deactivate Game \"{value}\"",
  "games.activateGameWithId": "Activate Campaign \"{value}\"",
  "games.deactivateGameMsg": "Game wont be available for new campaign, however it will continue for existing campaigns.",
  "games.deleteGameWithId": "Delete Game \"{value}\"",
  "games.deleteGameMsg": "Ensure that the game cannot be recovered once deleted.",
  "games.gameDeactivated": "Game Deactivated",
  "games.gameActivated": "Game Activated",
  "games.gameDeleted": "Game Deleted",
  "games.mute": "Mute",
  "games.unmute": "Unmute",
  "games.replay": "Play Again",
  "games.checkGameInit": "Game loads successfully",
  "games.checkGameStart": "Game starts properly",
  "games.checkGameEnd": "Game ends correctly",
  "games.checkGameDurationInLimit": "Game duration is within limit",
  "games.checkGameWin": "Verify game win by playing multiple times",
  "games.checkGameLoss": "Verify game lose by playing multiple times",
  "games.checkGameCustomiseBgAudio": "Background audio is customizable",
  "games.checkGameCustomiseAtleastImage": "At least one image is customizable",
  "games.checkGameGivenImage": "Declared images are customizable",
  "games.toggleMute": "Confirm mute/unmute: audio should play/stop correctly based on your action",
  "games.checksFailed": "Required checks failed. Please play again to validate the remaining criteria. Still not working? Ensure the game is developed according to the given guidelines.",
  "games.guide": "How to Play",
  "games.name": "Name",
  "games.gameImage": "Game Image",
  "games.gamePublished": "Game Published",
  "games.gameSaved": "Game Saved",
  "manage.publish": "Publish",
  "games.shareLinkCopied": "Share Link Copied",
  "activity.notifyEmail": "Resend",
  "activity.emailSent": "Email Sent",
  "activity.emailSendFailed": "Email Send Failed",
  "screen.appShowcase": "Rab7 Show",
  "screen.delete": "Delete",
  "screen.deleteScreenWithName": "Delete Digital Display \"{value}\"",
  "screen.tooltipDelete": "Delete Digital Display",
  "screen.campaignsShowcased": "Campaigns",
  "screen.status": "Status",
  "screen.createdAt": "Setup On",
  "screen.name": "Name",
  "screen.submit": "Save",
  "screen.idShort": "Display ID",
  "screen.id": "Digital Display ID",
  "screen.idHint": "# unique identity for the digital display",
  "location.choose": "Choose",
  "screen.isInteractive": "Digital Display is interactive",
  "screen.isInteractiveHint": "# user can play the fun game in the digital display",
  "game.playNow": "Play Now",
  "game.viewNow": "View Now",
  "screen.scanToPlay": "scan to play",
  "screen.scanToPost": "scan to post",
  "showcase.userInfoPostedPlayNow": "Your details have been updated, play it now.",
  "showcase.fillDetailsToPlay": "Please ensure all information is accurate for a smooth reward delivery.",
  "showcase.installApp": "Install Rab7 Showcase App ?",
  "showcase.autoClosePlayGameEnd": "Thanks for joining in the fun!<br>Returning to home screen in {value} seconds",
  "showcase.autoClosePlayGameEnd1": "Thanks for joining in the fun!<br>Returning to home screen in 1 second",
  "showcase.autoCloseIdle": "Digital Display Idle. Returning to home screen in {value} seconds.<br>Click to prevent.",
  "showcase.autoCloseIdle1": "Digital Display Idle. Returning to home screen in 1 second.<br>Click to prevent.",
  "menu.showcases": "Digital Displays",
  "menu.settingsShowcases": "Settings > Digital Displays",
  "screen.noScreensShowcased": "Showcase your campaigns, collaborate with others, and build a powerful digital presence. Earn and make a difference.",
  "screen.addNew": "Setup New Display",
  "screen.activation": "Digital Display Activation",
  "screen.setupDigitalDisplay": "Setup Showcase",
  "screen.digitalDisplayInfo": "Showcase Information",
  "screen.digitalDisplayActivated": "Showcase Digital Display Activated",
  "screen.qrActivated": "Showcase QR Activated",
  "screen.nameHint": "# name to identify display, location, etc.",
  "screen.code": "Activation Code",
  "screen.showcaseAs": "Select your showcase",
  "screen.showcase": "Showcase",
  "screen.viewQR": "View QR",
  "screen.statusInactive": "Inactive",
  "screen.insight": "Insight",
  "screen.deactivate": "Deactivate",
  "screen.deactivateScreenWithName": "Deactivate Digital Display \"{value}\"",
  "screen.deactivateScreenPrompt": "Deactivating will stop showcasing campaigns. You can't undo this. Are you sure?",
  "screen.deleteScreenPrompt": "Deleting will stop showcasing campaigns and you wont able to see insight. You can't undo this. Are you sure?",
  "activity.joinRab7ToViewAllFun": "Join Rab7 to view all fun.",
  "screens.orientationUpdated": "Digital Display Orientation Updated",
  "screen.codeHint": "# open <strong>show.rab7.com</strong> in your digital display and enter the displayed code",
  "screen.orientation": "App Orientation",
  "screen.updateScreenOrientation": "Update Digital Display Orientation",
  "screen.updateScreenOrientationPrompt": "Select the orientation of the showcase app to be displayed.",
  "screen.updateScreenOrientationHint": "# choose orientation of the showcase app to be displayed.",
  "screen.screenOrientationRequired": "select the orientation",
  "screen.orientationDefault": "Same as Screen",
  "screen.orientationPortraitR": "Portrait Left",
  "screen.orientationPortraitL": "Portrait Right",
  "screen.orientationLandscapeR": "Landscape Left",
  "screen.orientationLandscapeL": "Landscape Right",
  "screens.screenDeactivated": "Digital Display Deactivated",
  "screens.screenActivated": "Digital Display Activated",
  "screens.screenDeleted": "Digital Display Deleted",
  "screen.editDigitalDisplay": "Edit Showcase",
  "screen.qrUpdated": "Showcase QR Updated",
  "campaign.maximizeQRBenefit": " Maximize QR code visibility. <br> 1. Place it on shop tables, doors, counters, or product packaging. <br> 2. Share online too for wider reach. ",
  "screen.digitalDisplayUpdated": "Showcase Digital Display Updated",
  "screen.diveIn": "Ready to dive in?<br>Click play or scan the QR code to start the fun!",
  "screen.diveInNoPlay": "Ready to dive in?<br>Scan the QR code to start the fun!",
  "showcase.activityNotFoundError": "Fun is not available currently.",
  "screen.scanQRorOpen": " Scan with your phone or go to",
  "screen.enterCode": "Enter the code",
  "screen.statusOffline": "Offline",
  "screen.statusOnline": "Online",
  "activity.askInternalQuestion": "Ask a qualifying question to segment your audience",
  "activity.askInternalQuestionHint": "# It is available during campaign creation only and incurs an additional charge.",
  "activity.askInternalQuestionHintEdit": "# Once disabled, this feature cannot be re-enabled.",
  "activity.showcaseCampaign": "Showcase your campaign on digital displays in malls, stores, and events.",
  "activity.showcaseCampaignHint1": "Open show.rab7.com on your screen to participate. Earn by approving other campaigns.",
  "activity.showcaseCampaignHint1Edit": "Modifying your campaign data needs re-approval from other showcase owners.",
  "activity.showcaseCampaignHint2": "# It is available during campaign creation only and incurs an additional charge.",
  "activity.showcaseCampaignHint2Edit": "# Once disabled, this feature cannot be re-enabled.",
  "activity.scPrice": "Showcase Price",
  "screen.noNewCampaignRequests": "No more campaigns available to approve",
  "screen.noApprovedCampaignRequests": "No campaigns has been approved yet, approve available campaigns to be displayed in your showcases.",
  "screen.controlShowcaseCampaigns": "Control the display of other users' campaigns in your showcase",
  "screen.ownApprovedDefault": "Your own campaigns are showcased by default if \"Showcase Campaign\" is enabled in manage campaigns.",
  "screen.viewPendingCampaignRequests": "Approve Available Campaigns",
  "screen.approve": "Approve",
  "screen.remove": "Remove",
  "screen.removing": "Removing",
  "screen.approving": "Approving",
  "screen.displayOwnCampaigns": "Show my campaigns.",
  "screen.displayOnlyApproved": "Show approved campaigns from others.",
  "screen.displayAllCampaigns": "Show all campaigns from others.",
  "screen.updated": "Updated",
  "screen.owner": "Owner",
  "screen.campaign": "Campaign",
  "screen.media": "Media",
  "screen.view": "View",
  "screen.lastUpdated": "Last Updated",
  "screen.gameMedia": "Game Media",
  "screen.cta": "Call to Action",
  "screen.locale": "Language",
  "screen.localeArabic": "Arabic",
  "screen.localeEnglish": "English",
  "screen.selectShowcaseError": "select your showcase",
  "screen.codeNotMatched": "Code doesn't match ?",
  "screen.scanQRMessage": "Dive into a World of Fun. Scan QR to Play!",
  "screen.scanQRMessage0": "Limited Time Offers Inside. Scan QR Now!",
  "screen.scanQRMessage1": "Don't Miss Out! Scan QR for Exclusive Rewards.",
  "screen.scanQRMessage2": "Play Now, Win Big. Scan QR Code Today!",
  "screen.inQuestion": "Segmentation Question",
  "activity.roundWon": "Round Won",
  "activity.roundLost": "Round Lost",
  "activity.hint1GamePokerAS": 'distributing cards',
  "activity.hint2GamePokerAS": 'show cards',
  "screen.manageCampaignRequests": "Control Campaigns Approval",
  "screen.campaignsApproval": "Campaigns Approval",
  "screen.showcaseMoveToManageMessage": "Your campaigns will be automatically showcased when you enable \"Showcase Campaign\" option in manage campaigns.<br><br>To collaborate with others & earn, you can choose to showcase other users' campaigns. Adjust how other users' campaigns are displayed by clicking \"Control Campaigns Approval\".",
  "screen.approved": "Approved",
  "screen.waiting": "Waiting",
  "screen.denied": "Denied",
  "screen.deny": "Deny",
  "screen.denying": "Denying",
  "screen.interactiveScreen": "Touch",
  "screen.nonInteractiveScreen": "TV",
  "screen.QR": "QR",
  "screen.noDeniedCampaignAvailable": "No campaigns denied",
  "screen.noCampaignAvailable": "No campaigns available",
  "screen.viewContent": "See Content",
  "screen.changeToManualApproval": "Change to Manual Approval",
  "screen.changeToAutoApproval": "Change to Auto Approval",
  "screen.changeToManualApprovalPrompt": "Switching to manual approval means all campaigns need your approval before displayed in showcase. Approved campaigns remain active until you remove them. Are you sure you want to proceed?",
  "screen.changeToAutoApprovalPrompt": "Switching to auto-approval will automatically show all new campaigns without requiring your review. This can save time and potentially boost your earnings. Are you sure you want to proceed?",
  "screen.campaignsApprovalSetAuto": "Campaigns Approval: Auto",
  "screen.campaignsApprovalSetManual": "Campaigns Approval: Manual",
  "screen.showAllCampaigns": "Auto Approval Mode",
  "screen.showSelectedCampaigns": "Manual Approval Mode",
  "screen.modeFirstLoadPrompt": "Your approval is needed for others' campaigns to appear in your showcase.<br>Switch to auto-approval to show all campaigns without review.<br><br><strong>Please Note: Your campaigns don't need approval.</strong>",
  "screen.showcaseAdded": "{value} added successfully",
  "screen.showcaseUpdated": "Showcase updated successfully",
  "screen.earning": "Earning",
  "menu.settingsMore": "Settings & More",
  "menu.balance": "Balance",
  "menu.businessesWallets": "Businesses Wallets",
  "wallet.noBusinessesWallets": "No Businesses Wallets Available",
  "wallet.business": "Business",
  "wallet.balance": "Balance",
  "settings.businessesWallets": "Settings > Businesses Wallets",
  "generic.valueWithCurrency": "{currency}{value}",
  "screen.joinRab7ToPlay": "Join Rab7 to play",
  "activity.use": "Use",
  "activity.insufficientBalanceToConsume": "Insufficient Rab7 Coins, required minimum {value} coins to consume",
  "payment.paidInCoin": "Paid in Coins",
  "payment.coinPaid": "Coin-Paid",
  "inShowcase": "In Showcase",
  "notInShowcase": "Not In Showcase",
  "screen.screenInsight": "Display Insight",
  "menu.notificationSettings": "Notification Settings",
  "profile.notificationSettings": "Notification Settings",
  "profile.notifyActivityApproval": "Notify when campaigns available for review",
  "profile.emailActivityApproval": "Email when campaigns available for review",
  "profile.notificationSettingsUpdated": "Notification Setting Updated",
  "landing.sectionBannerTitle": "Rab7.com<br>Connect, Convince & Collect",
  "landing.sectionBannerSubTitle": "Your Platform for Building Interactive, Data-Driven Experiences",
  "landing.countUsers": "Users",
  "landing.countCampaigns": "Campaigns",
  "landing.countPlays": "Plays",
  "landing.countCities": "Cities",
  "landing.sectionWhatWeDo": "What we do ?",
  "landing.sectionWhatWeDoTitle": "Creating Interactive Connections",
  "landing.sectionWhatWeDo1": "• Empower businesses, influencers, and educators with customizable tools for engagement.",
  "landing.sectionWhatWeDo2": "• Build branded quizzes, games, surveys, and reels easily.",
  "landing.sectionWhatWeDo3": "• Capture audience insights and drive action with intuitive, user-friendly design.",
  "landing.sectionWhatWeDo4": "• Connect deeply with built-in audience segmentation and personalization- no tech skills needed!",
  "landing.sectionHowItWorks": "How it works ?",
  "landing.sectionHowItWorksTitle": "Create Your Perfect Campaign",
  "landing.sectionHowItWorks1": "1. <strong>Choose & Customize</strong>: Pick an interactive experience from our library, and personalize it with your brand’s logos and messaging.",
  "landing.sectionHowItWorks2": "2. <strong>Engage & Reward</strong>: Drive participation with rewards and tailored offers for your audience.",
  "landing.sectionHowItWorks3": "3. <strong>Target Your Audience</strong>: Set demographics, geography, and audience size for maximum reach.",
  "landing.sectionHowItWorks4": "4. <strong>Set Duration & Goals</strong>: Align your campaign length with goals, from short-term promotions to long-term engagement.",
  "landing.sectionHowItWorks5": "5. <strong>Share & Measure</strong>: Generate unique links and QR codes, then track success with insights on audience demographics and interests.",
  "landing.createCampaign": "Build Your Campaign",
  "landing.startFreeNow": "Start Free Now",
  "landing.contactEmail": "Contact Email: {value}",
  "landing.privacyPolicy": "Privacy Policy",
  "landing.tnc": "Terms & Conditions",
  "landing.footerNote": "Rab7.com website is owned by Sennem Solutions for Telecommunication and Information Technology Company, and we are registered in the Saudi Business Center with the number (0000003896).",
  "landing.copyright": "<span>&#169;</span> 2023 Rab7.com",
  "landing.partnersTitle": "Our Partners",
  "landing.partnersSubTitle": "Working together to engage audiences and drive meaningful connections.",
  "landing.priceTitle": "Flexible Pricing for Every Need",
  "landing.priceSubTitle": "Get started for free, with plans from just $12. Customize views and duration to fit your goals.",
  "landing.testimonialTitle": "What Our Users Say",
  "landing.testimonialSubTitle": "Hear how we’ve helped businesses connect, engage, and grow.",
  "landing.testimonials1": "Rab7.com made it so easy to create an engaging campaign for our brand. The customization options are endless, and we’ve seen incredible results with minimal effort!",
  "landing.testimonials2": "As an educator, I wanted a simple tool to create interactive content. Rab7.com was perfect—easy to use and effective in engaging my students in new ways!",
  "landing.testimonials3": "Setting up a campaign was quick, and the insights helped us understand our audience better. We’ve definitely noticed an increase in engagement and conversions!",
  "landing.testimonials4": "The platform’s flexibility allowed us to create a branded game that perfectly fit our audience. We love how easy it is to drive interaction and track performance!",
  "landing.testimonials5": "From start to finish, Rab7.com was a breeze to use. It’s helped us not only reach more people but also gather valuable data for future campaigns!",
  "landing.testimonials6": "With Rab7.com, we created a custom game for our campaign in minutes. Our customers loved it, and the insights we gained were invaluable!",
  "landing.testimonials7": "The platform is a game-changer! The customization was easy, and it helped us reach our target audience with interactive content that made an impact.",
  "landing.testimonials8": "Rab7.com has transformed our approach to customer engagement. The process is so smooth, and we’ve seen a noticeable increase in customer interaction!",
  "landing.testimonials9": "Creating a branded experience used to be a challenge, but Rab7.com made it quick and effective. It’s now an essential tool in our marketing strategy!",
  "landing.testimonials10": "The extensive game library and ease of use make Rab7.com our go-to for interactive marketing. Our audience engagement has reached new heights!",
  "landing.testimonialsUser1": "Hassan Al-Farsi",
  "landing.testimonialsUser2": "Omar Al-Saadi",
  "landing.testimonialsUser3": "Laila Khaled",
  "landing.testimonialsUser4": "Amira Nassar",
  "landing.testimonialsUser5": "Fahad Zaman",
  "landing.testimonialsUser6": "Rania Al-Mohammed",
  "landing.testimonialsUser7": "Yusuf Darwish",
  "landing.testimonialsUser8": "Mariam Al-Harbi",
  "landing.testimonialsUser9": "Khaled Shami",
  "landing.testimonialsUser10": "Noura Al-Fahad",
  "activity.noCampaigns": "You haven't added any campaign yet. Use the \"+\" button to add a campaign now.",
  "landing.menuPartners": "Partners",
  "landing.menuProcess": "Process",
  "landing.menuPricing": "Pricing",
  "landing.menuTestimonials": "Testimonials",
  "landing.countCountries": "Countries",
  "landing.countGames": "Game Library",
  "landing.bannerTitle1": "Why we do it ?",
  "landing.bannerTitle2": "What is the value ?",
  "landing.bannerTitle3": "How it works ?",
  "landing.bannerTitle4": "Why we are the best ?",
  "landing.bannerSubTitle1": "Make interactive marketing easy, simple, and affordable.",
  "landing.bannerSubTitle2": "Brand awareness<br>Boost sales<br>Gain customer insights",
  "landing.bannerSubTitle3": "A piece of cake!<br>In 20 seconds & no technical skills, choose a game and personalize it.",
  "landing.bannerSubTitle4": "Rich game library<br>Easy to create & modify<br>Fully automated",


  // trans
  "activity.guessCorrectAnswerPrompt": "selected guess is incorrect, choose correct one.",
  "activity.changeGuess": "Change Guess",
  "activity.confirmGuess": "Confirm Option ?",
  "activity.demoCampaignPrompt": "This is a demo campaign with limited features. Create your own campaign to explore everything we offer!",
  "activity.noCampaignsDemoOnly": "Explore the demo campaign. Use the \"+\" button to add your campaign now.",
  "activity.confirmAnswerNPlay": 'Confirm & Go to play ?',
  "manage.confirm": "Confirm",


}
